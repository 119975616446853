import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '../../../../shared/Dialog/DialogTitle/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const ReportDialog = ({ handleClose, group }) => {
  const { t } = useTranslation();

  const type = group ? 'group' : 'contact';
  const unit = t(`ReportDialog.${type}`);

  const handleConfirm = () => {
    handleClose();
  };

  const classes = makeStyles(theme => ({
    dialog: { paddingBottom: theme.spacing(1) },
    subtitle: { fontWeight: 'bold' },
    actions: {
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(1.5),
    },
  }))();

  return (
    <div data-testid="report-dialog" className={classes.dialog}>
      <DialogTitle>{t('ReportDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.subtitle}>
          {t('ReportDialog.subtitle', { unit })}
        </DialogContentText>
        <DialogContentText>
          {t('ReportDialog.text', { unit })}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleConfirm}
          component={'a'}
          href="mailto:David.D.Debono@mcast.edu.mt"
          variant="contained"
          color="primary"
          data-testid="send"
        >
          {t('ReportDialog.send')}
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          data-testid="cancel"
        >
          {t('ReportDialog.cancel')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default ReportDialog;
