import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSettings } from '../../../../../redux/selectors/App';
import { updateSettings } from '../../../../../redux/actions/App';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const Appearance = () => {
  const settings = useSelector(getSettings);
  const [sliderValue, setSliderValue] = useState(
    settings.communicator.font_size
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getSliderValues = (e, v) => {
    dispatch(
      updateSettings('communicator', {
        font_size: v,
      })
    );
  };

  const getInputValues = e => {
    dispatch(
      updateSettings('communicator', {
        font_family: e.target.value,
      })
    );
  };

  const toggleLanguage = e => {
    dispatch(
      updateSettings('common', {
        language: e.target.value,
      })
    );
  };

  const toggleDarkMode = e => {
    const newValue =
      settings.common.color_theme === 'Dark' ? 'Classic' : 'Dark';
    dispatch(
      updateSettings('common', {
        color_theme: newValue,
      })
    );
  };

  const changeBackground = color => {
    const payload = {
      background_color: color,
    };
    if (color !== 'default') {
      payload.color_theme = 'Classic';
    }
    dispatch(updateSettings('common', payload));
  };

  const classes = makeStyles(theme => ({
    appearance: {
      padding: 0,
    },
    section: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
    },
    sliderWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    slider: {
      margin: `0 ${theme.spacing(2)}px`,
    },
    subtitle: {
      fontWeight: 'bold',
    },
    radio: {
      marginTop: theme.spacing(2),
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiFormControlLabel-root': {
        margin: 0,
      },
      '& .MuiFormControlLabel-label': {
        marginRight: 'auto',
      },
    },
    darkMode: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    start: {
      display: 'flex',
      '& h6': {
        marginLeft: theme.spacing(2),
      },
    },
    backgrounds: {
      marginTop: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(127px, 1fr))',
      gap: '16px',
    },
    background: {
      width: 127,
      height: 127,
      backgroundColor: 'white',
      border: '1px solid #e5e5e5',
      borderRadius: 12,
    },
    upload: {
      cursor: 'not-allowed',
      marginTop: theme.spacing(2),
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    roboto: {
      '& .MuiFormControlLabel-label': { fontFamily: 'Roboto' },
    },
    openSans: {
      '& .MuiFormControlLabel-label': { fontFamily: 'Open Sans' },
    },
    nunito: {
      '& .MuiFormControlLabel-label': { fontFamily: 'Nunito' },
    },
    merriweather: {
      '& .MuiFormControlLabel-label': { fontFamily: 'Merriweather' },
    },
    colors: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '1rem',
    },
    colorButton: {
      marginTop: '1rem',
      border: '1px solid #E5E5E5',
      borderRadius: 12,
      outline: 'none',
      backgroundColor: 'none',
      aspectRatio: '1/1',
    },
  }))();

  return (
    <DialogContent className={classes.appearance}>
      <div className={classes.section}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('Settings.Appearance.font_size')}
        </Typography>
        <div className={classes.sliderWrapper}>
          <Typography variant="subtitle2">A</Typography>
          <Slider
            className={classes.slider}
            min={0.75}
            max={1.25}
            step={0.25}
            value={sliderValue}
            onChange={(e, v) => setSliderValue(v)}
            onChangeCommitted={getSliderValues}
          />
          <Typography variant="h6">A</Typography>
        </div>
      </div>
      <Divider />
      <div className={classes.section}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('Settings.Appearance.font_family')}
        </Typography>
        <div className={classes.radio}>
          <FormControl component="fieldset">
            <RadioGroup
              value={settings.communicator.font_family}
              onChange={getInputValues}
            >
              <FormControlLabel
                value="Roboto"
                control={<Radio />}
                label={`Roboto ${t('Settings.Appearance.default')}`}
                labelPlacement="start"
                className={classes.roboto}
              />
              <FormControlLabel
                value="Open Sans"
                control={<Radio />}
                label="Open Sans"
                labelPlacement="start"
                className={classes.openSans}
              />
              <FormControlLabel
                value="Nunito"
                control={<Radio />}
                label="Nunito"
                labelPlacement="start"
                className={classes.nunito}
              />
              <FormControlLabel
                value="Merriweather"
                control={<Radio />}
                label="Merriweather"
                labelPlacement="start"
                className={classes.merriweather}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <Divider />
      <div className={classes.section}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('Settings.Appearance.language')}
        </Typography>
        <div className={classes.radio}>
          <FormControl component="fieldset">
            <RadioGroup
              value={settings.common.language}
              onChange={e => toggleLanguage(e)}
            >
              <FormControlLabel
                value="en"
                control={<Radio />}
                label={t('Settings.Appearance.english')}
                labelPlacement="start"
              />
              <FormControlLabel
                value="mt"
                control={<Radio />}
                label={t('Settings.Appearance.maltese')}
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <Divider />
      <div className={classes.section}>
        <div className={classes.darkMode}>
          <div className={classes.start}>
            <Brightness4Icon />
            <Typography variant="subtitle1">
              {t('Settings.Appearance.dark_mode')}
            </Typography>
          </div>
          <Switch
            color="primary"
            disabled={settings.common.background_color !== 'default'}
            checked={settings.common.color_theme === 'Dark'}
            onChange={toggleDarkMode}
          />
        </div>
      </div>
      <Divider />
      <div className={classes.section}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('Settings.Appearance.change_background')}
        </Typography>
        <div className={classes.colors}>
          <button
            className={classes.colorButton}
            style={{
              backgroundColor: '#FFF',
              outline:
                settings.common.background_color === 'default'
                  ? '4px solid #2294CB'
                  : 'none',
            }}
            onClick={() => changeBackground('default')}
          />
          <button
            className={classes.colorButton}
            style={{
              backgroundColor: '#CEDEA2',
              outline:
                settings.common.background_color === 'green'
                  ? '4px solid #2294CB'
                  : 'none',
            }}
            onClick={() => changeBackground('green')}
          />
          <button
            className={classes.colorButton}
            style={{
              backgroundColor: '#EEDBCE',
              outline:
                settings.common.background_color === 'pink'
                  ? '4px solid #2294CB'
                  : 'none',
            }}
            onClick={() => changeBackground('pink')}
          />
          <button
            className={classes.colorButton}
            style={{
              backgroundColor: '#DBD7E4',
              outline:
                settings.common.background_color === 'blue'
                  ? '4px solid #2294CB'
                  : 'none',
            }}
            onClick={() => changeBackground('blue')}
          />
        </div>
      </div>
    </DialogContent>
  );
};

export default Appearance;
