import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../redux/actions/CurrentUser';
import styles from './LoadingScreen.module.css';
import CommAppLogo from '../../assets/icons/CommunicatorLogoRedone01';
import LoadingSvg from '../../assets/icons/LoadingPage01';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const LoadingScreen = ({ error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = makeStyles(theme => ({
    heading: { color: theme.palette.text.secondary },
    sorry: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: `0 ${theme.spacing(1)}px`,
      '& .MuiButton-root': {
        width: 250,
        maxWidth: '80%',
        padding: '.5rem',
        margin: '.5rem auto 0 auto',
      },
    },
  }))();

  return (
    <div className={styles.LoadingScreen}>
      <div className={styles.loadingWrapper}>
        {error ? (
          <div className={classes.sorry}>
            <Typography variant="h6">{t('LoadingScreen.sorry')}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {t('LoadingScreen.please')}
            </Typography>
            <Button variant="outlined" onClick={() => dispatch(logoutUser())}>
              {t('LoadingScreen.reload')}
            </Button>
          </div>
        ) : (
          <>
            <Typography variant="h5" className={classes.heading}>
              {t('LoadingScreen.loading')}
            </Typography>
            <CommAppLogo className={styles.CommAppLogo} />
            <LoadingSvg className={styles.LoadingSvg} />
          </>
        )}
      </div>
    </div>
  );
};

export default LoadingScreen;
