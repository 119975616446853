import * as React from 'react';

function SvgOverallAchievementIcon(props) {
  return (
    <svg
      id="Overall_Achievement_icon_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 28.9 29.6"
      xmlSpace="preserve"
      width="1em"
      height="1em"
      {...props}
    >
      <style>{'.Overall_Achievement_icon_svg__st0{fill:#5d6da4}'}</style>
      <g
        id="Overall_Achievement_icon_svg__Group_308"
        transform="translate(77.251)"
      >
        <path
          id="Overall_Achievement_icon_svg__Path_258"
          className="Overall_Achievement_icon_svg__st0"
          d="M-52.7 11.4l-4.6 2.6h-.2c-.1 0-.2-.1-.3-.2L-61.6 8c-.8.4-1.7.4-2.5 0l-3.8 5.9c-.1.2-.3.2-.5.1l-4.5-2.6c-.2.2-.4.4-.6.5l3.6 10.9c.1.3.4.6.8.6h12.5c.4 0 .7-.2.8-.6l3.6-10.9c-.2-.2-.4-.3-.5-.5z"
        />
        <path
          id="Overall_Achievement_icon_svg__Rectangle_420"
          className="Overall_Achievement_icon_svg__st0"
          d="M-68.8 24.8h12c.7 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2h-12c-.7 0-1.2-.5-1.2-1.2 0-.6.5-1.2 1.2-1.2z"
        />
        <circle
          id="Overall_Achievement_icon_svg__Ellipse_132"
          className="Overall_Achievement_icon_svg__st0"
          cx={-62.8}
          cy={4.8}
          r={2.5}
        />
        <circle
          id="Overall_Achievement_icon_svg__Ellipse_133"
          className="Overall_Achievement_icon_svg__st0"
          cx={-50.8}
          cy={9.7}
          r={1.6}
        />
        <circle
          id="Overall_Achievement_icon_svg__Ellipse_134"
          className="Overall_Achievement_icon_svg__st0"
          cx={-74.8}
          cy={9.7}
          r={1.6}
        />
      </g>
    </svg>
  );
}

export default SvgOverallAchievementIcon;
