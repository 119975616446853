import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getMessagesChatImages,
  loadMoreResults,
  toggleExpanded,
} from '../../../../../redux/actions/Search';
import {
  getActiveSection,
  getFocusedResultIndex,
} from '../../../../../redux/selectors/Search';
import { SnackbarContext } from '../../../ChatBox/SnackbarContext/SnackbarContext';
import { usernameText } from '../../../../../utils/userUtils';
import Avatar from '../../../../shared/Avatar/Avatar';
import Dialog from '../../../../shared/Dialog/Dialog';
import Moment from '../../../../shared/Moment';
import MessageDetails from '../../../ChatBox/MessageBoard/Message/MessageActions/MessageDetails/MessageDetails';
import { makeStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const MessagesResults = ({ data }) => {
  const expanded = useSelector(state => state.Search.results.messages.expanded);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const activeSection = useSelector(getActiveSection);
  const conversations = useSelector(state => state.Conversations.conversations);
  const users = useSelector(state =>
    data ? data.results.map(m => state.Users.users[m.user_id]) : []
  );
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (data && !data.dataFetched) {
      dispatch(getMessagesChatImages(data));
    }
  }, [data, dispatch]);

  const titleColor = message =>
    conversations[message.chat_id]?.hasLecturer ? 'primary' : 'textPrimary';

  const classes = makeStyles(theme => ({
    details: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      flexDirection: 'column',
    },
    result: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      cursor: 'pointer',
      '&:hover': { background: theme.palette.action.hover },
    },
    text: {
      '& .MuiTypography-body1': {
        maxWidth: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    focused: { background: theme.palette.action.focus },
    loadMore: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    timestamp: {
      marginLeft: 'auto',
      alignSelf: 'flex-start',
    },
  }))();

  if (!data) return null;

  return data.results.length ? (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => dispatch(toggleExpanded('messages', !expanded))}
      data-section="messages"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('SearchResults.messages')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {data.displayedResults.map((result, i) => (
          <Dialog
            key={result.message_id}
            className={
              activeSection === 'messages' && focusedResultIndex === i
                ? classes.focused
                : ''
            }
            trigger={
              <div className={classes.result}>
                {data.chats ? (
                  data.chats[result.chat_id]?.private ? (
                    <Avatar user={users[i]} />
                  ) : (
                    <Avatar
                      group
                      photo={data.chats[result.chat_id]?.signed_url}
                    />
                  )
                ) : (
                  <MuiAvatar />
                )}
                <div className={classes.text}>
                  {data.chats && (
                    <Typography variant="body1" color={titleColor(result)}>
                      {data.chats[result.chat_id]?.private
                        ? usernameText(users[i])
                        : data.chats[result.chat_id]?.title}
                    </Typography>
                  )}
                  <Typography variant="body2" color="textSecondary">
                    {usernameText(users[i])}: {result.body}
                  </Typography>
                </div>
                <div className={classes.timestamp}>
                  <Typography variant="caption" color="textSecondary">
                    <Moment>{result.created_at}</Moment>
                  </Typography>
                </div>
              </div>
            }
          >
            {close => (
              <MessageDetails handleClose={close} message={result} fromSearch />
            )}
          </Dialog>
        ))}
        {(data.count > data.results.length ||
          data.results.length > data.displayedResults.length) && (
          <div className={classes.loadMore}>
            <Button
              onClick={() =>
                dispatch(loadMoreResults(data, 'messages', setSnackbarOptions))
              }
              disabled={data.loadingMore}
            >
              {t('SearchResults.load_more')}
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null;
};

export default MessagesResults;
