import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SmartboardIcon from '../../../../../../../assets/icons/smarboardLogo';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import { getCurrentUserId } from '../../../../../../../redux/selectors/CurrentUser';

const SmartboardMessage = ({ message }) => {
  const currentUserId = useSelector(getCurrentUserId);
  const { t } = useTranslation();
  const renderSubheader = () => {
    const shared = t(
      `Message.${currentUserId === message.user_id ? 'you_shared' : 'shared'}`
    );
    return (
      <span className={classes.subheader}>
        {shared} {moment(message.created_at).format('DD MMMM')}
      </span>
    );
  };

  const classes = makeStyles(theme => ({
    smartboardMessage: {
      boxShadow: 'none',
      cursor: 'pointer',
    },
    header: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      '& .MuiCardHeader-title': {
        [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(1) },
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    avatar: {
      backgroundColor: '#FF9800',
      width: '40px',
      height: '48px',
      fontSize: '2em',
      borderRadius: '4px',
    },
    link: {
      border: 'unset',
      outline: 'unset',
      boxShadow: 'unset',
      backgroundColor: 'unset',
      textAlign: 'unset',
      color: 'unset',
      font: 'unset',
      padding: 'unset',
      textDecoration: 'none',
      width: '100%',
    },
  }))();

  return (
    <a
      className={classes.link}
      href={message.link_url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Card className={classes.smartboardMessage}>
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar variant="square" className={classes.avatar}>
              <span>
                <SmartboardIcon />
              </span>
            </Avatar>
          }
          title={message.link_title}
          subheader={renderSubheader()}
        />
      </Card>
    </a>
  );
};

export default SmartboardMessage;
