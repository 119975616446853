import { withStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    '& .MuiListItemIcon-root': {
      color: theme.palette.text.secondary,
      minWidth: theme.spacing(5),
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.text.secondary,
    },
    '&:focus': {
      backgroundColor: theme.palette.action.focus,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(MenuItem);

export default StyledMenuItem;
