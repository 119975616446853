import React, { useState, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../../../../redux/actions/Conversations';
import { SnackbarContext } from '../SnackbarContext/SnackbarContext';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const FileUpload = ({ trigger, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const { setSnackbarOpen, setSnackbarSeverity, setSnackbarText } = useContext(
    SnackbarContext
  );
  const fileRef = useRef();

  const { onUpload, name, url, requiredField, from } = data;

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
    setModalOpen(false);
  };

  const confirmUpload = async () => {
    setConfirmModalOpen(false);
    const file = fileRef.current;
    const response = await dispatch(
      uploadFile(file, name, url, setUploadedPercent)
    );
    if (response && response[requiredField]) {
      setSnackbarText(t('FileUpload.success'));
      setSnackbarSeverity('success');
      setModalOpen(false);
      if (from === 'send-message') {
        onUpload(
          response[requiredField],
          response.message_type_suggestion,
          file.name
        );
      } else if (from === 'group-info') {
        onUpload(response[requiredField]);
      }
    } else {
      setSnackbarText(t('FileUpload.error'));
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
    setUploadedPercent(0);
    fileRef.current = null;
  };

  const handleChange = async files => {
    if (files.length) {
      const file = files[0];
      if (file.name.endsWith('.exe') || file.name.endsWith('.dmg')) {
        setSnackbarText(t('FileUpload.unsuported'));
        setSnackbarSeverity('error');
      } else {
        fileRef.current = file;
        setConfirmModalOpen(true);
      }
    }
  };

  const classes = makeStyles(theme => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '5em',
    },
    paragraph: {
      whiteSpace: 'pre-line',
      fontSize: '1.3em !important',
      padding: '0 7em',
      lineHeight: '2em !important',
    },
    icon: {
      display: 'none !important',
    },
    dialogTitle: {
      position: 'relative',
      minHeight: '5em',
    },
    closeButton: {
      position: 'absolute !important',
      top: '.5em',
      right: '.5em',
    },
  }))();

  return (
    <div>
      {React.cloneElement(trigger, { onClick: handleOpenModal })}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle className={classes.dialogTitle}>
          <IconButton
            size="small"
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseModal}
            data-testid="close-upload-dialog"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent data-testid="upload-dialog">
          <DropzoneArea
            acceptedFiles={[]}
            filesLimit={1}
            dropzoneText={
              uploadedPercent === 0
                ? `${t('FileUpload.uploadArea.drag')}
                    ${t('FileUpload.uploadArea.or')}
                    ${t('FileUpload.uploadArea.browse')}
                  `
                : t('FileUpload.uploadArea.upload', {
                    percent: uploadedPercent,
                  })
            }
            classes={{
              icon: classes.icon,
              text: classes.paragraph,
              root: classes.root,
            }}
            showAlerts={['error', 'info']}
            onChange={handleChange}
            showPreviewsInDropzone={false}
            maxFileSize={50 * 1024 * 1024}
            getDropRejectMessage={() => `${t('FileUpload.max_file_size')}`}
            alertSnackbarProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              autoHideDuration: 5000,
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmModalOpen}
        onClose={closeConfirmModal}
        data-testid="confirmation-dialog"
      >
        <DialogTitle className={classes.dialogTitle}>
          <IconButton
            size="small"
            aria-label="close"
            className={classes.closeButton}
            onClick={closeConfirmModal}
          >
            <CloseIcon />
          </IconButton>
          {fileRef.current?.name}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t('FileUpload.confirmModal.text')}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={confirmUpload}
            variant="contained"
            color="primary"
            data-testid="confirm-btn"
          >
            {t('FileUpload.confirmModal.confirm')}
          </Button>
          <Button
            onClick={closeConfirmModal}
            variant="outlined"
            color="primary"
            data-testid="cancel-btn"
          >
            {t('FileUpload.confirmModal.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileUpload;
