import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '../../../../../shared/Dialog/DialogTitle/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const BlockContact = ({ handleClose, handleBlock }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    handleClose();
    handleBlock();
  };

  const classes = makeStyles(theme => ({
    text: { textAlign: 'center' },
    actions: {
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(1.5),
    },
  }))();

  return (
    <div data-testid="block-user-dialog">
      <DialogTitle>{t('ContactInfo.BlockContact.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.text}>
          {t('ContactInfo.BlockContact.please_confirm')}
          <br />
          {t('ContactInfo.BlockContact.blocked_users')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          data-testid="cancel"
        >
          {t('ContactInfo.BlockContact.cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          data-testid="confirm"
        >
          {t('ContactInfo.BlockContact.confirm')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default BlockContact;
