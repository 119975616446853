import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SocketContext } from '../../../../../../../api/socket';
import AddUsers from '../../AddUsers/AddUsers';
import GroupMembers from '../../GroupMembers/GroupMembers';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@mdi/react';
import { mdiAccountPlus } from '@mdi/js';

const GeneralInfo = ({
  chatUsers,
  chatDetails,
  chatId,
  currentUserId,
  closePopup,
}) => {
  const socket = useContext(SocketContext);
  const { t } = useTranslation();

  const owner = currentUserId === chatDetails.creator_id;

  const removeUserFromChat = user_id => () => {
    socket.emit('remove_users_from_chat', {
      chat_id: chatId,
      user_ids: [user_id],
      delete_messages: false,
    });
  };

  const classes = makeStyles(theme => ({
    membersTitle: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      fontWeight: 'bold',
    },
    addNewMember: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    add: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '& h6': { marginLeft: theme.spacing(2) },
    },
    icon: {
      width: '40px !important',
      height: '40px !important',
      padding: theme.spacing(1),
      borderRadius: '50%',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': { backgroundColor: theme.palette.primary.light },
    },
  }))();

  return (
    <>
      <Typography variant="subtitle1" className={classes.membersTitle}>
        {t('GroupInfo.members')}
      </Typography>
      {owner && (
        <div className={classes.addNewMember}>
          <Dialog
            trigger={
              <div className={classes.add}>
                <Icon path={mdiAccountPlus} size={1} className={classes.icon} />
                <Typography variant="subtitle1" color="primary">
                  {t('GroupInfo.add_member')}
                </Typography>
              </div>
            }
          >
            {close => (
              <AddUsers
                chatUsers={chatUsers}
                chat={chatDetails}
                handleClose={close}
              />
            )}
          </Dialog>
        </div>
      )}
      <GroupMembers
        currentUserId={currentUserId}
        creatorId={chatDetails.creator_id}
        users={chatUsers}
        remove={removeUserFromChat}
        closePopup={closePopup}
      />
    </>
  );
};

export default GeneralInfo;
