import React from 'react';
import { useSelector } from 'react-redux';
import { usernameText } from '../../../../utils/userUtils';
import { getTitle } from '../../../../utils/conversationUtils';
import { getChatId } from '../../../../redux/selectors/App';
import { getCurrentUserId } from '../../../../redux/selectors/CurrentUser';
import { getConversationDetails } from '../../../../redux/selectors/Conversations';
import {
  getAllOnlineContacts,
  getAllUsers,
} from '../../../../redux/selectors/Users';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ContactInfo from './ContactInfo/ContactInfo';
import GroupInfo from './GroupInfo/GroupInfo';
import ProfileImage from '../../../shared/ProfileImage/ProfileImage';
import Avatar from '../../../shared/Avatar/Avatar';
import Dialog from '../../../shared/Dialog/Dialog';

const ChatHeader = () => {
  const chatId = useSelector(getChatId);
  const conversationDetails = useSelector(getConversationDetails(chatId));
  const currentUserId = useSelector(getCurrentUserId);
  const users = useSelector(getAllUsers);
  const chatUsers = conversationDetails.users
    .filter(({ user_id }) => user_id !== currentUserId)
    .map(({ user_id }) => users[user_id]);
  const usersOnline = useSelector(getAllOnlineContacts);
  const userOnline = chatUsers.length
    ? usersOnline.find(user => user === chatUsers[0].user_id)
    : false;

  const renderAvatar = () =>
    conversationDetails.private ? (
      <Dialog
        trigger={
          <ProfileImage
            user_id={chatUsers[0].user_id}
            userOnline={userOnline}
          />
        }
      >
        {close => (
          <ContactInfo user_id={chatUsers[0].user_id} handleClose={close} />
        )}
      </Dialog>
    ) : (
      <Dialog
        trigger={<Avatar group photo={conversationDetails.image_signed_url} />}
      >
        {close => (
          <GroupInfo
            chat_id={conversationDetails.chat_id}
            handleClose={close}
          />
        )}
      </Dialog>
    );

  const renderTitle = () => (
    <p className={`${classes.truncate} ${classes.title}`}>
      {getTitle(conversationDetails, chatUsers)}
    </p>
  );

  const renderSubheader = () => (
    <p className={classes.truncate}>
      {conversationDetails.private
        ? userOnline
          ? 'Online'
          : 'Offline'
        : chatUsers.map(user => (
            <span
              key={user.user_id}
              className={`${classes.username} ${user.role === 'Teacher' &&
                classes.teacher}`}
            >
              {usernameText(user)}
            </span>
          ))}
    </p>
  );

  const classes = makeStyles(theme => {
    const cls = {
      chatHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: 60,
      },
      content: {
        margin: 'auto 0',
        display: 'flex',
        justifyContent: 'space-between',
        padding: `${theme.spacing(0.25)}px ${theme.spacing(1.5)}px`,
      },
      card: {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        width: '100%',
        height: '100%',
      },
      header: { padding: theme.spacing(1) },
      options: { display: 'flex' },
      search: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(0.5),
      },
      inputSearchOpen: {
        position: 'relative',
        transform: 'translateX(0)',
      },
      buttonSearchOpen: { display: 'none' },
      searchIcon: {
        width: 18,
        height: 18,
        fill: theme.palette.text.secondary,
        marginLeft: theme.spacing(0.5),
      },
      truncate: {
        maxWidth: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: 0,
        [theme.breakpoints.down('xs')]: { maxWidth: 200 },
      },
      title: {
        color: conversationDetails.hasLecturer
          ? theme.palette.primary.main
          : theme.palette.text.primary,
      },
      username: { '&:not(:last-child):after': { content: '", "' } },
      teacher: { color: theme.palette.primary.main },
    };

    if (theme.typography.fontSize === 17.5) {
      cls.chatHeader.maxHeight = 70;
      cls.chatHeader.height = 70;
    }

    return cls;
  })();

  return (
    <div className={classes.chatHeader}>
      <div className={classes.content}>
        <div className={classes.user}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.header}
              avatar={renderAvatar()}
              title={renderTitle()}
              subheader={renderSubheader()}
            />
          </Card>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default React.memo(ChatHeader);
