import React from 'react';
import { useTheme } from '@material-ui/styles';
import MuiAvatar from '@material-ui/core/Avatar';
import GroupIcon from '@material-ui/icons/Group';

const Avatar = ({
  user,
  group,
  photo,
  large,
  className,
  pointer = true,
  ...other
}) => {
  const { image_url, first_name, last_name } = user || {};

  const theme = useTheme();

  let userNameLetters = '';
  if (first_name && last_name)
    userNameLetters = `${first_name[0]}${last_name[0]}`;

  if (group)
    return photo ? (
      <MuiAvatar
        className={className}
        src={photo}
        style={{
          cursor: pointer ? 'pointer' : 'auto',
          fontSize: theme.typography.fontSize === 17.5 ? '1.25rem' : 'initial',
        }}
        {...other}
      />
    ) : (
      <MuiAvatar
        className={className}
        style={{
          cursor: pointer ? 'pointer' : 'auto',
          fontSize: theme.typography.fontSize === 17.5 ? '1.25rem' : 'initial',
        }}
        {...other}
      >
        {large ? <GroupIcon fontSize="large" /> : <GroupIcon />}
      </MuiAvatar>
    );

  if (image_url)
    return (
      <MuiAvatar
        className={className}
        src={image_url}
        style={{
          cursor: pointer ? 'pointer' : 'auto',
          fontSize: theme.typography.fontSize === 17.5 ? '1.25rem' : 'initial',
        }}
        {...other}
      />
    );

  return (
    <MuiAvatar
      style={{
        cursor: pointer ? 'pointer' : 'auto',
        fontSize: theme.typography.fontSize === 17.5 ? '1.25rem' : 'initial',
      }}
      {...other}
    >
      {userNameLetters}
    </MuiAvatar>
  );
};

export default Avatar;
