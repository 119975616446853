import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFileInfo } from '../../../utils/fileUtils';
import { makeStyles } from '@material-ui/styles';

const InfoSubheader = ({ message }) => {
  const { t } = useTranslation();

  const { file_data } = message;
  const { original_name } = file_data;
  const { expiration, sizeText } = getFileInfo(file_data);

  const classes = makeStyles(theme => ({
    info: {
      fontSize: '.9em',
      color: theme.palette.text.secondary,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1)}px`,
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0.5),
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 0,
      },
    },
    col: {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1)}px`,
    },
    name: {
      fontWeight: 'bold',
      maxWidth: '14ch',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      [theme.breakpoints.down('xs')]: { maxWidth: '20ch' },
    },
  }))();

  if (original_name === 'blob') return null;

  return (
    <div className={classes.info}>
      <span className={classes.col}>
        <span className={classes.name}>{original_name}</span>
        <span>{sizeText}</span>
      </span>
      {message.user.role === 'Student' && (
        <span>{`${t('File.expires')} ${expiration}`}</span>
      )}
    </div>
  );
};

export default InfoSubheader;
