import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteMessage } from '../../../../../../../redux/actions/Conversations';
import { SocketContext } from '../../../../../../../api/socket';
import DialogTitle from '../../../../../../shared/Dialog/DialogTitle/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const DeleteMessage = ({ handleClose, message, chatId }) => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDeleteMessage = () => {
    socket.emit(
      'delete',
      {
        chat_id: chatId,
        message_id: message.message_id,
      },
      _ => dispatch(deleteMessage(chatId, message.message_id))
    );
    handleClose();
  };

  const classes = makeStyles(theme => ({
    text: { textAlign: 'center' },
    actions: {
      justifyContent: 'flex-start',
      marginLeft: theme.spacing(1.5),
    },
  }))();

  return (
    <>
      <DialogTitle>{t('MessageActions.Delete.delete_message')}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className={classes.text}
        >
          {t('MessageActions.Delete.make_sure')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          data-testid="cancelButton"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="large"
        >
          {t('MessageActions.Delete.cancel')}
        </Button>
        <Button
          data-testid="deleteButton"
          onClick={handleDeleteMessage}
          color="primary"
          variant="contained"
          size="large"
        >
          {t('MessageActions.Delete.delete')}
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteMessage;
