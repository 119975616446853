export const getConversationDetails = chatId => state =>
  state.Conversations.conversations[chatId];
export const getMessages = chatId => state =>
  state.Conversations.conversations[chatId].messages;
export const getFetchingMessages = chatId => state =>
  state.Conversations.conversations[chatId].fetching_messages;
export const getSpecificMessage = (messageId, chatId) => state => {
  if (!messageId) return null;
  let conversations = state.Conversations.conversations;
  if (chatId) {
    const conversation = conversations[chatId];
    if (!conversation) return null;
    conversations = { conversation };
  }
  const messages = Object.values(conversations)
    .map(c => c.messages)
    .reduce((prev, next) => {
      return [...prev, ...next];
    }, []);
  const message = messages.find(m => m.message_id === messageId);
  if (message) return message;
  return state.Conversations.unknownMessages[messageId];
};
