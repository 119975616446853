import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './api/azureAuth';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorLogger from './api/errorLogger';
import './i18n';
import 'moment/locale/mt';
import { Azure } from 'azure-component';

const logger = new ErrorLogger();

const handleErrors = (mg, file, line, col, error) => {
  logger.addError(error);
};

window.onerror = handleErrors;
document.addEventListener('error', handleErrors);
window.addEventListener('error', handleErrors);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {process.env.NODE_ENV === 'production' ? (
        <Azure forceLogin>
          <App />
        </Azure>
      ) : (
        <App />
      )}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
