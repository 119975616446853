import { getDataFromApi, postDataToApi } from '../../api/dataFromApi';

export const addUsers = users => {
  const usersArr = Array.isArray(users) ? users : [users];

  return {
    type: 'ADD_USERS',
    payload: Object.assign(
      {},
      ...usersArr.map(u => ({
        [u.user_id]: { ...u, fullName: `${u.first_name} ${u.last_name}` },
      }))
    ),
  };
};

export const updateUser = (image_url, user_id) => {
  return {
    type: 'UPDATE_USER',
    payload: {
      image_url,
      user_id,
    },
  };
};

export const setAllContacts = contacts => {
  return {
    type: 'SET_ALL_CONTACTS',
    payload: contacts,
  };
};

export const addUserToContacts = user => {
  return {
    type: 'ADD_USER_TO_CONTACTS',
    payload: user,
  };
};

export const removeUserFromContacts = user_id => {
  return {
    type: 'REMOVE_USER_FROM_CONTACTS',
    payload: user_id,
  };
};

export const removeUsersFromContacts = user_ids => {
  return {
    type: 'REMOVE_USERS_FROM_CONTACTS',
    payload: user_ids,
  };
};

export const onlineContacts = contacts => {
  return {
    type: 'ONLINE_CONTACTS',
    payload: contacts,
  };
};

export const setOnlineStatus = contact => {
  return {
    type: 'SET_ONLINE_STATUS',
    payload: contact,
  };
};

export const getAllContacts = () => {
  return async (dispatch, getState) => {
    const contacts = await getDataFromApi('chat/contacts/list/');
    if (!contacts) return;
    dispatch(addUsers(contacts.contacts));
    dispatch(
      setAllContacts(
        contacts.contacts.reduce((prev, user) => {
          const contact = {
            ...user,
            fullName: `${user.first_name} ${user.last_name}`,
          };
          if (
            !getState().Users.contacts.find(c => c.user_id === user.user_id)
          ) {
            prev.push(contact);
          }
          return prev;
        }, [])
      )
    );
  };
};

export const getUser = user_id => {
  return async (dispatch, getState) => {
    const user = getState().Users.users[user_id];
    if (!user) {
      const user = await getDataFromApi(`auth/user/${user_id}/`);
      dispatch(addUsers(user));
    }
  };
};

export const addNewContact = user_id => {
  return async (dispatch, getState) => {
    await postDataToApi('chat/contacts/add/', {
      contact_id: user_id,
    });
    await dispatch(getUser(user_id));
    const user = getState().Users.users[user_id];
    const userExists = getState().Users.contacts.find(
      user => user.user_id === user_id
    );
    if (userExists) return;
    dispatch(addUserToContacts(user));
  };
};

export const removeExistingContact = user_id => {
  return async (dispatch, getState) => {
    await postDataToApi('chat/contacts/remove/', {
      contact_id: user_id,
    });
    dispatch(removeUserFromContacts(user_id));
  };
};

export const removeExistingContacts = user_ids => {
  return async (dispatch, getState) => {
    await Promise.all(
      user_ids.map(
        async user_id =>
          await postDataToApi('chat/contacts/remove/', {
            contact_id: user_id,
          })
      )
    );
    dispatch(removeUsersFromContacts(user_ids));
  };
};
