import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import youtubeUrl from 'youtube-url';
import { getName } from '../../../../../utils/userUtils';
import { getChatId } from '../../../../../redux/selectors/App';
import { getCurrentUserId } from '../../../../../redux/selectors/CurrentUser';
import {
  getFileDataForUnknownMessage,
  updateMessageFileData,
} from '../../../../../redux/actions/Conversations';
import {
  getConversationDetails,
  getSpecificMessage,
} from '../../../../../redux/selectors/Conversations';
import { getAllUsers } from '../../../../../redux/selectors/Users';
import Moment from '../../../../shared/Moment';
import MediaMessage from './MediaMessage/MediaMessage';
import MessageActions from './MessageActions/MessageActions';
import ContactInfo from '../../ChatHeader/ContactInfo/ContactInfo';
import SmartboardMessage from './MediaMessage/SmartboardMessage/SmartboardMessage';
import Avatar from '../../../../shared/Avatar/Avatar';
import Dialog from '../../../../shared/Dialog/Dialog';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ForwardIcon from '@material-ui/icons/Forward';
import ReplyIcon from '@material-ui/icons/Reply';
import ReceivedIcon from '@material-ui/icons/Done';
import SeenIcon from '@material-ui/icons/DoneAll';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import AudioIcon from '@material-ui/icons/AudiotrackOutlined';
import VideoIcon from '@material-ui/icons/VideocamOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTheme } from '@material-ui/core';

const Message = ({ message, classes }) => {
  const dispatch = useDispatch();
  const chatId = useSelector(getChatId);
  const currentUserId = useSelector(getCurrentUserId);
  const chatDetails = useSelector(getConversationDetails(chatId));
  const users = useSelector(getAllUsers);
  const theme = useTheme();
  const { t } = useTranslation();

  const isCurrentUser = message => currentUserId === message.user_id;
  const seenBy = message => message.seen_by.filter(id => id !== currentUserId);
  const fetchData =
    message.message_type &&
    !['text', 'youtube', 'link', 'smartboard'].includes(message.message_type) &&
    !message.file_data;

  const showReply = message =>
    message.replies_to && message.replies_to.message_id && !message.deleted;
  const showForward = message => message.forwards.user_id;

  const messageToReply = useSelector(
    getSpecificMessage(message.replies_to.message_id, chatId)
  );
  const messageToForward = useSelector(
    getSpecificMessage(message.forwards.message_id)
  );

  useEffect(() => {
    if (fetchData) {
      dispatch(updateMessageFileData(message));
    }
    // eslint-disable-next-line
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      if (
        message.replies_to &&
        message.replies_to.message_id &&
        !messageToReply
      ) {
        dispatch(getFileDataForUnknownMessage(message.replies_to));
      }
    })();
  }, [message.replies_to, messageToReply, dispatch]);

  useEffect(() => {
    (async () => {
      if (
        message.forwards &&
        message.forwards.message_id &&
        !messageToForward
      ) {
        dispatch(getFileDataForUnknownMessage(message.forwards));
      }
    })();
  }, [message.forwards, messageToForward, dispatch]);

  const renderMessageToReply = (message, messageToReply) => {
    if (
      !messageToReply ||
      messageToReply.message_type === 'text' ||
      !messageToReply.file_data
    )
      return message.replies_to.body;
    return <MediaMessage message={messageToReply} />;
  };

  const renderMessageToForward = (message, messageToForward) => {
    if (
      !messageToForward ||
      messageToForward.message_type === 'text' ||
      !messageToForward.file_data
    )
      return message.forwards.body;
    return <MediaMessage message={messageToForward} />;
  };

  const renderDeletedMessage = message => {
    return (
      <div
        data-testid="deleted"
        className={classes.deleted}
        style={{
          color: isCurrentUser(message)
            ? theme.palette.text.secondary
            : theme.palette.text.primary,
        }}
      >
        <NotInterestedIcon />
        <Typography variant="body2" component="span">
          {isCurrentUser(message)
            ? t('Message.you_deleted_this_message')
            : t('Message.message_deleted_by_contact')}
        </Typography>
      </div>
    );
  };

  const renderLink = ({ link_title, link_description, body }) => (
    <div>
      {/* <Typography variant="subtitle1" className={classes.title}>
        {link_title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {link_description}
      </Typography> */}
      <Link href={body} target="_blank" rel="noopener">
        {body}
      </Link>
    </div>
  );

  const renderSeen = message => {
    if (message.replace) return null;
    if (message.error)
      return <ErrorOutlineIcon className={classes.errorIcon} />;
    if (message.placeholder) return <AccessTimeIcon />;
    if (
      !message.statuses.received ||
      !isCurrentUser(message) ||
      message.deleted
    )
      return null;
    if (chatDetails.private) return renderSeenIcon(message);
    return renderSeenWithTooltip(message);
  };

  const renderSeenIcon = message => {
    if (seenBy(message).length > 0) return <SeenIcon color="primary" />;
    return <ReceivedIcon />;
  };

  const renderSeenWithTooltip = message => {
    const seenByUsers = seenBy(message);
    const title = seenByUsers.length
      ? seenByUsers.map(u => getName(users[u])).join(', ')
      : t('Message.nobody_seen');

    return <Tooltip title={title}>{renderSeenIcon(message)}</Tooltip>;
  };

  const renderPlaceholder = message => {
    return (
      <div data-testid="placeholder" className={classes.placeholder}>
        {renderPlaceholderIcon(message.message_type)}
      </div>
    );
  };

  const renderPlaceholderIcon = message_type => {
    switch (message_type) {
      case 'file':
        return <FileIcon data-testid="file-icon" />;
      case 'image':
        return <ImageIcon />;
      case 'audio':
        return <AudioIcon />;
      case 'video':
        return <VideoIcon />;
      default:
        return <FileIcon />;
    }
  };

  return (
    <div
      className={`${classes.root} ${isCurrentUser(message) &&
        classes.currentUserRoot}`}
    >
      {!isCurrentUser(message) && (
        <Dialog
          trigger={
            <div className={classes.avatar}>
              <Avatar user={message.user} />
            </div>
          }
        >
          {close => (
            <ContactInfo user_id={message.user.user_id} handleClose={close} />
          )}
        </Dialog>
      )}
      <div
        key={message.message_id}
        id={message.message_id}
        className={`${classes.message} ${isCurrentUser(message) &&
          classes.currentUserMessage}`}
      >
        <div className={classes.header}>
          <div
            className={classes.headerText}
            style={{
              color: isCurrentUser(message)
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
            }}
          >
            {showForward(message) && <ForwardIcon />}
            {showReply(message) && <ReplyIcon />}
            {!isCurrentUser(message) && !message.deleted && (
              <Typography variant="subtitle2" color="primary">
                {getName(message.user)}
              </Typography>
            )}
            {showForward(message) && (
              <Typography variant="subtitle2">
                {isCurrentUser(message) && <span>{t('Message.you')} </span>}
                {t('Message.forwarded')}
              </Typography>
            )}
            {showReply(message) && (
              <Typography variant="subtitle2">
                {isCurrentUser(message) && <span>{t('Message.you')} </span>}
                {t('Message.replied')}
              </Typography>
            )}
          </div>
          {!message.placeholder && <MessageActions message={message} />}
        </div>
        <div
          data-testid="content"
          className={classes.text}
          style={{
            marginTop: message.placeholder
              ? theme.spacing(3)
              : theme.spacing(1),
          }}
        >
          {showForward(message) && (
            <div className={classes.repliesOrForwards}>
              <Typography
                variant="subtitle2"
                style={{
                  color: isCurrentUser(message)
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
                }}
              >
                {getName(users[message.forwards.user_id])}
              </Typography>
              <Typography
                variant="body2"
                data-testid="forward"
                component="span"
              >
                {messageToForward && messageToForward.deleted
                  ? renderDeletedMessage(messageToForward)
                  : renderMessageToForward(message, messageToForward)}
              </Typography>
            </div>
          )}
          {showReply(message) && (
            <div className={classes.repliesOrForwards}>
              <Typography variant="subtitle2">
                {getName(users[message.replies_to.user_id])}
              </Typography>
              <Typography variant="body2" component="span" data-testid="reply">
                {messageToReply && messageToReply.deleted
                  ? renderDeletedMessage(messageToReply)
                  : renderMessageToReply(message, messageToReply)}
              </Typography>
            </div>
          )}
          {message.deleted ? (
            renderDeletedMessage(message)
          ) : (message.placeholder &&
              message.message_type &&
              !['youtube', 'link', 'smartboard'].includes(
                message.message_type
              )) ||
            fetchData ? (
            renderPlaceholder(message)
          ) : showForward(message) ? (
            ''
          ) : message.file_data ? (
            <MediaMessage message={message} />
          ) : message.message_type === 'smartboard' ? (
            <SmartboardMessage message={message} />
          ) : message.message_type === 'youtube' ? (
            <YouTube
              videoId={youtubeUrl.extractId(message.body)}
              opts={{ height: '240', width: '400' }}
              className={classes.youtube}
            />
          ) : message.message_type === 'link' ? (
            renderLink(message)
          ) : (
            message.body
          )}
          {message.last_edited &&
            message.created_at !== message.last_edited &&
            !message.deleted && (
              <Typography
                variant="caption"
                component="p"
                className={classes.edited}
                style={{
                  color: isCurrentUser(message)
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
                }}
              >
                {t('Message.edited')}
              </Typography>
            )}
        </div>
        <div
          className={classes.info}
          style={{
            color: isCurrentUser(message)
              ? theme.palette.text.secondary
              : theme.palette.text.primary,
          }}
        >
          {renderSeen(message)}
          <Moment onlyTime>{message.created_at}</Moment>
        </div>
      </div>
    </div>
  );
};

export default Message;
