import i18n from 'i18next';
import { postDataToApi, getDataFromApi } from '../../api/dataFromApi';
import { attachFileData, getConversationDetails } from './Conversations';
import { getUser } from './Users';

export const setLoading = loading => {
  return {
    type: 'SET_SEARCH_LOADING',
    payload: loading,
  };
};

export const setSearchResults = results => {
  return {
    type: 'SET_SEARCH_RESULTS',
    payload: results,
  };
};

export const setMessagesChatImages = chats => {
  return {
    type: 'SET_MESSAGES_CHAT_IMAGES',
    payload: chats,
  };
};

export const updateResults = (key, results) => {
  return {
    type: 'UPDATE_RESULTS',
    payload: { key, results },
  };
};

export const appendResults = (key, results) => {
  return {
    type: 'APPEND_RESULTS',
    payload: { key, results },
  };
};

export const addMoreResults = (data, key) => {
  return {
    type: 'ADD_MORE_RESULTS',
    payload: { data, key },
  };
};

export const setSearchTerm = searchTerm => {
  return {
    type: 'SET_SEARCH_TERM',
    payload: searchTerm,
  };
};

export const setInitSearch = searchTerm => {
  return {
    type: 'SET_INIT_SEARCH',
    payload: searchTerm,
  };
};

export const setInitValueApplied = applied => {
  return {
    type: 'SET_INIT_VALUE_APPLIED',
    payload: applied,
  };
};

export const incrementIndex = () => {
  return {
    type: 'INCREMENT_INDEX',
  };
};

export const decrementIndex = () => {
  return {
    type: 'DECREMENT_INDEX',
  };
};

export const setIndex = index => {
  return {
    type: 'SET_INDEX',
    payload: index,
  };
};

export const resetSearch = () => {
  return {
    type: 'RESET_SEARCH',
  };
};

export const setLoadingMore = (key, val) => {
  return {
    type: 'SET_LOADING_MORE',
    payload: { key, val },
  };
};

export const showMediaSkeleton = show => {
  return {
    type: 'SHOW_MEDIA_SKELETON',
    payload: show,
  };
};

export const toggleExpanded = (key, expanded) => {
  return {
    type: 'TOGGLE_EXPANDED',
    payload: { key, expanded },
  };
};

export const toggleDataFetched = (key, fetched) => {
  return {
    type: 'TOGGLE_DATA_FETCHED',
    payload: { key, fetched },
  };
};

export const displayAll = key => {
  return {
    type: 'DISPLAY_ALL',
    payload: key,
  };
};

export const updateUserImages = users => {
  return {
    type: 'UPDATE_USER_IMAGES',
    payload: users,
  };
};

const fetchUsers = async (data, dispatch) => {
  await Promise.all(data.map(async id => await dispatch(getUser(id))));
};

const fetchChats = async (data, dispatch) => {
  await Promise.all(
    data.map(async r => await dispatch(getConversationDetails(r.chat_id)))
  );
};

const getCmisImages = data => {
  return async dispatch => {
    if (!data) return;
    const cmisImages = Object.fromEntries(
      await Promise.all(
        data.results
          .filter(r => !r.image_url)
          .map(async r => {
            let cmisUser = await getDataFromApi(
              `users/${r.user_id}/`,
              'classter'
            );
            if (!cmisUser) {
              cmisUser = {
                image: '',
              };
            }
            return [r.user_id, cmisUser.image];
          })
      )
    );
    dispatch(
      updateUserImages(
        data.results.map(r => ({
          ...r,
          image_url:
            r.image_url || `data:image/png;base64,${cmisImages[r.user_id]}`,
        }))
      )
    );
  };
};

export const startSearch = (query, snackbar) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setSearchTerm(query));
    const body = { search_term: query };
    try {
      const data = await Promise.all([
        await postDataToApi('auth/user/search/', body),
        await postDataToApi('chat/search/messages/text/', body, 'v1'),
        await postDataToApi('chat/search/messages/media/', body, 'v1'),
        await postDataToApi('chat/search/chats/', body, 'v1'),
      ]);
      await fetchUsers(
        Array.from(
          new Set([...data[1].results, ...data[2].results].map(r => r.user_id))
        ),
        dispatch
      );
      await fetchChats(
        data[3].results.filter(
          r => !getState().Conversations.conversations[r.chat_id]
        ),
        dispatch
      );
      if (getState().Search.searchTerm === query)
        dispatch(setSearchResults(data));
    } catch {
      snackbar(i18n.t('SearchResults.error'), 'error');
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getMessagesChatImages = data => {
  return async (dispatch, getState) => {
    if (!data.results.length) return;
    await Promise.all(
      Array.from(
        new Set(
          data.results
            .filter(m => !getState().Conversations.conversations[m.chat_id])
            .map(m => m.chat_id)
        )
      ).map(async chatId => await dispatch(getConversationDetails(chatId)))
    );
    const chatDetails = await postDataToApi(
      'chat/chats/',
      {
        chat_id_list: Array.from(
          new Set(data.displayedResults.map(r => r.chat_id))
        ),
      },
      'v1'
    );
    const images = await postDataToApi(
      'files/group-image/',
      {
        uuid_list: chatDetails.map(c => c.image),
      },
      'v1'
    );
    dispatch(
      setMessagesChatImages(
        Object.assign(
          {},
          ...chatDetails.map(c => ({
            [c.chat_id]: {
              private: c.private,
              title: c.title,
              signed_url: images.url_list.find(e => e.chat_uuid === c.image)
                .signed_url,
            },
          }))
        )
      )
    );
  };
};

export const getChatsImages = (data, append) => {
  return async dispatch => {
    if (!data.results.length) return;
    const arr = append ? data.results : data.displayedResults;
    const images = await postDataToApi(
      'files/group-image/',
      {
        uuid_list: arr.map(c => c.image),
      },
      'v1'
    );
    const chatResults = arr.map((c, i) => ({
      ...c,
      image_signed_url: images.url_list[i].signed_url,
    }));
    if (append) dispatch(appendResults('chats', chatResults));
    else dispatch(updateResults('chats', chatResults));
  };
};

export const getMediaResultsFileData = (data, append) => {
  return async (dispatch, getState) => {
    if (!data.results.length) return;
    dispatch(showMediaSkeleton(true));
    const arr = append ? data.results : data.displayedResults;
    const resultsData = await Promise.all(
      arr.map(async r => await attachFileData(r))
    );
    await Promise.all(arr.map(async r => await dispatch(getUser(r.user_id))));
    const resultsWithUser = resultsData.map(r => ({
      ...r,
      user: getState().Users.users[r.user_id],
    }));
    dispatch(showMediaSkeleton(false));
    if (append) dispatch(appendResults('media', resultsWithUser));
    else dispatch(updateResults('media', resultsWithUser));
  };
};

export const getUsers = data => {
  return async dispatch => {
    dispatch(getCmisImages(data));
    if (!data.results.length) return;
    await Promise.all(
      data.displayedResults.map(async u => await dispatch(getUser(u.user_id)))
    );
    dispatch(toggleDataFetched('contacts', true));
  };
};

export const loadMoreResults = (data, key, snackbar) => {
  return async (dispatch, getState) => {
    if (
      getState().Search.results[key].results.length >
      getState().Search.results[key].displayedResults.length
    ) {
      dispatch(displayAll(key));
      return;
    }
    dispatch(setLoadingMore(key, true));
    const searchTerm = getState().Search.searchTerm;
    const url = data.links.next.split('/v0/')[1];
    const nextPage = key === 'contacts' ? url : 'chat/' + url;
    try {
      const resultsData = await postDataToApi(
        nextPage,
        { search_term: searchTerm },
        key === 'contacts' ? 'v0' : 'v1'
      );
      if (key === 'contacts') {
        dispatch(addMoreResults(resultsData, key));
      } else if (key === 'messages') {
        await fetchUsers(
          resultsData.results.map(r => r.user_id),
          dispatch
        );
        dispatch(addMoreResults(resultsData, key));
      } else if (key === 'media') {
        await fetchUsers(
          resultsData.results.map(r => r.user_id),
          dispatch
        );
        dispatch(getMediaResultsFileData(resultsData, true));
      } else if (key === 'chats') {
        await fetchChats(
          resultsData.results.filter(
            r => !getState().Conversations.conversations[r.chat_id]
          ),
          dispatch
        );
        dispatch(getChatsImages(resultsData, true));
      }
    } catch (e) {
      snackbar(i18n.t('SearchResults.error'), 'error');
    } finally {
      dispatch(setLoadingMore(key, false));
    }
  };
};
