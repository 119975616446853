import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateMessageFileData } from '../../../../../../../redux/actions/Conversations';
import ImagePreview from './ImagePreview/ImagePreview';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';

const ImageMessage = ({ message }) => {
  const dispatch = useDispatch();
  const [imgLoading, setImgLoading] = useState(false);
  const counter = useRef(0);
  const _isMounted = useRef(false);

  const { file_data } = message;
  const { original_name, media_file_thumb_url } = file_data;

  useEffect(() => {
    _isMounted.current = true;
    return () => (_isMounted.current = false);
  }, []);

  const handleImageError = async e => {
    if (counter.current >= 3) return;
    counter.current++;
    setImgLoading(true);
    await dispatch(updateMessageFileData(message));
    if (_isMounted.current) setImgLoading(false);
  };

  const getImageUrl = () => {
    if (media_file_thumb_url) return media_file_thumb_url;
    handleImageError();
  };

  const classes = makeStyles(theme => ({
    imageMessage: {
      margin: 'auto',
      maxWidth: 280,
      maxHeight: 500,
      boxShadow: 'none',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
    },
    spinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1em 0',
    },
  }))();

  return (
    <div data-testid="card">
      {imgLoading ? (
        <div data-testid="spinner" className={classes.spinner}>
          <CircularProgress />
        </div>
      ) : (
        <ImagePreview
          trigger={
            <CardMedia
              data-testid="media"
              className={classes.imageMessage}
              component="img"
              image={getImageUrl()}
              title={original_name}
              onError={handleImageError}
            />
          }
          message={message}
        />
      )}
    </div>
  );
};

export default ImageMessage;
