import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconLearningApp from '../../../assets/icons/IconLearningApp';
import IconILP from '../../../assets/icons/IconILP';
import IconSmartboard from '../../../assets/icons/IconSmartboard';
import IconProsys from '../../../assets/icons/IconProsys';
import StyledMenu from '../../shared/StyledMenu/StyledMenu';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';

const AppSwitcher = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = makeStyles(theme => ({
    link: {
      display: 'flex',
      alignItems: 'center',
      '& > span': { marginLeft: theme.spacing(2) },
      '& > svg': { fontSize: '1.2em' },
    },
    iconButton: { marginRight: theme.spacing(1) },
    icon: {
      fill: 'white',
      fontSize: '1.2em',
    },
    support: {
      display: 'flex',
      marginTop: theme.spacing(1),
      all: 'unset',
      color: theme.palette.text.secondary,
      fontSize: '0.8rem',
      cursor: 'pointer',
      '& > span': { marginLeft: theme.spacing(2) },
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }))();

  return (
    <>
      <IconButton onClick={handleClick} className={classes.iconButton}>
        <AppsIcon className={classes.icon} />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          button
          component={Link}
          href={process.env.REACT_APP_LEARNING_APP_URL}
          target="_blank"
          underline="none"
          color="textPrimary"
          className={classes.link}
        >
          <IconLearningApp />
          <span>Learning App</span>
        </MenuItem>
        <MenuItem
          button
          component={Link}
          href={process.env.REACT_APP_ILP_APP_URL}
          target="_blank"
          underline="none"
          color="textPrimary"
          className={classes.link}
        >
          <IconILP />
          <span>ILP</span>
        </MenuItem>
        <MenuItem
          button
          component={Link}
          href={process.env.REACT_APP_SMARTBOARD_APP_URL}
          target="_blank"
          underline="none"
          color="textPrimary"
          className={classes.link}
        >
          <IconSmartboard />
          <span>Smartboard</span>
        </MenuItem>
        <MenuItem
          button
          component={Link}
          href={process.env.REACT_APP_PROSYS_URL}
          target="_blank"
          underline="none"
          color="textPrimary"
          className={classes.link}
        >
          <IconProsys />
          <span>MyTrailMap</span>
        </MenuItem>
        <MenuItem
          className={classes.support}
          component={Link}
          href="mailto:David.D.Debono@mcast.edu.mt"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{t('ProfileActions.support')}</span>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default AppSwitcher;
