import React, { useEffect, useRef, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SocketContext } from '../../../../api/socket';
import {
  getMessages,
  checkPlaceholderTimeouts,
  clearChatMessages,
} from '../../../../redux/actions/Conversations';
import {
  getChatId,
  getIsVisible,
  getSettings,
} from '../../../../redux/selectors/App';
import {
  getConversationDetails,
  getFetchingMessages,
  getMessages as selectMessages,
} from '../../../../redux/selectors/Conversations';
import { getCurrentUserId } from '../../../../redux/selectors/CurrentUser';
import Message from './Message/Message';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const MessageBoard = () => {
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const chatId = useSelector(getChatId);
  const chatDetails = useSelector(getConversationDetails(chatId));
  const currentUserId = useSelector(getCurrentUserId);
  const isVisible = useSelector(getIsVisible);
  const messages = useSelector(selectMessages(chatId));
  const fetchingMessages = useSelector(getFetchingMessages(chatId));
  const [today, yesterday] = [moment(), moment().subtract(1, 'day')];
  const [show60Messages, setShow60Messages] = useState(false);

  const settings = useSelector(getSettings);

  const { messages_count, latest_message } = chatDetails;
  const clearMessages =
    latest_message &&
    messages[0] &&
    (latest_message.message_id !== messages[0].message_id ||
      latest_message.deleted !== messages[0].deleted ||
      latest_message.last_edited !== messages[0].last_edited);

  const messageGroup = [...messages]
    .slice(0, show60Messages ? 60 : messages.length)
    .reduce((prev, next) => {
      const timestamp = moment(next.created_at);
      // TODO: Format dates
      const date = timestamp.isSame(today, 'day')
        ? t('MessageBoard.today')
        : timestamp.isSame(yesterday, 'day')
        ? t('MessageBoard.yesterday')
        : timestamp.format('DD/MM/YYYY');
      if (!prev[date]) prev[date] = [];
      prev[date] = [...prev[date], next];
      return prev;
    }, {});

  const messageWrapper = useRef(null);
  const messageWrapperScroll = useRef({
    scrollTop: 0,
    scrollHeight: 0,
  });

  useEffect(() => {
    scrollToBottom();
    dispatch(checkPlaceholderTimeouts(chatId));
  }, [chatId, dispatch]);

  useEffect(() => {
    if (
      messageWrapperScroll.current.scrollHeight -
        messageWrapperScroll.current.scrollTop -
        messageWrapper.current.clientHeight <
        100 ||
      (latest_message && latest_message.user_id === currentUserId)
    ) {
      setShow60Messages(true);
      scrollToBottom();
    }

    messageWrapperScroll.current.scrollHeight =
      messageWrapper.current.scrollHeight;
  }, [currentUserId, latest_message]);

  useEffect(() => {
    if (!isVisible) return;

    const unseenMessages = messages
      .filter(m => !m.statuses.seen && m.user_id !== currentUserId)
      .map(m => ({
        chat_id: chatId,
        message_id: m.message_id,
        statuses: { received: true, seen: true, read: true },
      }));

    if (unseenMessages.length) socket.emit('ack', unseenMessages);
    // eslint-disable-next-line
  }, [chatId, currentUserId, isVisible, messages.length, socket, dispatch]);

  useEffect(() => {
    if (clearMessages) {
      dispatch(clearChatMessages(chatId));
    }
  }, [clearMessages, chatId, dispatch]);

  useEffect(() => {
    if (typeof messages_count === 'undefined') {
      (async () => {
        await dispatch(getMessages(socket));
        scrollToBottom();
      })();
    }
    // eslint-disable-next-line
  }, [dispatch, chatId, messages_count]);

  const getMoreMessages = async () => {
    if (messages.length < messages_count) {
      const oldScrollHeight = messageWrapper.current.scrollHeight;

      const response = await dispatch(getMessages(socket));

      if (response !== false) {
        messageWrapper.current.scrollTop = // Change current message wrapper scroll position
          messageWrapper.current.scrollHeight - // use max container scroll height
          oldScrollHeight + // minus previous container scroll height
          messageWrapperScroll.current.scrollTop - // move a bit down based on where we previously were when scroll happen
          88; // and remove extra 88 from height of the loading spinner

        messageWrapperScroll.current.scrollHeight =
          messageWrapper.current.scrollHeight;
      }
      setShow60Messages(false);
      return response;
    }
  };

  const scrollHandler = e => {
    if (
      e.target.scrollTop <= 600 &&
      e.target.scrollTop < messageWrapperScroll.current.scrollTop
    ) {
      getMoreMessages().then();
    }
    messageWrapperScroll.current.scrollTop = e.target.scrollTop;
  };

  const scrollToBottom = () => {
    setShow60Messages(true);
    messageWrapper.current.scrollTop =
      messageWrapper.current.scrollHeight + messageWrapper.current.clientHeight;
  };

  const classes = makeStyles(theme => ({
    messageBoard: {
      display: 'flex',
      flexFlow: 'column',
      flex: 1,
      padding: '0 1em 1em 1em',
      overflow: 'auto',
    },
    innerWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
      flexGrow: 1,
      minHeight: 'fit-content',
    },
    date: {
      alignSelf: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
      color: theme.palette.text.secondary,
    },
    loadMore: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      display: 'grid',
      placeItems: 'center',
    },
  }))();

  const messageClasses = makeStyles(theme => ({
    root: {
      display: 'flex',
      marginBottom: theme.spacing(2),
    },
    avatar: {
      marginRight: theme.spacing(1),
      height: '100%',
      display: 'grid',
      placeItems: 'center',
    },
    message: {
      borderRadius: 12,
      backgroundColor:
        settings.common.background_color === 'default'
          ? '#badaea'
          : theme.palette.background.default,
      color: theme.palette.getContrastText('#badaea'),
      border: `1px solid ${
        settings.common.background_color === 'default'
          ? theme.palette.grey[300]
          : theme.palette.grey[400]
      }`,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      width: 'max-content',
      minWidth: '20vw',
      maxWidth: '60vw',
      minHeight: 'fit-content',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerText: {
      display: 'flex',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, .38)',
      '& svg': {
        fontSize: '1em',
        marginRight: theme.spacing(0.5),
      },
      '& h6': { marginRight: theme.spacing(0.5) },
    },
    text: {
      marginTop: theme.spacing(3), // TODO: MESSAGE
      lineHeight: '1.25em',
      fontSize: '1.075em',
      wordWrap: 'break-word',
    },
    info: {
      fontSize: '.75em',
      color: 'rgba(0, 0, 0, .54)',
      alignSelf: 'flex-end',
      marginTop: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fontSize: '1.5em',
        marginRight: theme.spacing(1),
      },
    },
    currentUserRoot: {
      alignSelf: 'flex-end',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    currentUserMessage: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.getContrastText(theme.palette.background.paper),
    },
    deleted: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      color: 'rgba(0, 0, 0, .54)',
      '& span': {
        fontStyle: 'italic',
        marginLeft: theme.spacing(0.5),
      },
    },
    edited: {
      fontStyle: 'italic',
      color: 'rgba(0, 0, 0, .54)',
    },
    repliesOrForwards: {
      margin: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
      borderLeft: `2px solid ${theme.palette.text.secondary}`,
      paddingLeft: theme.spacing(1),
      '& h6': { color: 'rgba(0, 0, 0, .38)' },
    },
    youtube: {
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: { height: 'auto' },
    },
    // link: { marginTop: theme.spacing(2) },
    title: { fontWeight: 'bold' },
    placeholder: {
      padding: `${theme.spacing(5)}px 0`,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      },
      '& svg': {
        color: theme.palette.action.disabled,
        fontSize: '4rem',
        [theme.breakpoints.down('xs')]: { fontSize: '2rem' },
      },
    },
    errorIcon: { fill: 'red' },
  }))();

  return (
    <div
      id="messageBoard"
      className={classes.messageBoard}
      onScroll={scrollHandler}
      ref={messageWrapper}
    >
      {fetchingMessages && (
        <div className={classes.loadMore}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.innerWrapper}>
        {Object.entries(messageGroup).map(([date, messages]) => (
          <React.Fragment key={date}>
            {messages.map(message => (
              <Message
                key={message.message_id || message.message_short_id}
                message={message}
                classes={messageClasses}
              />
            ))}

            <Typography className={classes.date} variant="caption">
              {date}
            </Typography>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MessageBoard;
