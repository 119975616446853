import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LinkIcon from '@material-ui/icons/Link';

const Tabs = ({ value, handleChange, conversationExists = true }) => {
  const { t } = useTranslation();

  const classes = makeStyles(theme => ({
    tabsContainer: {
      padding: `0 ${theme.spacing(2)}px`,
      position: 'sticky',
      top: '0',
      zIndex: 1100,
    },
    tabs: {
      '& .MuiTab-root': {
        minWidth: 'unset',
        '& .MuiTab-wrapper': {
          flexDirection: 'row',
          '& .MuiSvgIcon-root': { marginBottom: 0 },
        },
      },
      '& .MuiTab-labelIcon': {
        minHeight: theme.spacing(6),
        paddingTop: theme.spacing(0.75),
      },
    },
    tabLabel: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('xs')]: { display: 'none' },
    },
  }))();

  return (
    <Paper square elevation={0} className={classes.tabsContainer}>
      <MuiTabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
      >
        <Tab
          icon={<InfoOutlinedIcon />}
          label={
            <span className={classes.tabLabel}>{t('ContactInfo.general')}</span>
          }
        />
        <Tab
          icon={<ImageIcon />}
          label={
            <span className={classes.tabLabel}>{t('ContactInfo.media')}</span>
          }
          disabled={!conversationExists}
        />
        <Tab
          icon={<InsertDriveFileIcon />}
          label={
            <span className={classes.tabLabel}>{t('ContactInfo.files')}</span>
          }
          disabled={!conversationExists}
        />
        <Tab
          icon={<LinkIcon />}
          label={
            <span className={classes.tabLabel}>{t('ContactInfo.links')}</span>
          }
          disabled={!conversationExists}
        />
      </MuiTabs>
    </Paper>
  );
};

export default Tabs;
