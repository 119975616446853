import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllContacts } from '../../../../redux/selectors/Users';
import {
  resetCreatingConversation,
  updateCreatingConversation,
} from '../../../../redux/actions/App';
import { getCreatingConversation } from '../../../../redux/selectors/App';
import { postDataToApi } from '../../../../api/dataFromApi';
import { getName } from '../../../../utils/userUtils';
import Avatar from '../../../shared/Avatar/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

const NewConversation = () => {
  const contacts = useSelector(getAllContacts);
  const { initialUser } = useSelector(getCreatingConversation);
  const [value, setValue] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);
  const [next, setNext] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    let defaultOptions = Object.values(contacts).map(c => ({
      ...c,
      group: 'Contacts',
    }));
    if (initialUser) {
      defaultOptions.unshift({ ...initialUser, group: 'Initial user' });
      setValue([initialUser]);
    }
    setOptions(defaultOptions);
  }, [contacts, initialUser]);

  const handleChange = (e, v) => {
    dispatch(updateCreatingConversation({ users: v }));
    setValue(v);
    setOptions(
      options.filter(
        o => o.group === 'Contacts' || v.some(v => v.user_id === o.user_id)
      )
    );
    setSearchTerm('');
  };

  const handleCancel = () => {
    dispatch(resetCreatingConversation());
  };

  const renderOption = option => (
    <div className={classes.option}>
      <Avatar user={option} />
      <Typography variant="subtitle1">{getName(option)}</Typography>
    </div>
  );

  const fetchOptions = async (url, searchTerm) => {
    setLoading(true);
    const { results, links } = await postDataToApi(url, {
      search_term: searchTerm,
    });
    const additionalOptions = results
      .filter(r => !options.some(o => o.user_id === r.user_id))
      .map(r => ({ ...r, group: 'Search results' }));
    setOptions([...options, ...additionalOptions]);
    setNext(links?.next);
    setLoading(false);
  };

  const handleInputChange = async e => {
    const { value: v } = e.target;
    setSearchTerm(v);
    if (v.trim().length >= 3) {
      fetchOptions('auth/user/search/', v);
    }
  };

  const loadMoreResults = async () => {
    if (!next || loading || searchTerm.trim().length < 3) return;
    fetchOptions(next.split('/v0/')[1], searchTerm);
  };

  const classes = makeStyles(theme => {
    const cls = {
      root: {
        minHeight: 60,
        display: 'flex',
        flexDirection: 'column',
      },
      content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiAutocomplete-root': {
          flex: 1,
          height: '100%',
          '& .MuiFormControl-root': {
            height: '100%',
            '& .MuiInputBase-root': {
              height: '100%',
              padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
              paddingLeft: theme.spacing(2),
              '& .MuiInputAdornment-root': {
                color: theme.palette.text.secondary,
              },
              '& .MuiInputBase-input': {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1.125),
              },
            },
          },
        },
      },
      cancel: {
        height: '75%',
        margin: `auto ${theme.spacing(2)}px`,
      },
      option: {
        display: 'flex',
        alignItems: 'center',
        '& h6': { marginLeft: theme.spacing(2) },
      },
    };

    if (theme.typography.fontSize === 17.5) cls.root.minHeight = 70;

    return cls;
  })();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Autocomplete
          multiple
          className={classes.root}
          value={value}
          onChange={handleChange}
          loading={loading}
          options={options}
          groupBy={option => option.group}
          renderOption={renderOption}
          getOptionLabel={getName}
          getOptionSelected={(option, value) =>
            option.user_id === value.user_id
          }
          ListboxProps={{
            onScroll: event => {
              const listboxNode = event.currentTarget;
              if (
                listboxNode.scrollTop + listboxNode.clientHeight >=
                listboxNode.scrollHeight
              ) {
                loadMoreResults();
              }
            },
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={getName(option)}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              autoFocus
              value={searchTerm}
              onChange={handleInputChange}
              placeholder={t('SearchBar.search_contacts')}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        style={{ marginRight: '16px' }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          closeIcon={null}
        />
        <IconButton className={classes.cancel} onClick={handleCancel}>
          <CloseIcon color="primary" />
        </IconButton>
      </div>
      <Divider />
    </div>
  );
};

export default NewConversation;
