import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getName } from '../../../../utils/userUtils';
import { toggleChatView } from '../../../../redux/actions/App';
import { getCurrentUserId } from '../../../../redux/selectors/CurrentUser';
import { getAllContacts } from '../../../../redux/selectors/Users';
import { SocketContext } from '../../../../api/socket';
import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '../../../shared/Avatar/Avatar';

const ContactsList = ({
  withSelect,
  selectedContacts,
  selectContact,
  skipContacts,
  close,
  setIsSubmitting,
}) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentUserId);
  const socket = useContext(SocketContext);
  const allContacts = useSelector(getAllContacts);

  let sortedContacts = allContacts;

  if (allContacts.every(user => user))
    sortedContacts = sortedContacts.sort((a, b) => {
      const x = getName(a);
      const y = getName(b);
      return x.localeCompare(y);
    });

  const groupedContacts = sortedContacts.reduce((prev, next) => {
    if (next && (!skipContacts || !skipContacts.includes(next.user_id))) {
      const group = getName(next)[0];
      prev[group] = prev[group] ? [...prev[group], next] : [next];
    }
    return prev;
  }, {});

  const createConversation = user_id => {
    setIsSubmitting(true);
    socket.emit(
      'new_chat',
      {
        user_ids: [currentUserId, user_id],
        private: true,
        creator_id: currentUserId,
      },
      () => {
        setIsSubmitting(false);
        if (close) close();
      }
    );
  };

  const toggleView = () => {
    dispatch(toggleChatView(true));
  };

  const primaryTypographyProps = user =>
    user.role === 'Teacher' ? { color: 'primary' } : { color: 'textPrimary' };

  const classes = makeStyles(theme => ({
    listItem: {
      paddingLeft: theme.spacing(5),
      '& .MuiListItemIcon-root': { minWidth: theme.spacing(5) },
    },
  }))();

  return (
    <List subheader={<li />}>
      {Object.entries(groupedContacts).length
        ? Object.entries(groupedContacts).map(([key, value]) => (
            <li key={key}>
              <ul>
                <ListSubheader>
                  <b>{key}</b>
                </ListSubheader>
                {value.map(user => (
                  <ListItem
                    className={classes.listItem}
                    key={key + user.user_id}
                    onClick={() => {
                      if (withSelect) selectContact(user);
                      else {
                        createConversation(user.user_id);
                        toggleView();
                      }
                    }}
                    button
                  >
                    {withSelect && (
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          tabIndex={-1}
                          checked={selectedContacts.indexOf(user.user_id) >= 0}
                        />
                      </ListItemIcon>
                    )}
                    <ListItemAvatar>
                      <Avatar user={user} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={getName(user)}
                      primaryTypographyProps={primaryTypographyProps(user)}
                    />
                  </ListItem>
                ))}
              </ul>
            </li>
          ))
        : null}
    </List>
  );
};

export default ContactsList;
