import _ from 'lodash';

export default class ErrorLogger {
  constructor() {
    if (ErrorLogger.instance) return ErrorLogger.instance;
    ErrorLogger.instance = this;
    this.errors = [];
    this.state = {};
    this.logs = [];
  }

  addError(error) {
    this.errors.push(error);
  }

  middleware = store => next => action => {
    this.logs.push(action);
    const result = next(action);
    const state = store.getState();
    this.state = state;
    return result;
  };

  getLogs() {
    if (!this.state.computedStates)
      return {
        errors: this.errors,
        state: {
          fullState: this.state,
          logs: this.logs,
        },
      };
    const { computedStates } = this.state.computedStates.reduce(
      (prev, next) => {
        if (prev.computedStates.length) {
          const difference = this.difference(next, prev.fullState);
          prev.computedStates.push(difference);
        } else {
          prev.computedStates.push(next);
        }

        prev.fullState = next;
        return prev;
      },
      { computedStates: [], fullState: {} }
    );
    return {
      errors: this.errors,
      state: { fullState: { ...this.state, computedStates }, logs: this.logs },
    };
  }

  /**
   * Deep diff between two object, using lodash
   * @param  {Object} object Object compared
   * @param  {Object} base   Object to compare with
   * @return {Object}        Return a new object who represent the diff
   */
  difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(object, base);
  }
}
