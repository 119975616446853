import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getName,
  usernameColor,
  usernameText,
} from '../../../../../utils/userUtils';
import { getDataFromApi } from '../../../../../api/dataFromApi';
import {
  addNewContact,
  removeExistingContact,
} from '../../../../../redux/actions/Users';
import { getUser } from '../../../../../redux/selectors/Users';
import {
  blockContact,
  unblockContact,
} from '../../../../../redux/actions/CurrentUser';
import {
  changeChat,
  resetCreatingConversation,
  updateCreatingConversation,
} from '../../../../../redux/actions/App';
import { getBlockList } from '../../../../../redux/selectors/CurrentUser';
import { getCreatingConversation } from '../../../../../redux/selectors/App';
import AchievementIcon from '../../../../../assets/icons/OverallAchievementIcon';
import { SnackbarContext } from '../../SnackbarContext/SnackbarContext';
import BlockContact from './BlockContact/BlockContact';
import ReportDialog from '../ReportDialog/ReportDialog';
import GeneralInfo from './Panels/GeneralInfo/GeneralInfo';
import Media from '../GroupInfo/Panels/Media/Media';
import Files from '../GroupInfo/Panels/Files/Files';
import Links from '../GroupInfo/Panels/Links/Links';
import Avatar from '../../../../shared/Avatar/Avatar';
import Dialog from '../../../../shared/Dialog/Dialog';
import DialogTitle from '../../../../shared/Dialog/DialogTitle/DialogTitle';
import Tabs from '../../../../shared/Tabs/Tabs';
import TabPanel from '../../../../shared/Tabs/TabPanel/TabPanel';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ReportIcon from '@material-ui/icons/Report';
import BlockIcon from '@material-ui/icons/Block';
import ChatIcon from '@material-ui/icons/Chat';

const ContactInfo = ({ user_id, handleClose, closePopup }) => {
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const [value, setValue] = useState(0);
  const [achievedBadges, setAchievedBadges] = useState([]);
  const user = useSelector(getUser(user_id));
  const isInContacts = useSelector(state =>
    state.Users.contacts.find(user => user.user_id === user_id)
  );
  const blockList = useSelector(getBlockList);
  const conversation = useSelector(state =>
    Object.values(state.Conversations.conversations).find(
      c =>
        c.private &&
        (c.users[0].user_id === user_id || c.users[1].user_id === user_id)
    )
  );
  const creatingConversation = useSelector(getCreatingConversation);
  const { status } = creatingConversation;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const chat_id = conversation?.chat_id;
  const blocked = blockList.some(u => u.user_id === user_id);

  useEffect(() => {
    (async () => {
      const achieved = [];
      const badges = await getDataFromApi(
        `achievements/list/other/${user_id}/`,
        'v1'
      );
      if (badges) {
        Object.entries(badges).map(badge => {
          if (badge[1].is_achieved) {
            achieved.push(badge);
          }
          return achieved;
        });
        setAchievedBadges(achieved);
      }
    })();
  }, [user_id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addContact = () => {
    dispatch(addNewContact(user_id));
  };

  const removeContact = () => {
    dispatch(removeExistingContact(user_id));
  };

  const handleBlock = async () => {
    await dispatch(blockContact(user_id, chat_id));
    handleClose();
    setSnackbarOptions(
      `${t('ContactInfo.BlockContact.you_have_blocked')} ${getName(user)}`
    );
  };

  const handleUnblock = async () => {
    await dispatch(unblockContact(user_id));
    setSnackbarOptions(
      `${t('ContactInfo.BlockContact.you_have_unblocked')} ${getName(user)}`
    );
  };

  const sendMessage = () => {
    if (chat_id) openConversation();
    else startConversation();
  };

  const openConversation = () => {
    if (status) dispatch(resetCreatingConversation());
    dispatch(changeChat(chat_id));
    handleClose();
    if (closePopup) closePopup();
  };

  const startConversation = () => {
    dispatch(
      updateCreatingConversation({
        status: true,
        users: [user],
        initialUser: user,
      })
    );
    handleClose();
    if (closePopup) closePopup();
  };

  const classes = makeStyles(theme => ({
    content: { padding: 0 },
    generalInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiTypography-h5': { marginBottom: theme.spacing(0.75) },
      '& .MuiTypography-body2': { marginBottom: theme.spacing(1.25) },
      paddingBottom: theme.spacing(2),
    },
    avatar: {
      width: 130,
      height: 130,
      marginBottom: theme.spacing(2),
    },
    achievements: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
      '& span': { marginLeft: theme.spacing(1) },
    },
    sendMessageBtn: {
      marginTop: theme.spacing(2),
      padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
    },
    actions: {
      marginTop: theme.spacing(1.5),
      padding: theme.spacing(2),
    },
    danger: { color: theme.palette.error.main },
    primary: { color: theme.palette.primary.main },
    action: {
      marginTop: theme.spacing(1),
      minHeight: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '& > button': {
        border: 'none',
        outline: 'none',
        background: 'transparent',
        marginLeft: theme.spacing(0.5),
        color: 'inherit',
      },
    },
  }))();

  return (
    <>
      <DialogTitle onClose={handleClose}>{t('ContactInfo.title')}</DialogTitle>
      <DialogContent className={classes.content}>
        {user && (
          <>
            <div className={classes.generalInfo}>
              <Avatar className={classes.avatar} user={user} />
              <Typography variant="h5" color={usernameColor(user)}>
                {usernameText(user)}
              </Typography>
              <Typography variant="body2">
                {t('ContactInfo.joined')}: 25 April 2018
              </Typography>
              <div className={classes.achievements}>
                <AchievementIcon />
                <span>{achievedBadges.length}</span>
              </div>
              <Button
                onClick={sendMessage}
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                startIcon={<ChatIcon />}
                className={classes.sendMessageBtn}
              >
                {t('ContactInfo.send_message')}
              </Button>
            </div>

            <Divider />

            <Tabs
              value={value}
              handleChange={handleChange}
              conversationExists={Boolean(conversation)}
            />
            <TabPanel className={classes.tabPanel} value={value} index={0}>
              <GeneralInfo bio_info={user.bio_info} />
            </TabPanel>
            {chat_id && (
              <>
                <TabPanel className={classes.tabPanel} value={value} index={1}>
                  <Media chatId={chat_id} />
                </TabPanel>
                <TabPanel className={classes.tabPanel} value={value} index={2}>
                  <Files chatId={chat_id} />
                </TabPanel>
                <TabPanel className={classes.tabPanel} value={value} index={3}>
                  <Links chatId={chat_id} />
                </TabPanel>
              </>
            )}

            <div className={classes.actions}>
              {isInContacts ? (
                <label className={`${classes.action} ${classes.danger}`}>
                  <RemoveCircleIcon />
                  <button onClick={removeContact}>
                    {t('ContactInfo.remove_contact')}
                  </button>
                </label>
              ) : (
                <label className={`${classes.action} ${classes.primary}`}>
                  <AddCircleIcon />
                  <button onClick={addContact}>
                    {t('ContactInfo.add_contact')}
                  </button>
                </label>
              )}
              <Dialog
                trigger={
                  <label className={classes.action} data-testid="report">
                    <ReportIcon />
                    <button>{t('ContactInfo.report_contact')}</button>
                  </label>
                }
              >
                {close => <ReportDialog handleClose={close} />}
              </Dialog>
              {user.role !== 'Teacher' &&
                (blocked ? (
                  <label className={classes.action}>
                    <BlockIcon />
                    <button onClick={handleUnblock}>
                      {t('ContactInfo.unblock_contact')}
                    </button>
                  </label>
                ) : (
                  <Dialog
                    trigger={
                      <label
                        className={`${classes.action} ${classes.danger}`}
                        data-testid="block"
                      >
                        <BlockIcon />
                        <button>{t('ContactInfo.block_contact')}</button>
                      </label>
                    }
                  >
                    {close => (
                      <BlockContact
                        handleClose={close}
                        handleBlock={handleBlock}
                      />
                    )}
                  </Dialog>
                ))}
            </div>
          </>
        )}
      </DialogContent>
    </>
  );
};

export default ContactInfo;
