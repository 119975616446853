import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';

const Notifications = () => {
  const { t } = useTranslation();

  const classes = makeStyles(theme => ({
    notifications: { padding: 0 },
    options: {
      padding: theme.spacing(2),
      '& .MuiFormControl-root': { width: '100%' },
      '& .MuiFormControlLabel-root': { margin: 0 },
      '& .MuiFormControlLabel-label': { marginRight: 'auto' },
    },
    heading: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    switchOption: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }))();

  return (
    <DialogContent className={classes.notifications}>
      <div className={classes.options}>
        <Typography variant="subtitle1" className={classes.heading}>
          {t('Settings.Notifications.receive_about')}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup value="private" onChange={() => {}}>
            <FormControlLabel
              value="private"
              control={<Radio disabled />}
              label={t('Settings.Notifications.private_messages')}
              labelPlacement="start"
            />
            <FormControlLabel
              value="group"
              control={<Radio disabled />}
              label={t('Settings.Notifications.group_messages')}
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <Divider />
      <div className={classes.options}>
        <div className={classes.switchOption}>
          <Typography variant="subtitle1">
            {t('Settings.Notifications.play_sounds')}
          </Typography>
          <Switch checked={true} disabled onChange={() => {}} />
        </div>
        <div className={classes.switchOption}>
          <Typography variant="subtitle1">
            {t('Settings.Notifications.show_message_preview')}
          </Typography>
          <Switch checked={false} disabled onChange={() => {}} />
        </div>
      </div>
    </DialogContent>
  );
};

export default Notifications;
