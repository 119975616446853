import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/selectors/Users';
import { getCurrentUser } from '../../../redux/selectors/CurrentUser';
// import { updateCurrentUser } from '../../../redux/actions/CurrentUser';
// import { updateUser } from '../../../redux/actions/Users';
import { withStyles } from '@material-ui/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '../Avatar/Avatar';

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const ProfileImage = ({ user_id, userOnline, ...other }) => {
  const currentUser = useSelector(getCurrentUser);
  const otherUser = useSelector(getUser(user_id));
  const user = user_id === currentUser.user_id ? currentUser : otherUser;

  // const checkImage = () => {
  //   user.user_id === currentUser.user_id
  //     ? dispatch(updateCurrentUser({ image_url: null }))
  //     : dispatch(updateUser(null, user.user_id));
  // };

  return userOnline ? (
    <StyledBadge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot"
    >
      <Avatar aria-label="profile-picture" user={user} {...other} />
    </StyledBadge>
  ) : (
    <Avatar aria-label="profile-picture" user={user} {...other} />
  );
};

export default ProfileImage;
