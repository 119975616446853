import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleEdit } from '../../../../../../redux/actions/App';
import { replyToMessage } from '../../../../../../redux/actions/Conversations';
import { getChatId } from '../../../../../../redux/selectors/App';
import { getCurrentUserId } from '../../../../../../redux/selectors/CurrentUser';
import { downloadFile } from '../../../../../../utils/fileUtils';
import { SocketContext } from '../../../../../../api/socket';
import DeleteMessage from './DeleteMessage/DeleteMessage';
import ForwardMessage from './ForwardMessage/ForwardMessage';
import MessageDetails from './MessageDetails/MessageDetails';
import Dialog from '../../../../../shared/Dialog/Dialog';
import StyledMenu from '../../../../../shared/StyledMenu/StyledMenu';
import StyledMenuItem from '../../../../../shared/StyledMenu/StyledMenuItem/StyledMenuItem';
import { createStyles, makeStyles } from '@material-ui/styles';
import ListItemText from '@material-ui/core/ListItemText';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useMessageActionsStyles = makeStyles(theme =>
  createStyles({
    icon: {
      cursor: 'pointer',
      color: theme.palette.type === 'light' ? '#73878f' : '#9f9f9f',
      marginLeft: 'auto',
    },
  })
);

const MessageActions = ({ message }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const chatId = useSelector(getChatId);
  const currentUserId = useSelector(getCurrentUserId);
  const isCurrentUser = message => currentUserId === message.user_id;
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useMessageActionsStyles();

  const isExpired = message => moment().isAfter(message.file_data?.expires_at);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    handleHideMenu();
    setIsOpen(false);
  };

  const handleHideMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = (message, close) => {
    dispatch(toggleEdit(message.message_id, true));
    dispatch(replyToMessage(chatId, false));
    close();
  };

  const handleReply = (message, close) => {
    dispatch(replyToMessage(chatId, message.message_id));
    dispatch(toggleEdit(message.message_id, false));
    close();
  };

  const handleCopy = message => {
    if (!navigator || !navigator.clipboard || !navigator.clipboard.writeText)
      return alert(t('MessageActions.copy_doesnt_work_in_your_browser'));
    navigator.clipboard.writeText(message.body).then(
      () => {
        console.log(t('MessageActions.copied'), message.body);
      },
      () => {
        console.warn(t('MessageActions.failed_to_copy'));
      }
    );
    handleClose();
  };

  const handleDownload = message => {
    downloadFile(false, message, socket);
    handleClose();
  };

  return (
    !message.deleted && (
      <>
        <MoreHorizIcon
          className={classes.icon}
          fontSize="small"
          color="action"
          onClick={handleClick}
        />
        <StyledMenu
          data-testid="actions"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          keepMounted
          autoFocus={false}
          onClose={handleHideMenu}
        >
          {isOpen && (
            <div>
              <Dialog
                onBack={handleClose}
                trigger={
                  <StyledMenuItem onClick={handleHideMenu}>
                    <ListItemText primary={t('MessageActions.details')} />
                  </StyledMenuItem>
                }
              >
                {close => (
                  <MessageDetails handleClose={close} message={message} />
                )}
              </Dialog>
              <StyledMenuItem
                onClick={() => handleReply(message, handleHideMenu)}
              >
                <ListItemText primary={t('MessageActions.reply')} />
              </StyledMenuItem>
              <Dialog
                onBack={handleClose}
                trigger={
                  <StyledMenuItem onClick={handleHideMenu}>
                    <ListItemText primary={t('MessageActions.forward')} />
                  </StyledMenuItem>
                }
              >
                {close => (
                  <ForwardMessage handleClose={close} message={message} />
                )}
              </Dialog>
              {message.message_type === 'text' && (
                <StyledMenuItem onClick={() => handleCopy(message)}>
                  <ListItemText primary={t('MessageActions.copy')} />
                </StyledMenuItem>
              )}
              {message.message_type === 'text' && isCurrentUser(message) && (
                <StyledMenuItem
                  onClick={() => handleEdit(message, handleClose)}
                >
                  <ListItemText primary={t('MessageActions.edit')} />
                </StyledMenuItem>
              )}
              {isCurrentUser(message) && (
                <Dialog
                  onBack={handleClose}
                  trigger={
                    <StyledMenuItem onClick={handleHideMenu}>
                      <ListItemText primary={t('MessageActions.delete')} />
                    </StyledMenuItem>
                  }
                >
                  {close => (
                    <DeleteMessage
                      handleClose={close}
                      message={message}
                      chatId={chatId}
                    />
                  )}
                </Dialog>
              )}
              {!['text', 'youtube', 'link', 'smartboard'].includes(
                message.message_type
              ) &&
                !isExpired(message) && (
                  <StyledMenuItem
                    onClick={() => handleDownload(message)}
                    data-testid="download"
                  >
                    <ListItemText primary={t('MessageActions.download')} />
                  </StyledMenuItem>
                )}
            </div>
          )}
        </StyledMenu>
      </>
    )
  );
};

export default MessageActions;
