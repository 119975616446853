import {
  getDataFromApi,
  postDataToApi,
  deleteDataFromApi,
} from '../../api/dataFromApi';
import { persistor } from '../store';
import { getInitSettings, changeChat } from './App';
import {
  removeConversation,
  removeConversations,
  insertConversation,
} from './Conversations';
import {
  getUser,
  removeExistingContact,
  removeExistingContacts,
} from './Users';

export const setCurrentUser = user => {
  return {
    type: 'SET_CURRENT_USER',
    payload: {
      ...user,
      loggedIn: true,
    },
  };
};

export const updateCurrentUser = data => {
  return {
    type: 'UPDATE_CURRENT_USER',
    payload: {
      ...data,
    },
  };
};

export const logoutUser = () => {
  return async dispatch => {
    await persistor.flush();
    await persistor.purge();
    dispatch({
      type: 'LOGOUT_USER',
      payload: {
        loggedIn: false,
      },
    });
  };
};

export const setLastLogin = () => {
  return {
    type: 'SET_LAST_LOGIN',
    payload: new Date().toISOString(),
  };
};

export const block = users => {
  return {
    type: 'ADD_TO_BLOCK_LIST',
    payload: Array.isArray(users) ? users : [users],
  };
};

export const unblock = userId => {
  return {
    type: 'REMOVE_FROM_BLOCK_LIST',
    payload: userId,
  };
};

export const acceptTOS = () => {
  return {
    type: 'SET_TOS_ACCEPTED',
  };
};

export const setTOSAccepted = () => {
  return async dispatch => {
    await postDataToApi('auth/user/self/tnc_accept/');
    dispatch(acceptTOS());
  };
};

export const getCurrentUser = () => {
  return async dispatch => {
    const currentUser = await getDataFromApi('auth/user/self/');
    if (!currentUser.tnc_accepted) return;
    await dispatch(getInitSettings());
    const blockList = await getDataFromApi('auth/user/block');
    dispatch(
      setCurrentUser({
        ...currentUser,
        fullName: `${currentUser.first_name} ${currentUser.last_name}`,
        blockList,
      })
    );
    await postDataToApi(
      'achievements/increment/',
      {
        user_id: currentUser.user_id,
        achievement_event_id: 'communicator_logged_in',
        count: 1,
      },
      'v1'
    );
  };
};

export const blockContact = (user_id, chat_id) => {
  return async (dispatch, getState) => {
    await postDataToApi('auth/user/block/', {
      student_ids: [user_id],
    });
    if (getState().Users.contacts.find(c => c.user_id === user_id))
      await dispatch(removeExistingContact(user_id));
    const user = getState().Users.users[user_id];
    dispatch(block(user));
    const chatId = getState().App.chatId;
    if (chatId && chatId === chat_id) await dispatch(changeChat(false));
    if (chat_id) await dispatch(removeConversation(chat_id));
  };
};

export const unblockContact = user_id => {
  return async (dispatch, getState) => {
    const response = await deleteDataFromApi(`auth/user/unblock/${user_id}/`);
    dispatch(unblock(user_id));
    dispatch(getUser(user_id));
    if (response && response.chat_id) {
      const chatInfo = await getDataFromApi(`chat/${response.chat_id}/`);
      dispatch(insertConversation(chatInfo));
    }
  };
};

export const blockContacts = user_ids => {
  return async (dispatch, getState) => {
    await postDataToApi('auth/user/block/', {
      student_ids: user_ids,
    });
    await dispatch(removeExistingContacts(user_ids));
    const users = user_ids.map(id => getState().Users.users[id]);
    dispatch(block(users));
    const chats = Object.values(getState().Conversations.conversations)
      .filter(
        c =>
          c.private &&
          (user_ids.includes(c.users[0].user_id) ||
            user_ids.includes(c.users[1].user_id))
      )
      .map(c => c.chat_id);
    const chatId = getState().App.chatId;
    if (chatId && chats.includes(chatId)) {
      dispatch(changeChat(false));
    }
    dispatch(removeConversations(chats));
  };
};
