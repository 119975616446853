import React from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from '../../api/azureAuth';
import { changeChat } from '../../redux/actions/App';
import AppSwitcher from './AppSwitcher/AppSwitcher';
import ProfileActions from './ProfileActions/ProfileActions';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from '@material-ui/icons/Chat';

const Header = () => {
  const dispatch = useDispatch();

  const [jwt, setJwt] = React.useState('');

  React.useEffect(() => {
    (async () => {
      const jwt = await Auth.getToken();
      setJwt(jwt);
    })();
  }, []);

  const classes = makeStyles(theme => ({
    header: { flexGrow: 1 },
    logo: {
      margin: `0 ${theme.spacing(1)}px`,
      padding: theme.spacing(1),
      backgroundColor: 'white',
      '&:hover': { backgroundColor: 'white' },
    },
    title: { flexGrow: 1 },
    toolbar: { padding: `0 ${theme.spacing(2)}px` },
  }))();

  return (
    <div className={classes.header}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            size="small"
            className={classes.logo}
            aria-label="logo"
            onClick={() => dispatch(changeChat(false))}
          >
            <ChatIcon color="primary" />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            Communicator
          </Typography>
          <button
            onClick={() => console.log(jwt)}
            style={{
              marginLeft: 'auto',
              marginRight: '1em',
              display: window.location.href.includes('dev')
                ? 'initial'
                : 'none',
            }}
          >
            Token
          </button>
          <AppSwitcher />
          <ProfileActions />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
