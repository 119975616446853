import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SocketContext } from '../../../../../../api/socket';
import Avatar from '../../../../../shared/Avatar/Avatar';
import DialogTitle from '../../../../../shared/Dialog/DialogTitle/DialogTitle';
import ContactsList from '../../../../Sidebar/ContactsList/ContactsList';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

const AddUsers = ({ chatUsers, chat, handleClose }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const socket = useContext(SocketContext);
  const { t } = useTranslation();

  const { title, chat_id, image_signed_url } = chat;

  const toggleContact = user => {
    setSelectedUsers(prevValue => {
      if (prevValue.indexOf(user) >= 0) {
        const newSet = new Set(prevValue);
        newSet.delete(user);
        return [...newSet];
      }
      return [...prevValue, user];
    });
  };

  const addContacts = e => {
    e.preventDefault();
    if (selectedUsers.length) {
      socket.emit('add_users_to_chat', {
        chat_id,
        user_ids: selectedUsers.map(user => user.user_id),
      });
    }
    handleClose();
  };

  const classes = makeStyles(theme => ({
    content: {
      paddingLeft: 0,
      paddingRight: 0,
      '&:first-child': { paddingTop: 0 },
    },
    groupInfo: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    avatar: {
      width: 80,
      height: 80,
      fontSize: '4em',
    },
    title: {
      marginLeft: theme.spacing(2),
      '& .MuiTypography-subtitle1': { marginTop: theme.spacing(0.5) },
      '& .MuiTypography-caption': { color: theme.palette.text.secondary },
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
      '& span': { marginRight: theme.spacing(1) },
    },
    members: { padding: theme.spacing(2) },
    groupMembers: {
      marginTop: theme.spacing(2),
      display: 'flex',
      '& div:not(:last-child)': { marginRight: theme.spacing(1) },
    },
    contacts: {
      maxHeight: 'calc(40 * var(--vh))',
      overflowY: 'auto',
      [theme.breakpoints.down('xs')]: { maxHeight: 'calc(20 * var(--vh))' },
    },
    actions: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2),
    },
  }))();

  return (
    <>
      <DialogTitle onClose={handleClose}>{t('AddUsers.title')}</DialogTitle>
      <Divider />
      <form onSubmit={addContacts}>
        <DialogContent className={classes.content}>
          <div className={classes.groupInfo}>
            <Avatar
              group
              photo={image_signed_url}
              large
              className={classes.avatar}
            />
            <div className={classes.title}>
              <Typography variant="subtitle1">{title}</Typography>
              <Typography variant="caption">10 August 2020</Typography>
            </div>
          </div>
          <Divider />
          <div className={classes.search}>
            <span>{t('NewConversation.to')}: </span>
            <Input
              fullWidth
              placeholder={t('NewConversation.type_contacts_name')}
              disableUnderline
            />
          </div>
          <Divider />
          <div className={classes.members}>
            <Typography variant="subtitle2">
              {t('AddUsers.group_members')}
            </Typography>
            <div className={classes.groupMembers}>
              {chatUsers.map(user => (
                <Avatar key={user.user_id} user={user} />
              ))}
            </div>
          </div>

          <div className={classes.contacts}>
            <ContactsList
              withSelect
              skipContacts={chatUsers.map(u => u.user_id)}
              selectedContacts={selectedUsers.map(u => u.user_id)}
              selectContact={toggleContact}
              close={handleClose}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            disabled={!selectedUsers.length}
          >
            {t('AddContact.add')}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            onClick={handleClose}
          >
            {t('AddContact.cancel')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AddUsers;
