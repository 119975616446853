import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataFromApi } from '../../../../../../../api/dataFromApi';
import { attachFileData } from '../../../../../../../redux/actions/Conversations';
import FileMessage from '../../../../MessageBoard/Message/MediaMessage/FileMessage/FileMessage';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

const Files = ({ chatId }) => {
  const [files, setFiles] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [dataLength, setDataLength] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const data = await getDataFromApi(
          `chat/${chatId}/messages/file/`,
          'v1'
        );
        setDataLength(data.length);
        const files = await Promise.all(
          data.map(async file => await attachFileData(file))
        );
        setFiles(files);
      } catch {
        setDataLength(0);
        setFiles([]);
      }
      setDataFetched(true);
    })();
  }, [chatId]);

  const renderSkeleton = () => (
    <Box px={1}>
      {[...new Array(dataLength || 5)].map((item, index) => (
        <div className={classes.skeletonItem} key={index}>
          <Skeleton width={30} height={50} />
          <div className={classes.text}>
            <Skeleton width={150} height={20} />
            <Skeleton width={150} height={10} />
          </div>
        </div>
      ))}
    </Box>
  );

  const classes = makeStyles(theme => ({
    files: { marginTop: theme.spacing(2) },
    skeletonItem: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
    },
    text: { marginLeft: theme.spacing(2) },
    noFiles: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(2),
    },
  }))();

  return (
    <div className={classes.files}>
      {dataFetched ? (
        files.length ? (
          files
            .filter(file => !file.deleted)
            .map(file => <FileMessage message={file} key={file.message_id} />)
        ) : (
          <Typography variant="subtitle1" className={classes.noFiles}>
            {t('ContactInfo.no_shared_files')}
          </Typography>
        )
      ) : (
        renderSkeleton()
      )}
    </div>
  );
};

export default Files;
