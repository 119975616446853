import Users from './Users';
import Conversations from './Conversations';
import CurrentUser from './CurrentUser';
import App from './App';
import Search from './Search';
import { combineReducers } from 'redux';
import { Auth } from '../../api/azureAuth';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['App', 'Search'],
};

const appConfig = {
  key: 'App',
  storage,
  blacklist: [
    'searchResultsView',
    'creatingConversation',
    'chatId',
    'conversationsFetched',
    'fetchingConversations',
    'nextPage',
    'toggleView',
    'chatWidth',
    'settingsPage',
    'editMessage',
    'isVisible',
  ], // TODO: SEPARATE TO NEW MODULE WHAT WE DON'T SAVE IN LOCAL STORAGE
};
const allReducers = combineReducers({
  App: persistReducer(appConfig, App),
  Users,
  Conversations,
  CurrentUser,
  Search,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

export default (state, payload) => {
  if (payload.type === 'LOGOUT_USER') {
    Auth.logout();
  }
  return persistedReducer(state, payload);
};
