const initialState = {
  loading: false,
  results: {
    contacts: {
      results: [],
      displayedResults: [],
      dataFetched: false,
      loadingMore: false,
      expanded: false,
    },
    messages: {
      results: [],
      displayedResults: [],
      dataFetched: false,
      loadingMore: false,
      expanded: false,
    },
    media: {
      results: [],
      displayedResults: [],
      dataFetched: false,
      loadingMore: false,
      showSkeleton: false,
      expanded: false,
    },
    chats: {
      results: [],
      displayedResults: [],
      dataFetched: false,
      loadingMore: false,
      expanded: false,
    },
  },
  searchTerm: '',
  initSearch: '',
  initValueApplied: false,
  focusedResultIndex: -1,
  activeSection: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case 'SET_SEARCH_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_SEARCH_RESULTS':
      const activeSection = payload[0].count
        ? 'contacts'
        : payload[1].count
        ? 'messages'
        : payload[2].count
        ? 'media'
        : payload[3].count
        ? 'chats'
        : null;
      return {
        ...state,
        results: {
          contacts: {
            ...state.results.contacts,
            ...payload[0],
            displayedResults:
              payload[0].results.length > 3
                ? payload[0].results.slice(0, 3)
                : payload[0].results,
            dataFetched: !Boolean(payload[0].results.length),
            expanded: Boolean(payload[0].count),
          },
          messages: {
            ...state.results.messages,
            ...payload[1],
            displayedResults:
              payload[1].results.length > 3
                ? payload[1].results.slice(0, 3)
                : payload[1].results,
            dataFetched: !Boolean(payload[1].results.length),
            expanded: Boolean(payload[1].count),
          },
          media: {
            ...state.results.media,
            ...payload[2],
            displayedResults:
              payload[2].results.length > 3
                ? payload[2].results.slice(0, 3)
                : payload[2].results,
            dataFetched: !Boolean(payload[2].results.length),
            expanded: Boolean(payload[2].count),
          },
          chats: {
            ...state.results.chats,
            ...payload[3],
            displayedResults:
              payload[3].results.length > 3
                ? payload[3].results.slice(0, 3)
                : payload[3].results,
            dataFetched: !Boolean(payload[3].results.length),
            expanded: Boolean(payload[3].count),
          },
        },
        activeSection,
        focusedResultIndex: -1,
      };
    case 'SET_MESSAGES_CHAT_IMAGES':
      return {
        ...state,
        results: {
          ...state.results,
          messages: {
            ...state.results.messages,
            dataFetched: true,
            chats: {
              ...state.results.messages.chats,
              ...payload,
            },
          },
        },
      };
    case 'UPDATE_RESULTS':
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            displayedResults: payload.results,
            dataFetched: true,
            loadingMore: false,
          },
        },
      };
    case 'APPEND_RESULTS':
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            results: [
              ...state.results[payload.key].results,
              ...payload.results,
            ],
            displayedResults: [
              ...state.results[payload.key].displayedResults,
              ...payload.results,
            ],
          },
        },
      };
    case 'ADD_MORE_RESULTS':
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            results: [
              ...state.results[payload.key].results,
              ...payload.data.results,
            ],
            displayedResults: [
              ...state.results[payload.key].displayedResults,
              ...payload.data.results,
            ],
            links: payload.data.links,
            dataFetched: !Boolean(payload.data.results.length),
            loadingMore: false,
          },
        },
      };
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: payload,
      };
    case 'SET_INIT_SEARCH':
      return {
        ...state,
        initSearch: payload,
      };
    case 'SET_INIT_VALUE_APPLIED':
      return {
        ...state,
        initValueApplied: payload,
      };
    case 'INCREMENT_INDEX': {
      const { activeSection, focusedResultIndex, results } = state;
      let newIndex;
      let newActiveSection = activeSection;
      if (!activeSection) {
        return state;
      } else if (
        focusedResultIndex ===
        results[activeSection].displayedResults.length - 1
      ) {
        if (activeSection === 'contacts') {
          newActiveSection =
            (state.results.messages.expanded &&
              state.results.messages.results.length &&
              'messages') ||
            (state.results.media.expanded &&
              state.results.media.results.length &&
              'media') ||
            (state.results.chats.expanded &&
              state.results.chats.results.length &&
              'chats') ||
            activeSection;
        } else if (activeSection === 'messages') {
          newActiveSection =
            (state.results.media.expanded &&
              state.results.media.results.length &&
              'media') ||
            (state.results.chats.expanded &&
              state.results.chats.results.length &&
              'chats') ||
            (state.results.contacts.expanded &&
              state.results.contacts.results.length &&
              'contacts') ||
            activeSection;
        } else if (activeSection === 'media') {
          newActiveSection =
            (state.results.chats.expanded &&
              state.results.chats.results.length &&
              'chats') ||
            (state.results.contacts.expanded &&
              state.results.contacts.results.length &&
              'contacts') ||
            (state.results.messages.expanded &&
              state.results.messages.results.length &&
              'messages') ||
            activeSection;
        } else if (activeSection === 'chats') {
          newActiveSection =
            (state.results.contacts.expanded &&
              state.results.contacts.results.length &&
              'contacts') ||
            (state.results.messages.expanded &&
              state.results.messages.results.length &&
              'messages') ||
            (state.results.media.expanded &&
              state.results.media.results.length &&
              'media') ||
            activeSection;
        }
        newIndex = 0;
      } else {
        newIndex = focusedResultIndex + 1;
      }
      return {
        ...state,
        focusedResultIndex: newIndex,
        activeSection: newActiveSection,
      };
    }
    case 'DECREMENT_INDEX': {
      const { activeSection, focusedResultIndex, results } = state;
      let newIndex;
      let newActiveSection = activeSection;
      if (!activeSection) {
        return state;
      } else if (focusedResultIndex === 0) {
        if (activeSection === 'contacts') {
          newActiveSection =
            (state.results.chats.expanded &&
              state.results.chats.results.length &&
              'chats') ||
            (state.results.media.expanded &&
              state.results.media.results.length &&
              'media') ||
            (state.results.messages.expanded &&
              state.results.messages.results.length &&
              'messages') ||
            activeSection;
        } else if (activeSection === 'messages') {
          newActiveSection =
            (state.results.contacts.expanded &&
              state.results.contacts.results.length &&
              'contacts') ||
            (state.results.chats.expanded &&
              state.results.chats.results.length &&
              'chats') ||
            (state.results.media.expanded &&
              state.results.media.results.length &&
              'media') ||
            activeSection;
        } else if (activeSection === 'media') {
          newActiveSection =
            (state.results.messages.expanded &&
              state.results.messages.results.length &&
              'messages') ||
            (state.results.contacts.expanded &&
              state.results.contacts.results.length &&
              'contacts') ||
            (state.results.chats.expanded &&
              state.results.chats.results.length &&
              'chats') ||
            activeSection;
        } else if (activeSection === 'chats') {
          newActiveSection =
            (state.results.media.expanded &&
              state.results.media.results.length &&
              'media') ||
            (state.results.messages.expanded &&
              state.results.messages.results.length &&
              'messages') ||
            (state.results.contacts.expanded &&
              state.results.contacts.results.length &&
              'contacts') ||
            activeSection;
        }
        newIndex = results[newActiveSection].displayedResults.length - 1;
      } else {
        newIndex = focusedResultIndex - 1;
      }
      return {
        ...state,
        focusedResultIndex: newIndex,
        activeSection: newActiveSection,
      };
    }
    case 'SET_INDEX':
      return {
        ...state,
        focusedResultIndex: payload,
      };
    case 'RESET_SEARCH':
      return { ...initialState };
    case 'SET_LOADING_MORE':
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            loadingMore: payload.val,
          },
        },
      };
    case 'SHOW_MEDIA_SKELETON':
      return {
        ...state,
        results: {
          ...state.results,
          media: {
            ...state.results.media,
            showSkeleton: payload,
          },
        },
      };
    case 'TOGGLE_EXPANDED':
      let newActiveSection = state.activeSection;
      if (!payload.expanded) {
        if (payload.key === 'contacts')
          newActiveSection = state.results.messages.expanded
            ? 'messages'
            : state.results.media.expanded
            ? 'media'
            : state.results.chats.expanded
            ? 'chats'
            : null;
        if (payload.key === 'messages')
          newActiveSection = state.results.media.expanded
            ? 'media'
            : state.results.chats.expanded
            ? 'chats'
            : state.results.contacts.expanded
            ? 'contacts'
            : null;
        if (payload.key === 'media')
          newActiveSection = state.results.chats.expanded
            ? 'chats'
            : state.results.contacts.expanded
            ? 'contacts'
            : state.results.messages.expanded
            ? 'messages'
            : null;
        if (payload.key === 'chats')
          newActiveSection = state.results.contacts.expanded
            ? 'contacts'
            : state.results.messages.expanded
            ? 'messages'
            : state.results.media.expanded
            ? 'media'
            : null;
      }
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            expanded: payload.expanded,
          },
        },
        activeSection: newActiveSection,
      };
    case 'TOGGLE_DATA_FETCHED':
      return {
        ...state,
        results: {
          ...state.results,
          [payload.key]: {
            ...state.results[payload.key],
            dataFetched: payload.fetched,
          },
        },
      };
    case 'UPDATE_USER_IMAGES':
      return {
        ...state,
        results: {
          ...state.results,
          contacts: {
            ...state.results.contacts,
            results: payload,
            displayedResults: state.results.contacts.displayedResults.map(
              (_, i) => payload[i]
            ),
          },
        },
      };
    case 'DISPLAY_ALL':
      return {
        ...state,
        results: {
          ...state.results,
          [payload]: {
            ...state.results[payload],
            displayedResults: state.results[payload].results,
            dataFetched: false,
          },
        },
      };
    default:
      return state;
  }
}
