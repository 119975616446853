import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useInterval from './useIntervalHook';
import { getLanguageSettings } from '../../redux/selectors/App';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few sec',
    ss: '%d sec',
    m: 'a min',
    mm: '%d min',
  },
});

/**
 * React Component for moment.js
 * It will parse provided date and will return formated date
 *
 * By default will return
 * *1 providedDate < 45 min from now = Time from now
 * *2 providedDate is today = HH:mm
 * *3 providedDate same week = ddd (Mon, Tue...)
 * *4 providedDate same year = MMM DD (Mar 20, Apr 03...)
 * *5 default = YYYY-MM-DD (2019-01-31)
 *
 * @param {string | date | array} children
 * @param {boolean} fullTime (if true, will return *4 or *5 with HH:MM at the end)
 * @param {boolean} onlyTime (will do nothing if fullTime) - (if true, will return only *1 or *2)
 * @param {boolean} withTime (if true, for *3, *4, *5 - will add HH:MM at the end)
 */

const Moment = ({ children, onlyTime, withTime, fullTime }) => {
  const [date, setDate] = useState('');
  const [interval, setInterval] = useState(null);
  const language = useSelector(getLanguageSettings);

  const updateDate = useCallback(() => {
    if (children) {
      const now = moment();
      const beforeHour = now.subtract(44, 'minutes');
      const displayTime = moment(children);
      const isInHour = displayTime.isAfter(beforeHour);
      const isToday = displayTime.isSame(now, 'day');
      const isSameWeek = displayTime.isSame(now, 'week');
      const isSameYear = displayTime.isSame(now, 'year');
      let interval = null;

      let toDisplay = displayTime.format(
        `YYYY-MM-DD ${withTime ? 'HH:mm' : ''}`
      );
      if (isSameYear) {
        toDisplay = displayTime.format(`MMM DD ${withTime ? 'HH:mm' : ''}`);
      }
      if (isSameWeek && !fullTime) {
        toDisplay = displayTime.format(`ddd ${withTime ? 'HH:mm' : ''}`);
      }
      if ((isToday || onlyTime) && !fullTime) {
        toDisplay = displayTime.format('HH:mm');
      }
      if (isInHour && !fullTime) {
        toDisplay = displayTime.fromNow();
        interval = 10000;
      }

      setDate(toDisplay);
      setInterval(interval);
    }
  }, [children, fullTime, onlyTime, withTime]);

  useEffect(() => {
    updateDate();
  }, [children, updateDate, language]);

  useInterval(() => {
    updateDate();
  }, interval);

  return <>{date}</>;
};

export default Moment;
