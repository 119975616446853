import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateMessageFileData } from '../../../../../../../redux/actions/Conversations';
import FileMessage from '../FileMessage/FileMessage';
import InfoSubheader from '../../../../../../shared/InfoSubheader/InfoSubheader';

const AudioMessage = ({ message }) => {
  const [renderAsFile, setRenderAsFile] = useState(false);
  const dispatch = useDispatch();
  const counter = useRef(0);
  const audioEl = useRef(null);

  const { file_data } = message;
  const { media_file_url } = file_data;

  const handleError = async e => {
    if (counter.current >= 3) {
      setRenderAsFile(true);
      return;
    }
    counter.current++;
    await dispatch(updateMessageFileData(message));
    if (audioEl.current) audioEl.current.load();
  };

  if (renderAsFile) return <FileMessage message={message} />;

  return (
    <div>
      <audio
        controls
        src={media_file_url}
        style={{ maxWidth: '100%' }}
        onError={handleError}
        ref={audioEl}
      >
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <InfoSubheader message={message} />
    </div>
  );
};

export default AudioMessage;
