import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getName, usernameText } from '../../../../../utils/userUtils';
import { getTitle } from '../../../../../utils/conversationUtils';
import {
  changeChat,
  resetCreatingConversation,
  toggleChatView,
} from '../../../../../redux/actions/App';
import {
  getChatId,
  getCreatingConversation,
} from '../../../../../redux/selectors/App';
import { getCurrentUserId } from '../../../../../redux/selectors/CurrentUser';
import {
  getAllOnlineContacts,
  getAllUsers,
} from '../../../../../redux/selectors/Users';
import Moment from '../../../../shared/Moment';
import ProfileImage from '../../../../shared/ProfileImage/ProfileImage';
import Avatar from '../../../../shared/Avatar/Avatar';
import { useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const Conversation = ({
  conversation,
  forward,
  withSelect,
  selectedConversations,
  selectConversations,
  classes,
}) => {
  const chatId = useSelector(getChatId);
  const lastMessage = conversation.latest_message;
  // const lastMessageTime = lastMessage ? lastMessage.timestamp : '';
  const lastMessageTime = lastMessage ? lastMessage.created_at : '';
  const lastMessageBody = lastMessage ? lastMessage.body : '';
  const lastMessageUserId = lastMessage ? lastMessage.user_id : null;
  const lastMessageUser = useSelector(state =>
    lastMessage
      ? state.Users.users[lastMessage.user_id] &&
        usernameText(state.Users.users[lastMessage.user_id])
      : ''
  );
  const users = useSelector(getAllUsers);
  const currentUser = useSelector(getCurrentUserId);
  const creatingConversation = useSelector(getCreatingConversation);
  const { status } = creatingConversation;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const draftMessage = conversation.draft && conversation.draft.body;
  const typing = (conversation.typing || [])
    .filter(t => t.user_id !== currentUser)
    .map(t => t.user_id);
  const typingUsers = Object.entries(users)
    .filter(([user_id, user]) => typing.includes(user_id))
    .map(([user_id, user]) => user);

  const usersOnline = useSelector(getAllOnlineContacts);
  const userOnline =
    conversation.users.length === 1 &&
    usersOnline.find(user_id => user_id === conversation.users[0].user_id);

  const unseenMessages =
    useSelector(
      state => state.Conversations.unseenMessages[conversation.chat_id]
    ) || [];
  const unSeenMessages = conversation.messages.filter(m => !m.statuses.seen);

  const unseenCount = new Set([
    ...unseenMessages,
    ...unSeenMessages.map(m => m.message_id),
  ]).size;

  // const otherUser = conversation =>
  //   conversation.private && conversation.users[0];

  const isSelected = chatId === conversation.chat_id && !forward;
  const photo = !conversation.private && conversation.image_signed_url;

  const toggleView = () => {
    dispatch(toggleChatView(true));
  };

  const renderLastMessage = () => {
    if (!lastMessage) return '';
    if (!lastMessage.message_type || lastMessage.message_type === 'text') {
      if (
        lastMessage.forwards?.message_id &&
        lastMessage.forwards?.message_type !== 'text'
      ) {
        return lastMessageUserId === currentUser
          ? `${t('Conversation.you_forwarded')} ${t(
              `Conversation.types.${lastMessage.forwards.message_type}`
            )}`
          : `${lastMessageUser} ${t('Conversation.user_forwarded')} ${t(
              `Conversation.types.${lastMessage.forwards.message_type}`
            )}`;
      }
      return conversation.private
        ? lastMessageBody
        : `${lastMessageUser}: ${lastMessageBody}`;
    }
    return lastMessageUserId === currentUser
      ? `${t('Conversation.you_sent')} ${t(
          `Conversation.types.${lastMessage.message_type}`
        )}`
      : `${lastMessageUser} ${t('Conversation.user_sent')} ${t(
          `Conversation.types.${lastMessage.message_type}`
        )}`;
  };

  const renderAvatar = () => {
    if (conversation.private)
      return (
        <ProfileImage
          user_id={conversation.users[0].user_id}
          userOnline={userOnline}
        />
      );
    return <Avatar group photo={photo} />;
  };

  const renderSubheader = () => {
    if (typing.length)
      return (
        <Typography variant="body2" className={classes.descriptionMessage}>
          {typingUsers.map(getName).join(', ')}{' '}
          {t('TypingIndicator.typing', {
            count: typing.length,
          })}
        </Typography>
      );
    // else if (draftMessage)
    //   return (
    //     <Typography variant="body2" className={classes.descriptionMessage}>
    //       <span className={classes.draft}>{t('Conversation.draft')}</span>
    //       {draftMessage}
    //     </Typography>
    //   );
    else if (lastMessageUser && conversation.latest_message.deleted) {
      if (lastMessageUserId === currentUser)
        return (
          <div className={classes.deletedMessage}>
            <NotInterestedIcon fontSize="small" />
            <Typography variant="body2">
              {t('Conversation.you_deleted_this_message')}
            </Typography>
          </div>
        );
      else
        return (
          <div className={classes.deletedMessage}>
            <NotInterestedIcon fontSize="small" />
            <Typography variant="body2">
              {t('Conversation.message_deleted_by_contact')}
            </Typography>
          </div>
        );
    } else
      return (
        lastMessageUser &&
        !conversation.latest_message.deleted && (
          <Typography
            variant="body2"
            data-testid="last-message"
            className={classes.lastMessage}
          >
            {renderLastMessage()}
          </Typography>
        )
      );
  };

  const renderAction = () => (
    <>
      <Typography variant="caption">
        <Moment>{lastMessageTime}</Moment>
      </Typography>
      {unseenCount > 0 && (
        <div className={classes.notification}>{unseenCount}</div>
      )}
    </>
  );

  const handleClick = () => {
    if (status) dispatch(resetCreatingConversation());
    if (forward) {
      withSelect && selectConversations(conversation);
    } else {
      dispatch(changeChat(conversation.chat_id));
      toggleView();
    }
  };

  return (
    conversation.users && (
      <ListItem
        button
        selected={isSelected}
        key={conversation.chat_id}
        onClick={handleClick}
      >
        {withSelect && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              tabIndex={-1}
              checked={selectedConversations.indexOf(conversation.chat_id) >= 0}
            />
          </ListItemIcon>
        )}
        <Card className={classes.card}>
          <CardHeader
            className={classes.header}
            avatar={renderAvatar()}
            title={getTitle(conversation)}
            subheader={renderSubheader()}
            action={renderAction()}
            titleTypographyProps={{
              style: {
                color: conversation.hasLecturer
                  ? theme.palette.primary.main
                  : theme.palette.text.primary,
              },
            }}
          />
        </Card>
      </ListItem>
    )
  );
};

export default Conversation;
