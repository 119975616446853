import React from 'react';
import moment from 'moment';
import FileMessage from './FileMessage/FileMessage';
import ImageMessage from './ImageMessage/ImageMessage';
import VideoMessage from './VideoMessage/VideoMessage';
import AudioMessage from './AudioMessage/AudioMessage';

const MediaMessage = ({ message }) => {
  const { message_type, file_data, body } = message;

  const isFile = message_type === 'file';
  const isImage = message_type === 'image';
  const isVideo = message_type === 'video';
  const isAudio = message_type === 'audio';

  const expired = moment().isAfter(file_data?.expires_at);

  function renderMediaMessage() {
    if (expired || isFile) return <FileMessage message={message} />;
    else if (isImage) return <ImageMessage message={message} />;
    else if (isVideo) return <VideoMessage message={message} />;
    else if (isAudio) return <AudioMessage message={message} />;
    else return body;
  }

  return <div data-testid="media-message">{renderMediaMessage()}</div>;
};

export default MediaMessage;
