export default function reducer(
  state = {
    isVisible: true,
    chatId: false,
    searchResultsView: false,
    creatingConversation: {
      status: false,
      submitting: false,
      users: [],
      initialUser: null,
    },
    conversationsFetched: false,
    fetchingConversations: false,
    nextPage: null,
    toggleView: false,
    chatWidth: '',
    settings: {
      communicator: {
        font_size: 1,
        font_family: 'Roboto',
      },
      common: {
        color_theme: 'Classic',
        color_accent: 'WaikawaGray',
        background_color: 'default',
        language: 'en',
      },
    },
    settingsPage: 'settings',
    conversationsOrder: [],
    editMessage: false,
  },
  { type, payload }
) {
  switch (type) {
    case 'SET_VISIBILITY':
      return {
        ...state,
        isVisible: payload,
      };
    case 'TOGGLE_SEARCH_RESULTS':
      return {
        ...state,
        searchResultsView: payload,
      };
    case 'UPDATE_CREATING_CONVERSATION':
      return {
        ...state,
        creatingConversation: {
          ...state.creatingConversation,
          ...payload,
        },
      };
    case 'RESET_CREATING_CONVERSATION':
      return {
        ...state,
        creatingConversation: {
          status: false,
          submitting: false,
          users: [],
          initialUser: null,
        },
      };
    case 'CHANGE_CHAT':
      return {
        ...state,
        chatId: payload,
      };
    case 'CHANGE_CHAT_VIEW':
      return {
        ...state,
        toggleView: payload,
      };
    case 'CHANGE_CHAT_WIDTH':
      return {
        ...state,
        chatWidth: payload,
      };
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: payload,
      };
    case 'PATCH_SETTINGS':
      return {
        ...state,
        settings: payload,
      };
    case 'SET_SETTINGS_PAGE':
      return {
        ...state,
        settingsPage: payload,
      };
    case 'CONVERSATIONS_FETCHED':
      return {
        ...state,
        conversationsFetched: payload,
      };
    case 'FETCHING_CONVERSATIONS':
      return {
        ...state,
        fetchingConversations: payload,
      };
    case 'CHANGE_CONVERSATIONS_ORDER':
      return {
        ...state,
        conversationsOrder: payload,
      };
    case 'SET_NEXT_PAGE': {
      return {
        ...state,
        nextPage: payload,
      };
    }
    case 'TOGGLE_EDIT':
      return {
        ...state,
        editMessage: payload,
      };
    default:
      return state;
  }
}
