import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getActiveSection,
  getFocusedResultIndex,
} from '../../../../../../redux/selectors/Search';
import { getName } from '../../../../../../utils/userUtils';
import { getTitle } from '../../../../../../utils/conversationUtils';
import Moment from '../../../../../shared/Moment';
import { changeChat } from '../../../../../../redux/actions/App';
import { getCurrentUserId } from '../../../../../../redux/selectors/CurrentUser';
import { getAllOnlineContacts } from '../../../../../../redux/selectors/Users';
import Avatar from '../../../../../shared/Avatar/Avatar';
import ProfileImage from '../../../../../shared/ProfileImage/ProfileImage';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const ChatResult = ({ conversation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeSection = useSelector(getActiveSection);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const currentUser = useSelector(getCurrentUserId);
  const usersOnline = useSelector(getAllOnlineContacts);
  const userOnline =
    conversation.users.length === 1 &&
    usersOnline.find(user_id => user_id === conversation.users[0]);
  const lastMessage = conversation.latest_message;
  const lastMessageTime = lastMessage ? lastMessage.created_at : '';
  const lastMessageBody = lastMessage ? lastMessage.body : '';
  const lastMessageUserId = lastMessage ? lastMessage.user_id : null;
  const lastMessageUser = useSelector(state =>
    lastMessage
      ? state.Users.users[lastMessage.user_id] &&
        getName(state.Users.users[lastMessage.user_id])
      : ''
  );

  const photo = !conversation.private && conversation.image_signed_url;
  const titleColor = conversation.hasLecturer ? 'primary' : 'textPrimary';

  const renderAvatar = () => {
    if (conversation.private)
      return (
        <ProfileImage
          user_id={conversation.users[0].user_id}
          userOnline={userOnline}
        />
      );
    return <Avatar group photo={photo} />;
  };

  const renderTitle = () => {
    return (
      <Typography variant="body1" color={titleColor}>
        {getTitle(conversation)}
      </Typography>
    );
  };

  const renderSubheader = () => {
    if (lastMessageUser && conversation.latest_message.deleted) {
      if (lastMessageUserId === currentUser)
        return (
          <div className={classes.deletedMessage}>
            <NotInterestedIcon fontSize="small" />
            <Typography variant="body2">
              {t('Conversation.you_deleted_this_message')}
            </Typography>
          </div>
        );
      else
        return (
          <div className={classes.deletedMessage}>
            <NotInterestedIcon fontSize="small" />
            <Typography variant="body2">
              {t('Conversation.message_deleted_by_contact')}
            </Typography>
          </div>
        );
    } else
      return (
        lastMessageUser &&
        !conversation.latest_message.deleted && (
          <Typography
            variant="body2"
            data-testid="last-message"
            className={classes.lastMessage}
          >
            {renderLastMessage()}
          </Typography>
        )
      );
  };

  const renderLastMessage = () => {
    if (!lastMessage) return '';
    if (!lastMessage.message_type || lastMessage.message_type === 'text') {
      if (
        lastMessage.forwards?.message_id &&
        lastMessage.forwards?.message_type !== 'text'
      ) {
        return lastMessageUserId === currentUser
          ? `${t('Conversation.you_forwarded')} ${t(
              `Conversation.types.${lastMessage.forwards.message_type}`
            )}`
          : `${lastMessageUser} ${t('Conversation.user_forwarded')} ${t(
              `Conversation.types.${lastMessage.forwards.message_type}`
            )}`;
      }
      return conversation.private
        ? lastMessageBody
        : `${lastMessageUser}: ${lastMessageBody}`;
    }
    return lastMessageUserId === currentUser
      ? `${t('Conversation.you_sent')} ${t(
          `Conversation.types.${lastMessage.message_type}`
        )}`
      : `${lastMessageUser} ${t('Conversation.user_sent')} ${t(
          `Conversation.types.${lastMessage.message_type}`
        )}`;
  };

  const renderAction = () => (
    <Typography variant="caption" color="textSecondary">
      <Moment>{lastMessageTime}</Moment>
    </Typography>
  );

  const classes = makeStyles(theme => {
    const cls = {
      card: {
        cursor: 'pointer',
        '&:hover': { background: theme.palette.action.hover },
      },
      deletedMessage: {
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'italic',
        '& .MuiTypography-body2': {
          marginLeft: theme.spacing(1),
          fontStyle: 'italic',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 195,
        },
        '& svg': { fontSize: '1em' },
      },
      lastMessage: {
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 190,
      },
    };

    if (activeSection === 'chats') {
      cls.card[`&:nth-of-type(${focusedResultIndex + 1})`] = {
        background: theme.palette.action.focus,
      };
    }

    return cls;
  })();

  return (
    <Card
      className={classes.card}
      onClick={() => dispatch(changeChat(conversation.chat_id))}
    >
      <CardHeader
        avatar={renderAvatar()}
        title={renderTitle()}
        subheader={renderSubheader()}
        action={renderAction()}
      />
    </Card>
  );
};

export default ChatResult;
