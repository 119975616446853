export default function reducer(
  state = {
    users: {},
    contacts: [],
    online_contacts: [],
  },
  { type, payload }
) {
  switch (type) {
    case 'ADD_USER':
      return {
        ...state,
        users: {
          ...state.users,
          ...payload,
        },
      };
    case 'ADD_USERS':
      return {
        ...state,
        users: {
          ...state.users,
          ...payload,
        },
      };
    case 'UPDATE_USER':
      return {
        ...state,
        users: {
          ...state.users,
          [payload.user_id]: {
            ...state.users[payload.user_id],
            image_url: payload.image_url,
          },
        },
      };
    case 'SET_ALL_CONTACTS':
      return {
        ...state,
        contacts: [...state.contacts, ...payload],
      };
    case 'ADD_USER_TO_CONTACTS':
      return {
        ...state,
        contacts: [...state.contacts, payload],
      };
    case 'REMOVE_USER_FROM_CONTACTS':
      return {
        ...state,
        contacts: [...state.contacts.filter(user => user.user_id !== payload)],
      };
    case 'REMOVE_USERS_FROM_CONTACTS':
      return {
        ...state,
        contacts: [
          ...state.contacts.filter(user => !payload.includes(user.user_id)),
        ],
      };
    case 'ONLINE_CONTACTS':
      return {
        ...state,
        online_contacts: payload.online_contacts,
      };
    case 'SET_ONLINE_STATUS':
      let new_online_contacts = new Set([...state.online_contacts]);
      if (payload.online) {
        new_online_contacts.add(payload.user_id);
      } else {
        new_online_contacts.delete(payload.user_id);
      }
      return {
        ...state,
        online_contacts: [...new_online_contacts],
      };
    default:
      return state;
  }
}
