import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

const Link = ({ data }) => {
  const { link_title, link_description, body } = data;

  const classes = makeStyles(theme => ({
    link: { marginTop: theme.spacing(2) },
    title: { fontWeight: 'bold' },
  }))();

  return (
    <div className={classes.link}>
      <Typography variant="subtitle1" className={classes.title}>
        {link_title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {link_description}
      </Typography>
      <MuiLink href={body} target="_blank" rel="noopener">
        {body}
      </MuiLink>
    </div>
  );
};

export default Link;
