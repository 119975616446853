import React from 'react';
import io from 'socket.io-client';
import { Auth } from './azureAuth';

const URL = process.env.REACT_APP_DEV_SOCKET_URL;

const socket = async () => {
  const token = await Auth.getToken();
  return io(URL, {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  });
};

export const SocketContext = React.createContext({});
export const SocketProvider = SocketContext.Provider;
export const SocketConsumer = SocketContext.Consumer;

export default socket;
