import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getName } from '../../../../utils/userUtils';
import { setSettingsPage } from '../../../../redux/actions/App';
import { getCurrentUser } from '../../../../redux/selectors/CurrentUser';
import Avatar from '../../../shared/Avatar/Avatar';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import PaletteIcon from '@material-ui/icons/Palette';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const Settings = () => {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setSettings = type => {
    dispatch(setSettingsPage(type));
  };

  const classes = makeStyles(theme => ({
    settings: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    profile: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(2.5),
      display: 'flex',
      alignItems: 'center',
      '& h6': {
        marginLeft: theme.spacing(2),
      },
    },
    avatar: {
      width: 80,
      height: 80,
      fontSize: '2em',
    },
  }))();

  return (
    <DialogContent className={classes.settings}>
      <div className={classes.profile}>
        <Avatar user={currentUser} className={classes.avatar} />
        <Typography variant="subtitle1">{getName(currentUser)}</Typography>
      </div>
      <Divider />

      <List>
        <ListItem button onClick={() => setSettings('appearance')}>
          <ListItemIcon>
            <PaletteIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('Settings.appearance')} />
        </ListItem>
        <ListItem button onClick={() => setSettings('privacy')}>
          <ListItemIcon>
            <VisibilityIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('Settings.privacy')} />
        </ListItem>
      </List>
    </DialogContent>
  );
};

export default Settings;
