import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SocketContext } from '../../../../../../../api/socket';
import {
  usernameColor,
  usernameText,
} from '../../../../../../../utils/userUtils';
import { downloadFile } from '../../../../../../../utils/fileUtils';
import { getAllUsers } from '../../../../../../../redux/selectors/Users';
import { getCurrentUserId } from '../../../../../../../redux/selectors/CurrentUser';
import { getSpecificMessage } from '../../../../../../../redux/selectors/Conversations';
import MessageInfo from './MessageInfo/MessageInfo';
import MediaMessage from '../../MediaMessage/MediaMessage';
import Link from '../../../../ChatHeader/GroupInfo/Panels/Links/Link/Link';
import SmartboardMessage from '../../MediaMessage/SmartboardMessage/SmartboardMessage';
import Profile from '../../../../../../Header/ProfileActions/Profile/Profile';
import ContactInfo from '../../../../ChatHeader/ContactInfo/ContactInfo';
import Avatar from '../../../../../../shared/Avatar/Avatar';
import DialogTitle from '../../../../../../shared/Dialog/DialogTitle/DialogTitle';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import { makeStyles } from '@material-ui/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';

const MessageDetails = ({ handleClose, message, fromSearch }) => {
  let messageWithFullData = useSelector(
    getSpecificMessage(message.message_id, message.chat_id)
  );
  const users = useSelector(getAllUsers);
  const userId = useSelector(getCurrentUserId);
  const socket = useContext(SocketContext);
  const { t } = useTranslation();

  const isMedia = !['text', 'youtube', 'link', 'smartboard'].includes(
    message.message_type
  );
  const isExpired = isMedia && moment().isAfter(message.file_data?.expires_at);
  const isCurrentUser = userId === message.user_id;

  const renderMessage = () => {
    const { message_type, body } = messageWithFullData;
    switch (message_type) {
      case 'smartboard':
        return <SmartboardMessage message={messageWithFullData} />;
      case 'link':
        return (
          <div className={classes.text}>
            <Link data={messageWithFullData} />
          </div>
        );
      case 'text':
        return (
          <div className={classes.text}>
            <Typography variant="body1">{body}</Typography>
          </div>
        );
      default:
        return <MediaMessage message={messageWithFullData} />;
    }
  };

  const classes = makeStyles(theme => ({
    content: { padding: 0 },
    message: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      color: theme.palette.text.primary,
      background: theme.palette.background.default,
      minHeight: 160,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      width: '100%',
      alignSelf: 'flex-start',
    },
    received: {
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(2),
        color: theme.palette.text.primary,
      },
    },
    user: {
      all: 'unset',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-body1': { marginLeft: theme.spacing(2) },
    },
    section: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      '& .MuiTypography-subtitle1': {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
      },
    },
    accordion: {
      border: 0,
      boxShadow: 'none',
      '& .MuiAccordionDetails-root': {
        paddingTop: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: `${theme.spacing(1.5)}px`,
      },
      '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: 'unset' },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        marginBottom: theme.spacing(2),
      },
    },
    download: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
      margin: theme.spacing(2),
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      cursor: isExpired ? 'not-allowed' : 'pointer',
      '& .MuiSvgIcon-root': {
        fill: isExpired ? theme.palette.grey[500] : theme.palette.text.primary,
      },
      '& .MuiTypography-body2': {
        marginLeft: theme.spacing(1),
        color: isExpired ? theme.palette.grey[500] : theme.palette.text.primary,
      },
    },
    link: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': { background: theme.palette.action.hover },
    },
    icon: {
      borderRadius: '50%',
      padding: '.4rem',
      background: theme.palette.primary.light,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    linkIcon: {
      fill: theme.palette.getContrastText(theme.palette.text.primary),
    },
    linkContent: { marginLeft: theme.spacing(2) },
    title: { fontWeight: 'bold' },
  }))();

  if (fromSearch) messageWithFullData = message;
  const { seen_by, downloaded_by, statuses } = messageWithFullData;
  const user = fromSearch ? users[message.user_id] : messageWithFullData.user;
  const seenBy = seen_by.filter(u => u !== user.user_id);
  const downloadedBy = downloaded_by
    ? downloaded_by.filter(u => u !== user.user_id)
    : [];

  return (
    <>
      <DialogTitle onClose={handleClose}>
        {t('MessageActions.Details.title')}
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.message}>{renderMessage()}</div>
        {(statuses.received || statuses.seen) && (
          <div className={classes.received}>
            <CheckIcon color="primary" />
            <Typography variant="subtitle1">
              {t('MessageActions.Details.received')}
            </Typography>
          </div>
        )}
        <div className={classes.section}>
          <Typography variant="subtitle1">
            {t('MessageActions.Details.sender')}
          </Typography>
          <Dialog
            trigger={
              <button className={classes.user}>
                <Avatar user={user} />
                <Typography variant="body1" color={usernameColor(user)}>
                  {usernameText(user)}
                </Typography>
              </button>
            }
          >
            {close =>
              isCurrentUser ? (
                <Profile user={user} handleClose={close} />
              ) : (
                <ContactInfo user_id={user.user_id} handleClose={close} />
              )
            }
          </Dialog>
        </div>
        {fromSearch && (
          <MessageInfo message={messageWithFullData} closePopup={handleClose} />
        )}
        {userId === user.user_id && seenBy.length ? (
          <Accordion defaultExpanded className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('MessageActions.Details.seen_by')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {seenBy.map(user => (
                <div className={classes.user} key={user}>
                  <Avatar user={users[user]} />
                  <Typography
                    variant="body1"
                    color={usernameColor(users[user])}
                  >
                    {usernameText(users[user])}
                  </Typography>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}
        {userId === user.user_id && downloadedBy.length ? (
          <Accordion defaultExpanded className={classes.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('MessageActions.Details.downloaded_by')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {downloadedBy.map(user => (
                <div className={classes.user} key={user}>
                  <Avatar user={users[user]} />
                  <Typography
                    variant="body1"
                    color={usernameColor(users[user])}
                  >
                    {usernameText(users[user])}
                  </Typography>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ) : null}
        {isMedia && (
          <button
            className={classes.download}
            onClick={() => downloadFile(isExpired, message, socket)}
          >
            <GetAppIcon />
            <Typography variant="body2" color="textPrimary">
              {t('MessageActions.download')}
            </Typography>
          </button>
        )}
      </DialogContent>
    </>
  );
};

export default MessageDetails;
