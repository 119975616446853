import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllContacts } from '../../../../../../redux/selectors/Users';
import { getName } from '../../../../../../utils/userUtils';
import Contact from './Contact';
import DialogTitle from '../../../../../shared/Dialog/DialogTitle/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { getSettings } from '../../../../../../redux/selectors/App';

const Contacts = ({ handleClose }) => {
  const contacts = useSelector(getAllContacts);
  const [displayedContacts, setDisplayedContacts] = useState(contacts);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const settings = useSelector(getSettings);

  useEffect(() => {
    setDisplayedContacts(
      contacts.filter(contact =>
        getName(contact)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [contacts, searchTerm]);

  const handleChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const classes = makeStyles(theme => ({
    content: { padding: 0 },
    search: { padding: '0.75rem 1rem' },
    searchInput: {
      '& .MuiInputBase-root': {
        backgroundColor:
          settings.common.background_color === 'default'
            ? theme.palette.action.hover
            : theme.palette.background.default,
        borderRadius: 18,
      },
    },
    contactList: { padding: '.5rem 0' },
    empty: {
      padding: '1rem 0',
      textAlign: 'center',
    },
  }))();

  return (
    <>
      <DialogTitle onClose={handleClose}>
        {t('ProfileActions.contacts')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Divider />
        <div className={classes.search}>
          <TextField
            className={classes.searchInput}
            fullWidth
            hiddenLabel
            variant="filled"
            placeholder={t('ProfileActions.search_contacts')}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
        <Divider />
        <div className={classes.contactList}>
          {displayedContacts.length ? (
            <List>
              {displayedContacts.map(contact => (
                <Contact
                  key={contact.user_id}
                  user={contact}
                  onClose={handleClose}
                />
              ))}
            </List>
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.empty}
            >
              {t('ProfileActions.list_empty')}
            </Typography>
          )}
        </div>
      </DialogContent>
    </>
  );
};

export default Contacts;
