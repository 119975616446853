import i18next from '../../i18n';
import {
  getDataFromApi,
  buildURL,
  postDataToApi,
  putDataToApi,
} from '../../api/dataFromApi';
import { getUser, addUsers } from './Users';
import {
  conversationsFetched,
  fetchingConversations,
  changeConversationsOrder,
  updateConversationsOrder,
  changeChat,
  updateCreatingConversation,
} from './App';
import MessageTone from '../../assets/your-turn.ogg';
import { setLastLogin } from './CurrentUser';
import * as $ from './Conversations';
import { getName } from '../../utils/userUtils';

let tone = new Audio(MessageTone);

export const setConversations = conversations => {
  return {
    type: 'SET_CONVERSATIONS',
    payload: Object.assign(
      {},
      ...conversations.map(conversation => ({
        [conversation.chat_id]: conversation,
      }))
    ),
  };
};

export const addConversations = conversations => {
  return {
    type: 'ADD_CONVERSATIONS',
    payload: Object.assign(
      {},
      ...conversations.map(conversation => ({
        [conversation.chat_id]: conversation,
      }))
    ),
  };
};

export const removeConversation = chatId => {
  return {
    type: 'REMOVE_CONVERSATION',
    payload: chatId,
  };
};

export const removeConversations = ids => {
  return {
    type: 'REMOVE_CONVERSATIONS',
    payload: ids,
  };
};

export const addNewConversation = conversation => {
  return {
    type: 'ADD_NEW_CONVERSATION',
    payload: conversation,
  };
};

export const updateConversation = payload => {
  return {
    type: 'UPDATE_CONVERSATION',
    payload,
  };
};

export const editConversation = payload => {
  return {
    type: 'EDIT_CONVERSATION',
    payload,
  };
};

export const setConversationDetails = (id, details) => {
  return {
    type: 'SET_CONVERSATION_DETAILS',
    payload: {
      id,
      details,
    },
  };
};

export const setConversationImages = images => {
  return {
    type: 'SET_CONVERSATION_IMAGES',
    payload: images,
  };
};

export const setMessages = (id, messages) => {
  return {
    type: 'SET_MESSAGES',
    payload: {
      id,
      ...messages,
    },
  };
};

export const clearMessages = (id, messages, latestMessage) => {
  return {
    type: 'CLEAR_MESSAGES',
    payload: {
      id,
      messages,
      latestMessage,
    },
  };
};

export const addNewMessage = (id, message) => {
  const statuses = message.statuses || {};
  return {
    type: 'ADD_MESSAGE',
    payload: {
      id,
      message: {
        ...message,
        statuses: {
          received: false,
          seen: false,
          read: false,
          ...statuses,
        },
      },
    },
  };
};

export const replaceMessage = (id, message) => {
  const statuses = message.statuses || {};
  return {
    type: 'REPLACE_MESSAGE',
    payload: {
      id,
      message: {
        ...message,
        statuses: {
          received: false,
          seen: false,
          read: false,
          ...statuses,
        },
      },
    },
  };
};

export const replaceMessages = (chatId, messages) => {
  return {
    type: 'REPLACE_MESSAGES',
    payload: {
      chatId,
      messages,
    },
  };
};

export const changeFetchingMessages = (id, data) => {
  return {
    type: 'CHANGE_FETCHING_MESSAGES',
    payload: {
      id,
      data,
    },
  };
};

export const changeMessageStatus = messages => {
  return {
    type: 'CHANGE_MESSAGE_STATUS',
    payload: messages,
  };
};

export const changeMessageSeenBy = messages => {
  return {
    type: 'CHANGE_MESSAGE_SEEN_BY',
    payload: messages,
  };
};

export const changeMessageDownloadedBy = (chatId, messageId, user_ids) => {
  return {
    type: 'CHANGE_MESSAGE_DOWNLOADED_BY',
    payload: {
      chatId,
      messageId,
      user_ids,
    },
  };
};

export const deleteMessage = (chatId, messageId) => {
  return {
    type: 'DELETE_MESSAGE',
    payload: {
      chatId,
      messageId,
    },
  };
};

export const editMessage = (
  chatId,
  messageId,
  body,
  last_edited,
  file_data
) => {
  return {
    type: 'EDIT_MESSAGE',
    payload: {
      chatId,
      messageId,
      body,
      last_edited,
      file_data,
    },
  };
};

export const replyToMessage = (chatId, messageId) => {
  return {
    type: 'REPLY_TO_MESSAGE',
    payload: {
      chatId,
      messageId,
    },
  };
};

export const setDraft = (chatId, message) => {
  return {
    type: 'SET_DRAFT_MESSAGE',
    payload: {
      chatId,
      message,
    },
  };
};

export const setTyping = (chatId, userId) => {
  return {
    type: 'SET_TYPING_INDICATOR',
    payload: {
      chatId,
      userId,
      timestamp: new Date(),
    },
  };
};

export const removeTyping = (chatId, userId) => {
  return {
    type: 'REMOVE_TYPING_INDICATOR',
    payload: {
      chatId,
      userId,
    },
  };
};

export const removeUserFromConversation = (chatId, userIds) => {
  return {
    type: 'REMOVE_USER_FROM_CONVERSATION',
    payload: {
      chatId,
      userIds,
    },
  };
};

export const addUsersToConversation = (chatId, users) => {
  return {
    type: 'ADD_USERS_TO_CONVERSATION',
    payload: {
      chatId,
      users,
    },
  };
};

export const saveUnknownMessage = message => {
  return {
    type: 'ADD_UNKNOWN_MESSAGE',
    payload: message,
  };
};

export const setUnseenMessages = messages => {
  return {
    type: 'SET_UNSEEN_MESSAGES',
    payload: messages,
  };
};

export const updateChatPhoto = (chatId, url) => {
  return {
    type: 'UPDATE_PHOTO',
    payload: {
      chatId,
      url,
    },
  };
};

export const setNextPage = url => {
  return {
    type: 'SET_NEXT_PAGE',
    payload: url,
  };
};

export const setPlaceholderMessageTimeout = (messageShortId, timeout) => {
  return {
    type: 'SET_PLACEHOLDER_TIMEOUT',
    payload: { messageShortId, timeout },
  };
};

export const clearPlaceholderMessageTimeouts = messageShortIds => {
  return {
    type: 'CLEAR_PLACEHOLDER_TIMEOUTS',
    payload: messageShortIds,
  };
};

export const markPlaceholderAsError = (chatId, messageShortId) => {
  return {
    type: 'MARK_AS_ERROR',
    payload: { chatId, messageShortId },
  };
};

export const clearErrorPlaceholders = (chatId, messages) => {
  return {
    type: 'CLEAR_ERROR_PLACEHOLDERS',
    payload: { chatId, messages },
  };
};

export const attachFileData = async message => {
  const changedMessage = { ...message };
  if (
    !['text', 'youtube', 'link'].includes(changedMessage.message_type) &&
    changedMessage.body
  ) {
    changedMessage.file_data = await getDataFromApi(
      `files/media-file/${changedMessage.body}/`,
      'v1'
    );
  }
  return changedMessage;
};

export const addUserIdsToConversation = (chatId, userIds) => {
  return async (dispatch, getState) => {
    if (!getState().Conversations.conversations[chatId]) return;
    let users = [];
    await Promise.all(
      userIds.map(async user_id => {
        if (!getState().Users.users[user_id]) {
          await dispatch(getUser(user_id));
        }
        users.push(getState().Users.users[user_id]);
      })
    );
    dispatch($.addUsersToConversation(chatId, users));
  };
};

export const getConversations = () => {
  return async (dispatch, getState) => {
    const Conversations = await getDataFromApi('chat/list/');
    if (!Conversations) return;

    const { results, links } = Conversations;

    const users = results
      .map(c => c.users)
      .reduce((prev, next) => [...prev, ...next], [])
      .filter(u => !getState().Users.users[u.user_id]);
    if (users.length) dispatch(addUsers(users));

    dispatch(changeConversationsOrder(results.map(c => c.chat_id)));
    dispatch(
      $.setConversations(
        results.map(c => ({
          messages: c.latest_message
            ? [
                {
                  ...c.latest_message,
                  statuses: { received: true, seen: true, read: true },
                  seen_by: [],
                  downloaded_by: [],
                  user: getState().Users.users[c.latest_message.user_id],
                  replace: true,
                },
              ]
            : [],
          draft: {},
          hasLecturer: c.users.some(u => u.role === 'Teacher'),
          ...c,
        }))
      )
    );

    await dispatch(getUnseenMessages());

    dispatch(conversationsFetched());
    if (links && links.next) {
      const url = links.next.split(buildURL()).join('');
      dispatch(setNextPage(url));
    }
    dispatch(setImages(results));
  };
};

export const getNextPage = () => {
  return async (dispatch, getState) => {
    if (getState().App.fetchingConversations || !getState().App.nextPage)
      return;
    dispatch(fetchingConversations(true));

    const nextPage = getState().App.nextPage;
    const Conversations = await getDataFromApi(nextPage);
    if (!Conversations) return;

    const { results, links } = Conversations;

    const users = results
      .map(c => c.users)
      .reduce((prev, next) => [...prev, ...next], [])
      .filter(u => !getState().Users.users[u.user_id]);
    if (users.length) dispatch(addUsers(users));

    const conversationsOrder = getState().App.conversationsOrder;
    dispatch(
      changeConversationsOrder([
        ...conversationsOrder,
        ...results.map(c => c.chat_id),
      ])
    );
    dispatch(
      $.addConversations(
        results.map(c => ({
          messages: c.latest_message
            ? [
                {
                  ...c.latest_message,
                  statuses: { received: true, seen: true, read: true },
                  seen_by: [],
                  downloaded_by: [],
                  user: getState().Users.users[c.latest_message.user_id],
                  replace: true,
                },
              ]
            : [],
          hasLecturer: c.users.some(u => u.role === 'Teacher'),
          draft: {},
          ...c,
        }))
      )
    );
    dispatch(fetchingConversations(false));
    if (links && links.next) {
      const url = links.next.split(buildURL()).join('');
      dispatch(setNextPage(url));
    } else {
      dispatch(setNextPage(null));
    }
    dispatch(setImages(results));
  };
};

export const getConversation = id => {
  return async dispatch => {
    const c = await getDataFromApi(`chat/${id}/`);
    dispatch(insertConversation(c));
    if (!c.private) dispatch(setImages([c.data]));
  };
};

export const getUnseenMessages = () => {
  return async dispatch => {
    const response = await getDataFromApi('chat/messages/unseen/');
    const unseenMessages = Object.assign(
      {},
      ...response.map(x => ({ [x.chat_id]: x.unseen_messages_ids }))
    );
    dispatch(setUnseenMessages(unseenMessages));
  };
};

export const setImages = conversations => {
  return async dispatch => {
    const uuid_list = conversations.filter(c => !c.private).map(c => c.image);
    const data = await postDataToApi('files/group-image/', { uuid_list }, 'v1');
    const images = {};
    for (const { chat_uuid, signed_url } of data.url_list) {
      images[chat_uuid] = signed_url;
    }
    dispatch(setConversationImages(images));
  };
};

export const updateConversations = () => {
  return async (dispatch, getState) => {
    const conv = await $.getLatestConversation();
    if (!conv) return;

    const users = conv.users.filter(u => !getState().Users.users[u.user_id]);
    if (users.length) dispatch(addUsers(users));

    if (getState().Conversations.conversations[conv.chat_id]) {
      await dispatch(addMessage(conv.latest_message, false));
      dispatch(updateConversation(conv));
    } else {
      conv.hasLecturer = conv.users.some(u => u.role === 'Teacher');
      dispatch(addNewConversation(conv));
      await dispatch(addMessage(conv.latest_message, false));
    }

    dispatch(updateConversationsOrder(conv.chat_id));

    if (conv.latest_message.user_id === getState().CurrentUser.user_id) {
      dispatch(changeChat(conv.chat_id));
      dispatch(
        updateCreatingConversation({ status: false, submitting: false })
      );
    }
  };
};

export const getConversationList = async (url, prevData = []) => {
  try {
    const Conversations = await getDataFromApi(url);
    if (!Conversations) return prevData;

    const results = [...prevData, ...Conversations.results];

    if (Conversations.links && Conversations.links.next) {
      url = Conversations.links.next.split(buildURL()).join('');
      return await $.getConversationList(url, results);
    }
    return results;
  } catch {
    return prevData;
  }
};

export const getLatestConversation = async () => {
  try {
    const Conversations = await getDataFromApi('chat/list/');
    if (!Conversations || !Conversations.results) return null;
    return Conversations.results[0];
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const removeUserConversation = chatId => {
  return async (dispatch, getState) => {
    const currentChatId = getState().App.chatId;
    if (currentChatId === chatId) {
      await dispatch(changeChat(false));
    }
    dispatch($.removeConversation(chatId));
  };
};

export const insertConversation = chatInfo => {
  return (dispatch, getState) => {
    const { data, users } = chatInfo;
    if (users) data.users = users;

    const conversations = [
      ...Object.values(getState().Conversations.conversations),
      data,
    ];
    const order = conversations
      .sort((a, b) => {
        if (!a.latest_message && b.latest_message) return 1;
        if (a.latest_message && !b.latest_message) return -1;
        if (!a.latest_message && !b.latest_message) return 0;
        return (
          new Date(b.latest_message.created_at).getTime() -
          new Date(a.latest_message.created_at).getTime()
        );
      })
      .map(c => c.chat_id);

    dispatch(
      addNewConversation({
        ...data,
        messages: [],
        hasLecturer: data.users.some(u => u.role === 'Teacher'),
      })
    );
    dispatch(changeConversationsOrder(order));
  };
};

export const getAllMessagesSince = () => {
  return async (dispatch, getState) => {
    const data = await $.getMessagesSince(
      'chat/messages/since/',
      [],
      getState().CurrentUser.lastLogin
    );
    dispatch(setLastLogin());
    data.reverse().forEach(async message => {
      await dispatch($.addMessage(message, false));
    });
  };
};

export const getMessagesSince = async (url, prevData = [], datetime) => {
  try {
    const data = await postDataToApi(url, {
      datetime,
    });
    const results = [...prevData, ...data.results];
    if (data.links && data.links.next) {
      url = data.links.next.split(buildURL()).join('');
      return await $.getMessagesSince(url, results, datetime);
    }
    return results;
  } catch {
    return prevData;
  }
};

export const getMessages = socket => {
  return async (dispatch, getState) => {
    const chatId = getState().App.chatId;
    if (
      !chatId ||
      getState().Conversations.conversations[chatId].fetching_messages
    )
      return false;
    let url = `chat/${chatId}/messages/`;
    const conversationLinks = getState().Conversations.conversations[chatId]
      .messages_links;
    if (conversationLinks && conversationLinks.previous) {
      url = conversationLinks.previous
        .split(buildURL())
        .join('')
        .replace('v0/', '');
    }
    dispatch($.changeFetchingMessages(chatId, true));
    const Messages = await getDataFromApi(url);
    if (Messages) {
      if (
        Messages.count > 25 &&
        Messages.results.length < 25 &&
        Messages.links.previous
      ) {
        url = Messages.links.previous
          .split(buildURL())
          .join('')
          .replace('v0/', '');
        const previousPage = await getDataFromApi(url);
        Messages.results = [...Messages.results, ...previousPage.results];
        Messages.links = previousPage.links;
      }
      let existingMessages = getState().Conversations.conversations[chatId]
        .messages;
      if (existingMessages.length) {
        Messages.results = Messages.results.filter(message =>
          existingMessages.every(
            e_message =>
              e_message.message_id !== message.message_id || e_message.replace
          )
        );
      }
      Messages.results = Messages.results
        .map(message => {
          let user = getState().Users.users[message.user_id];
          dispatch($.getForwardUser(message));
          if (
            message.user_id === getState().CurrentUser.user_id &&
            !message.statuses.seen
          ) {
            const statuses = {
              seen: true,
              received: true,
              read: true,
            };
            const data = {
              message_id: message.message_id,
              chat_id: chatId,
              statuses,
            };
            message.statuses = statuses;
            socket.emit('ack', data);
          }
          return { ...message, user };
        })
        .reverse();
      const newMessages = {
        ...Messages,
        results: [],
      };
      for (let messageIndex in Messages.results) {
        const message = Messages.results[messageIndex];
        const newMessage = await $.attachFileData(message);
        newMessages.results.push(newMessage);
      }
      existingMessages = getState().Conversations.conversations[chatId]
        .messages;
      if (existingMessages.length) {
        newMessages.results = newMessages.results.filter(message =>
          existingMessages.every(
            e_message =>
              e_message.message_id !== message.message_id || e_message.replace
          )
        );
      }
      dispatch($.setMessages(chatId, newMessages));
    }
  };
};

export const clearChatMessages = chatId => {
  return async (dispatch, getState) => {
    if (!chatId) return;
    const response = await getDataFromApi(`chat/${chatId}/`);
    const latestMessage = response.data.latest_message;
    const initialMessage = latestMessage
      ? {
          ...latestMessage,
          statuses: { received: true, seen: true, read: true },
          seen_by: [],
          downloaded_by: [],
          user: getState().Users.users[latestMessage.user_id],
          replace: true,
        }
      : null;
    const messages = initialMessage ? [initialMessage] : [];
    dispatch(clearMessages(chatId, messages, latestMessage));
  };
};

export const getForwardUser = message => {
  return async (dispatch, getState) => {
    if (
      message.forwards &&
      message.forwards.user_id &&
      !getState().Users.users[message.forwards.user_id]
    ) {
      await dispatch(getUser(message.forwards.user_id));
    }
  };
};

export const getConversationDetails = chatId => {
  return async dispatch => {
    const { data, users } = await getDataFromApi(`chat/${chatId}/`);
    if (users) data.users = users;
    await Promise.all(
      data.users.map(async user => await dispatch(getUser(user.user_id)))
    );
    data.hasLecturer = data.users.some(user => user.role === 'Teacher');
    dispatch($.setConversationDetails(chatId, data));
    return data;
  };
};

export const addMessage = (message, update = true) => {
  return async (dispatch, getState) => {
    if (!getState().Conversations.conversations[message.chat_id]) {
      const { data, users } = await getDataFromApi(`chat/${message.chat_id}/`);
      if (users) data.users = users;
      const usersToAdd = data.users.filter(
        u => !getState().Users.users[u.user_id]
      );
      if (usersToAdd.length) dispatch(addUsers(usersToAdd));
      dispatch(
        addNewConversation({
          ...data,
          messages: [],
          hasLecturer: data.users.some(u => u.role === 'Teacher'),
        })
      );
      dispatch(updateConversationsOrder(message.chat_id));
    }

    if (
      getState().Conversations.conversations[message.chat_id].messages.find(
        m => m.message_id === message.message_id
      )
    )
      return;

    let replace;
    if (
      getState().Conversations.conversations[message.chat_id].messages.find(
        m => m.message_short_id === message.message_short_id
      )
    )
      replace = true;

    const currentChatId = getState().App.chatId;

    const statuses = {
      received: true,
    };

    if (
      message.chat_id === currentChatId &&
      getState().App.isVisible !== false
    ) {
      statuses.seen = true;
    }
    if (message.user_id === getState().CurrentUser.user_id) {
      statuses.seen = true;
      statuses.read = true;
    }

    await dispatch($.getForwardUser(message));

    let newMessage = {
      ...message,
      statuses: { ...message.statuses, ...statuses },
      user: getState().Users.users[message.user_id],
      seen_by: message.seen_by ? message.seen_by : [],
      downloaded_by: message.downloaded_by ? message.downloaded_by : [],
      last_edited: message.last_edited || message.created_at,
    };

    if (currentChatId === newMessage.chat_id)
      newMessage = await $.attachFileData(newMessage);

    if (
      getState().Conversations.conversations[message.chat_id].messages.find(
        m => m.message_id === newMessage.message_id
      )
    )
      return;

    if (replace) {
      await dispatch($.replaceMessage(message.chat_id, newMessage));
      dispatch(clearPlaceholderMessageTimeouts([message.message_short_id]));
    } else {
      await dispatch($.addNewMessage(message.chat_id, newMessage));
    }

    if (update) {
      await dispatch(updateConversationsOrder(message.chat_id));
    }

    if (message.user_id !== getState().CurrentUser.user_id) {
      if (getState().App.isVisible === false && update) {
        tone.play();
        let user = getState().Users.users[message.user_id];
        Notification.requestPermission();
        const body =
          message.message_type === 'text'
            ? message.body
            : `${i18next.t('Conversation.has_sent')} ${i18next.t(
                `Conversation.types.${message.message_type}`
              )}`;
        new Notification(getName(user), { body });
      }
      const data = {
        chat_id: message.chat_id,
        message_id: message.message_id,
        statuses,
      };
      return data;
    }
  };
};

export const updateMessageFileData = message => {
  return async dispatch => {
    const updatedMessage = await $.attachFileData(message);
    const {
      chat_id,
      message_id,
      body,
      last_edited,
      file_data,
    } = updatedMessage;
    dispatch(editMessage(chat_id, message_id, body, last_edited, file_data));
  };
};

export const getFileDataForUnknownMessage = message => {
  return async dispatch => {
    const newMessage = await $.attachFileData(message);
    dispatch($.saveUnknownMessage(newMessage));
  };
};

export const uploadFile = (file, name, url, setPercent) => {
  return async dispatch => {
    const form = new FormData();
    form.append(name, file);
    try {
      const response = await putDataToApi(url, form, 'v1', {
        onUploadProgress: e => {
          const percent = Math.round((e.loaded / e.total) * 100);
          setPercent(percent);
        },
      });
      return response || {};
    } catch (e) {
      return e?.response || {};
    }
  };
};

export const setPlaceholderTimeout = (chatId, messageShortId) => {
  return async dispatch => {
    const timeout = setTimeout(() => {
      dispatch(placeholderTimeoutExpired(messageShortId, chatId));
    }, 3000);
    dispatch(setPlaceholderMessageTimeout(messageShortId, timeout));
  };
};

export const placeholderTimeoutExpired = (messageShortId, chatId) => {
  return async (dispatch, getState) => {
    const data = await postDataToApi(`/chat/${chatId}/messages/short-ids`, {
      message_short_ids_list: [messageShortId],
    });
    if (data.count) {
      const message = data.results[0];
      let user = getState().Users.users[message.user_id];
      if (user) {
        message.user = user;
      } else {
        await dispatch(getUser(message.user_id));
        message.user = getState().Users.users[message.user_id];
      }
      dispatch(replaceMessage(message.chat_id, message));
    } else {
      dispatch(markPlaceholderAsError(chatId, messageShortId));
    }
    dispatch(clearPlaceholderMessageTimeouts([messageShortId]));
  };
};

export const checkPlaceholderTimeouts = chatId => {
  return async (dispatch, getState) => {
    const placeholders = getState().Conversations.conversations[
      chatId
    ].messages.filter(m => m.placeholder);
    if (placeholders.length) {
      const errors = [];
      const shortIds = [];
      placeholders.forEach(m => {
        if (m.error) {
          errors.push(m);
        } else {
          shortIds.push(m.message_short_id);
        }
      });
      dispatch(clearErrorPlaceholders(chatId, errors));
      const data = await postDataToApi(`/chat/${chatId}/messages/short-ids/`, {
        message_short_ids_list: shortIds,
      });
      if (data.count) {
        dispatch(replaceMessages(chatId, data.results));
        dispatch(
          clearPlaceholderMessageTimeouts(
            data.results.map(m => m.message_short_id)
          )
        );
      }
    }
  };
};
