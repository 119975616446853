import * as React from 'react';

function IconSmartboard(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#F57C00">
          <path d="M19.736 2H4.264A2.27 2.27 0 002 4.264v15.472A2.27 2.27 0 004.264 22h5.887l.001-.001c.554-1.99-.114-2.829-.114-2.83-.15-.148-1.878-.18-2.675-.186a1.573 1.573 0 01-.845-.25c-.916-.595-.82-1.45-.82-1.45v-1.32h-.001l-.942-.19c-.944-.377 0-1.32 0-1.32.866-.866.627-2.064.467-2.494a6.638 6.638 0 01-.407-1.744c-.404-4.76 4.792-5.904 4.849-5.95l.001-.002c4.308-.614 6.768.7 7.957 1.633a4.675 4.675 0 011.425 1.886c2.402 5.786-2.972 9.312-2.972 9.312-2.107 1.505-1.693 3.969-1.44 4.905l.002.001h5.099A2.27 2.27 0 0022 19.736V4.264A2.27 2.27 0 0019.736 2" />
          <path d="M15.553 10.955h-2.33c-.59 0-1.074-.478-1.074-1.063v-2.35c0-.586.483-1.064 1.074-1.064h2.33c.59 0 1.074.478 1.074 1.063v2.35c0 .586-.483 1.064-1.074 1.064M10.466 16.328H8.162c-.598 0-1.087-.476-1.087-1.057V7.535c0-.582.489-1.057 1.087-1.057h2.304c.597 0 1.086.475 1.086 1.057v7.736c0 .581-.489 1.057-1.086 1.057M15.563 16.328h-2.35a1.062 1.062 0 01-1.064-1.053v-2.37c0-.58.479-1.054 1.064-1.054h2.35c.585 0 1.064.474 1.064 1.054v2.37c0 .58-.479 1.053-1.064 1.053" />
        </g>
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default IconSmartboard;
