import React from 'react';
import { makeStyles } from '@material-ui/styles';

const SvgTyping = props => {
  const classes = makeStyles(theme => ({
    g: { transform: 'translateY(2px)' },
    dot: {
      fill: 'rgba(127, 127, 127, .7)',
      transformOrigin: '50% 50%',
      animation: 'ball-beat 1s 0s infinite ease-in-out',
      '&:nth-child(2)': { animationDelay: '0.1s !important' },
      '&:nth-child(3)': { animationDelay: '0.2s !important' },
    },
    '@global': {
      '@keyframes ball-beat': {
        '0%': { transform: 'translateY(0)' },
        '30%': { transform: 'translateY(-2px)' },
        '40%': { transform: 'translateY(-2px)' },
        '60%': { transform: 'translateY(0)' },
        '100%': { transform: 'translateY(0)' },
      },
    },
  }))();

  return (
    <svg
      id="typing_bubble"
      data-name="typing bubble"
      xmlns="http://www.w3.org/2000/svg"
      width="2em"
      height="1em"
      viewBox="0 0 24 7"
      {...props}
    >
      <g className={classes.g}>
        <circle className={classes.dot} cx="3" cy="3" r="3" />
        <circle className={classes.dot} cx="12" cy="3" r="3" />
        <circle className={classes.dot} cx="21" cy="3" r="3" />
      </g>
    </svg>
  );
};

export default SvgTyping;
