import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageVisibility from 'react-page-visibility';
import moment from 'moment';
import Sidebar from './Sidebar/Sidebar';
import WelcomeBox from './WelcomeBox/WelcomeBox';
import ChatBox from './ChatBox/ChatBox';
import { SnackbarContext } from './ChatBox/SnackbarContext/SnackbarContext';
import {
  updateConversationsOrder,
  setIsVisible,
} from '../../redux/actions/App';
import { onlineContacts, setOnlineStatus } from '../../redux/actions/Users';
import {
  addMessage,
  deleteMessage,
  getConversationDetails,
  editMessage,
  setDraft,
  setTyping,
  removeTyping,
  removeUserConversation,
  removeUserFromConversation,
  addUserIdsToConversation,
  changeMessageSeenBy,
  changeMessageDownloadedBy,
  changeMessageStatus,
  updateConversations,
  editConversation,
} from '../../redux/actions/Conversations';
import { getChatId, getCreatingConversation } from '../../redux/selectors/App';
import { getCurrentUserId } from '../../redux/selectors/CurrentUser';
import useInterval from '../shared/useIntervalHook';
import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ComposeNewMessage from './ChatBox/MessageBoard/Message/MessageActions/ComposeNewMessage/ComposeNewMessage';
import Dialog from '@material-ui/core/Dialog';

const Chat = ({ socket }) => {
  const {
    snackbarOpen,
    handleCloseSnackbar,
    snackbarSeverity,
    snackbarText,
  } = useContext(SnackbarContext);
  const chatId = useSelector(getChatId);
  const currentUserId = useSelector(getCurrentUserId);
  const creatingConversation = useSelector(getCreatingConversation);
  const conversations = useSelector(state => state.Conversations.conversations);
  const dispatch = useDispatch();

  const { status } = creatingConversation;
  const typings = Object.values(conversations)
    .filter(cnv => (cnv.typing || []).length)
    .map(cnv => ({
      chat_id: cnv.chat_id,
      typing: cnv.typing,
    }));

  const [smartboardMsg, setSmartboardMsg] = useState({
    body: '',
    smartboard_link: '',
    link_title: '',
    useLink: false,
  });

  useEffect(() => {
    (async () => {
      if (socket) {
        socket.on('online_contacts', contacts =>
          dispatch(onlineContacts(contacts))
        );
        socket.on('online_status', contact =>
          dispatch(setOnlineStatus(contact))
        );
        socket.emit('connect');

        const addNewMessage = async message => {
          const data = await dispatch(addMessage(message));
          socket.emit('ack', data);
        };
        socket.on('message', addNewMessage);
        socket.on('error', console.error);

        socket.on('new_chat_message_created', async new_chat => {
          const { new_chat_id } = new_chat;
          socket.emit('enter_chat', new_chat_id);
          dispatch(updateConversations());
        });

        socket.on('message_status_change', messages => {
          dispatch(changeMessageStatus(messages));
        });

        socket.on('seen_by', messages => {
          dispatch(changeMessageSeenBy(messages));
        });

        socket.on('downloaded_by', ({ message_id, chat_id, downloaded_by }) => {
          dispatch(
            changeMessageDownloadedBy(chat_id, message_id, downloaded_by)
          );
        });

        socket.on('deleted_message', message => {
          dispatch(deleteMessage(message.chat_id, message.message_id));
        });

        socket.on('edit_message', message => {
          dispatch(
            editMessage(
              message.chat_id,
              message.message_id,
              message.body,
              message.last_edited
            )
          );
        });

        socket.on('created_draft_message', message => {
          dispatch(setDraft(message.chat_id, message));
        });

        socket.on('deleted_draft_message', ({ chat_id }) => {
          dispatch(setDraft(chat_id, {}));
        });

        socket.on('typing', ({ user_id, typing, chat_id }) => {
          if (typing) {
            dispatch(setTyping(chat_id, user_id));
          } else {
            dispatch(removeTyping(chat_id, user_id));
          }
        });

        socket.on('removed_from_chat', chat_id => {
          dispatch(removeUserConversation(chat_id));
        });

        socket.on('users_removed', ({ chat_id, user_ids }) => {
          dispatch(removeUserFromConversation(chat_id, user_ids));
        });

        socket.on('new_users_in_chat', ({ chat_id, user_ids }) => {
          dispatch(addUserIdsToConversation(chat_id, user_ids));
        });

        socket.on('added_to_chat', async chat_id => {
          dispatch(updateConversationsOrder(chat_id));
          dispatch(getConversationDetails(chat_id));
        });

        socket.on('edited_chat', data => {
          dispatch(editConversation(data));
        });

        window.onbeforeunload = () => {
          socket.emit('disconnect');
          socket.close();
        };
      }
    })();
  }, [currentUserId, socket, dispatch]);

  // Check typing every 3 sec
  useInterval(() => {
    typings.forEach(({ chat_id, typing }) => {
      typing.forEach(({ user_id, timestamp }) => {
        const momentTimestamp = moment(timestamp);
        const fourSecAgo = moment().subtract('6', 'seconds');
        if (momentTimestamp.isBefore(fourSecAgo)) {
          dispatch(removeTyping(chat_id, user_id));
        }
      });
    });
  }, 3000);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const body = params.get('body');
    const smartboard_link = params.get('link');
    const link_title = params.get('title');
    if (body && smartboard_link && link_title) {
      try {
        setSmartboardMsg({
          body: atob(body),
          smartboard_link: atob(smartboard_link),
          link_title: atob(link_title),
          useLink: true,
        });
        window.history.pushState({}, null, window.location.origin);
      } catch {
        window.history.pushState({}, null, window.location.origin);
      }
    }
  }, []);

  const handleVisibilityChange = visibility =>
    dispatch(setIsVisible(visibility));

  const classes = makeStyles(theme => {
    const cls = {
      chat: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
          height: `calc(100*var(--vh) - 56px)`,
        },
      },
      sidebar: {
        flex: 1,
        minWidth: 350,
        width: '100%',
      },
      chatBox: {
        flex: 4,
        width: '100%',
      },
    };

    if (chatId || status)
      cls.sidebar[theme.breakpoints.down('sm')] = { display: 'none' };
    else cls.chatBox[theme.breakpoints.down('sm')] = { display: 'none' };

    if (theme.typography.fontSize === 17.5) {
      cls.chat[theme.breakpoints.down('xs')] = {
        height: `calc(100*var(--vh) - 60px)`,
      };
    }

    return cls;
  })();

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <div className={classes.chat}>
        <div className={classes.sidebar}>
          <Sidebar />
        </div>
        <div className={classes.chatBox} data-testid="Chat-render-component">
          {chatId || status ? <ChatBox /> : <WelcomeBox />}
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
            {snackbarText}
          </Alert>
        </Snackbar>
        <Dialog
          open={smartboardMsg.useLink}
          onClose={() => setSmartboardMsg(s => ({ ...s, useLink: false }))}
        >
          <ComposeNewMessage
            handleClose={() =>
              setSmartboardMsg(s => ({ ...s, useLink: false }))
            }
            message={smartboardMsg}
          />
        </Dialog>
      </div>
    </PageVisibility>
  );
};

export default Chat;
