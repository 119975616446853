import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SocketContext } from '../../../../../api/socket';
import { updateChatPhoto } from '../../../../../redux/actions/Conversations';
import { getConversationDetails } from '../../../../../redux/selectors/Conversations';
import { getCurrentUserId } from '../../../../../redux/selectors/CurrentUser';
import { getUser } from '../../../../../redux/selectors/Users';
import FileUpload from '../../FileUpload/FileUpload';
import GeneralInfo from './Panels/GeneralInfo/GeneralInfo';
import ReportDialog from '../ReportDialog/ReportDialog';
import Links from './Panels/Links/Links';
import Media from './Panels/Media/Media';
import Files from './Panels/Files/Files';
import Tabs from '../../../../shared/Tabs/Tabs';
import TabPanel from '../../../../shared/Tabs/TabPanel/TabPanel';
import Dialog from '../../../../shared/Dialog/Dialog';
import DialogTitle from '../../../../shared/Dialog/DialogTitle/DialogTitle';
import Avatar from '../../../../shared/Avatar/Avatar';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReportIcon from '@material-ui/icons/Report';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const GroupInfo = ({ chat_id, handleClose }) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(0);
  const chatDetails = useSelector(getConversationDetails(chat_id));
  const currentUserId = useSelector(getCurrentUserId);
  const users = chatDetails.users.map(user => user.user_id);
  const chatUsers = useSelector(state =>
    users.map(user => getUser(user)(state))
  );
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { title, image, image_signed_url } = chatDetails;
  const [editedTitle, setEditedTitle] = useState(title);
  const owner = currentUserId === chatDetails.creator_id;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = e => {
    e.preventDefault();
    changeGroupName();
  };

  const changeGroupName = e => {
    if (title.trim() !== editedTitle.trim()) {
      socket.emit('edit_chat', { chat_id, title: editedTitle.trim() });
    }
    setEditMode(false);
  };

  const classes = makeStyles(theme => {
    const cls = {
      content: { padding: 0 },
      groupInfoWrapper: {},
      groupInfo: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        '& .MuiTypography-subtitle1': {
          maxWidth: 400,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          [theme.breakpoints.down('xs')]: { maxWidth: 200 },
        },
      },
      groupImage: {
        position: 'relative',
        padding: 0,
        '&:hover': { backgroundColor: 'unset' },
      },
      groupImageOverlay: {
        display: 'none',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, .54)',
        color: theme.palette.getContrastText('rgba(0, 0, 0, .54)'),
      },
      avatar: {
        width: 80,
        height: 80,
        fontSize: '4em',
        [theme.breakpoints.down('xs')]: {
          width: 70,
          height: 70,
          fontSize: '3.5em',
        },
      },
      groupName: {
        marginLeft: theme.spacing(2),
        display: editMode ? 'none' : 'block',
      },
      editGroupName: {
        width: '70%',
        marginLeft: theme.spacing(2),
        display: editMode ? 'block' : 'none',
        '& .MuiInputBase-root': { width: '100%' },
      },
      editNameForm: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      iconBtn: {
        marginLeft: 'auto',
        '&:hover': { backgroundColor: 'unset' },
      },
      actions: {
        marginTop: theme.spacing(1.5),
        padding: theme.spacing(2),
      },
      action: {
        marginTop: theme.spacing(1),
        minHeight: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& > button': {
          border: 'none',
          outline: 'none',
          background: 'transparent',
          marginLeft: theme.spacing(0.5),
          color: 'inherit',
        },
      },
      disabled: {
        cursor: 'not-allowed',
        color: theme.palette.text.disabled,
      },
    };

    if (owner) {
      cls.groupImage['&:hover .overlay'] = { display: 'flex' };
      cls.groupInfoWrapper = {
        cursor: 'pointer',
        '&:hover': { backgroundColor: theme.palette.action.hover },
      };
    }

    return cls;
  })();

  return (
    <>
      <DialogTitle onClose={handleClose}>{t('GroupInfo.title')}</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.groupInfoWrapper}>
          <div className={classes.groupInfo}>
            <FileUpload
              trigger={
                <Button
                  disableRipple
                  disabled={!owner}
                  className={classes.groupImage}
                >
                  <Avatar
                    group
                    large
                    className={classes.avatar}
                    photo={image_signed_url}
                  />
                  <div className={`${classes.groupImageOverlay} overlay`}>
                    <CameraAltIcon fontSize="large" />
                  </div>
                </Button>
              }
              data={{
                name: 'image',
                url: `files/upload/group-image/${image}/`,
                onUpload: url => dispatch(updateChatPhoto(chat_id, url)),
                requiredField: 'url',
                from: 'group-info',
              }}
            />
            {!editMode ? (
              <>
                <Typography variant="subtitle1" className={classes.groupName}>
                  {editedTitle}
                </Typography>
                <IconButton
                  className={classes.iconBtn}
                  onClick={() => setEditMode(true)}
                  disabled={!owner}
                  data-testid="edit-icon"
                >
                  <EditIcon />
                </IconButton>
              </>
            ) : (
              <form
                onSubmit={handleSubmit}
                className={classes.editNameForm}
                data-testid="edit-name-form"
              >
                <TextField
                  className={classes.editGroupName}
                  value={editedTitle}
                  onChange={e => setEditedTitle(e.target.value)}
                  onBlur={changeGroupName}
                  inputRef={input => input && input.focus()}
                  inputProps={{ 'data-testid': 'edit-name-input' }}
                />
                <IconButton className={classes.iconBtn} disabled={!owner}>
                  <DoneIcon />
                </IconButton>
              </form>
            )}
          </div>
        </div>

        <Divider />

        <Tabs value={value} handleChange={handleChange} />
        <TabPanel className={classes.tabPanel} value={value} index={0}>
          <GeneralInfo
            chatUsers={chatUsers}
            chatDetails={chatDetails}
            chatId={chat_id}
            currentUserId={currentUserId}
            closePopup={handleClose}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={1}>
          <Media chatId={chat_id} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={2}>
          <Files chatId={chat_id} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={3}>
          <Links chatId={chat_id} />
        </TabPanel>

        <div className={classes.actions}>
          <Dialog
            trigger={
              <label data-testid="report" className={classes.action}>
                <ReportIcon />
                <button>{t('GroupInfo.report_group')}</button>
              </label>
            }
          >
            {close => <ReportDialog handleClose={close} group />}
          </Dialog>
          <label className={`${classes.action} ${classes.disabled}`}>
            <ExitToAppIcon />
            <button>{t('GroupInfo.exit_group')}</button>
          </label>
        </div>
      </DialogContent>
    </>
  );
};

export default GroupInfo;
