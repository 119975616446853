import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getName } from '../../../../../../utils/userUtils';
import { blockContacts } from '../../../../../../redux/actions/CurrentUser';
import { getAllContacts } from '../../../../../../redux/selectors/Users';
import { SnackbarContext } from '../../../../../Chat/ChatBox/SnackbarContext/SnackbarContext';
import Avatar from '../../../../../shared/Avatar/Avatar';
import DialogTitle from '../../../../../shared/Dialog/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

const BlockUsers = ({ handleClose }) => {
  const [checked, setChecked] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const allContacts = useSelector(getAllContacts);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const contactList = allContacts.filter(c => c.role === 'Student');

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleBlock = async e => {
    e.preventDefault();
    setSubmitting(true);
    await dispatch(blockContacts(checked));
    setSubmitting(false);
    setSnackbarOptions(
      `${checked.length} user${checked.length > 1 ? 's' : ''} ${t(
        'Settings.Privacy.blocked'
      )}`
    );
    setChecked([]);
  };

  const classes = makeStyles(theme => ({
    dialogContent: {
      padding: 0,
      '&:first-child': { padding: 0 },
    },
    list: {
      padding: `${theme.spacing(2)}px 0`,
      maxHeight: 'calc(65 * var(--vh))',
      [theme.breakpoints.down('xs')]: { maxHeight: 'calc(50 * var(--vh))' },
    },
    listItemContent: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(2)}px`,
    },
    info: {
      margin: `${theme.spacing(2)}px 0`,
      paddgin: `0 ${theme.spacing(1)}px`,
      textAlign: 'center',
    },
    actions: { justifyContent: 'flex-start' },
  }))();

  return (
    <>
      <DialogTitle onClose={handleClose}>
        {t('Settings.Privacy.block_users')}
      </DialogTitle>
      <Divider />
      <form onSubmit={handleBlock}>
        <DialogContent className={classes.dialogContent}>
          {contactList.length ? (
            <List className={classes.list}>
              {contactList.map(contact => (
                <ListItem
                  key={contact.user_id}
                  button
                  onClick={() => handleToggle(contact.user_id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(contact.user_id) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <div className={classes.listItemContent}>
                    <Avatar user={contact} />
                    <Typography variant="body1">{getName(contact)}</Typography>
                  </div>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography
              variant="body1"
              color="textSecondary"
              className={classes.info}
            >
              {t('Settings.Privacy.no_contacts')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            disabled={!checked.length || submitting}
          >
            {t('Settings.Privacy.block')}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default BlockUsers;
