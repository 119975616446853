import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllContacts } from '../../../redux/actions/Users';
import { getSearchResultsView } from '../../../redux/selectors/App';
import SidebarHeader from './SidebarHeader/SidebarHeader';
import SearchResults from './SearchResults/SearchResults';
import ConversationsList from './ConversationsList/ConversationsList';
import { makeStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const Sidebar = () => {
  const searchResultsView = useSelector(getSearchResultsView);
  const [showList, setShowList] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      await dispatch(getAllContacts());
      setShowList(true);
    })();
  }, [dispatch]);

  const classes = makeStyles(theme => ({
    sidebar: {
      boxSizing: 'border-box',
      height: '100%',
      display: 'flex',
    },
    content: { width: '100%' },
    label: {
      padding: '.5rem 0',
      textAlign: 'center',
      background: theme.palette.background.default,
    },
  }))();

  return (
    <div className={classes.sidebar} id="sidebar">
      <div className={classes.content}>
        <SidebarHeader />
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.label}
        >
          {searchResultsView
            ? t('Sidebar.search_results')
            : t('Sidebar.conversations')}
        </Typography>
        {searchResultsView ? (
          <SearchResults />
        ) : showList ? (
          <ConversationsList />
        ) : null}
      </div>
      <Divider orientation="vertical" flexItem />
    </div>
  );
};

export default React.memo(Sidebar);
