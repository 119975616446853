import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getMediaResultsFileData,
  loadMoreResults,
  toggleExpanded,
} from '../../../../../redux/actions/Search';
import {
  getActiveSection,
  getFocusedResultIndex,
} from '../../../../../redux/selectors/Search';
import { SnackbarContext } from '../../../ChatBox/SnackbarContext/SnackbarContext';
import Dialog from '../../../../shared/Dialog/Dialog';
import MessageDetails from '../../../ChatBox/MessageBoard/Message/MessageActions/MessageDetails/MessageDetails';
import FileMessage from '../../../ChatBox/MessageBoard/Message/MediaMessage/FileMessage/FileMessage';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';

const MediaResults = ({ data }) => {
  const expanded = useSelector(state => state.Search.results.media.expanded);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const activeSection = useSelector(getActiveSection);
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (data && !data.dataFetched && !data.showSkeleton) {
      dispatch(getMediaResultsFileData(data));
    }
  }, [data, dispatch]);

  const renderResult = message => {
    const { message_type, file_data } = message;
    if (message_type === 'link') return renderLinkResult(message);
    if (!file_data) return null;
    return <FileMessage message={message} fromSearch />;
  };

  const renderLinkResult = message => (
    <div className={classes.link}>
      <div className={classes.icon}>
        <LinkRoundedIcon className={classes.linkIcon} />
      </div>
      <div className={classes.content}>
        <Typography variant="subtitle2" className={classes.title}>
          {message.link_title}
        </Typography>
        <Link
          href={message.body}
          className={classes.linkUrl}
          target="_blank"
          rel="noopener"
        >
          {message.body}
        </Link>
      </div>
    </div>
  );

  const renderSkeleton = () =>
    [...new Array(data.displayedResults.length)].map((item, index) => (
      <div className={classes.skeletonItem} key={index}>
        <Skeleton width={30} height={50} />
        <div className={classes.text}>
          <Skeleton width={150} height={20} />
          <Skeleton width={150} height={10} />
        </div>
      </div>
    ));

  const classes = makeStyles(theme => ({
    details: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      flexDirection: 'column',
    },
    card: {
      width: '100%',
      cursor: 'pointer',
      '&:hover': { background: theme.palette.action.hover },
      '& .MuiCardHeader-root': {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
    focused: {
      '& .MuiCard-root': { background: theme.palette.action.focus },
    },
    linkFocused: { background: theme.palette.action.focus },
    loadMore: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
    skeletonItem: {
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
    },
    text: { marginLeft: theme.spacing(2) },
    link: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    icon: {
      borderRadius: '50%',
      padding: '.4rem',
      background: theme.palette.primary.light,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    linkIcon: {
      fill: theme.palette.getContrastText(theme.palette.text.primary),
    },
    linkUrl: { wordBreak: 'break-all' },
    content: { marginLeft: theme.spacing(2) },
    title: { fontWeight: 'bold' },
  }))();

  if (!data) return null;

  return data.results.length ? (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => dispatch(toggleExpanded('media', !expanded))}
      data-section="media"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('SearchResults.media')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {data.showSkeleton
          ? renderSkeleton()
          : data.displayedResults.map((result, i) => (
              <Dialog
                key={result.message_id}
                className={
                  activeSection === 'media' && focusedResultIndex === i
                    ? result.message_type === 'link'
                      ? classes.linkFocused
                      : classes.focused
                    : ''
                }
                trigger={
                  <div key={result.message_id}>{renderResult(result)}</div>
                }
              >
                {close => (
                  <MessageDetails
                    handleClose={close}
                    message={result}
                    fromSearch
                  />
                )}
              </Dialog>
            ))}
        {(data.count > data.results.length ||
          data.results.length > data.displayedResults.length) && (
          <div className={classes.loadMore}>
            <Button
              onClick={() =>
                dispatch(loadMoreResults(data, 'media', setSnackbarOptions))
              }
              disabled={data.loadingMore}
            >
              {t('SearchResults.load_more')}
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null;
};

export default MediaResults;
