const initialState = {
  conversations: {},
  unknownMessages: {},
  unseenMessages: {},
  placeholderTimeouts: {},
};

export default function reducer(state = initialState, { type, payload }) {
  let oldState = { ...state };
  let messages;
  switch (type) {
    case 'SET_CONVERSATIONS': {
      const newState = {};
      Object.entries(payload).forEach(([key, value]) => {
        const messages =
          oldState.conversations[key] && oldState.conversations[key].messages
            ? oldState.conversations[key].messages
            : value.messages;
        const image_signed_url =
          oldState.conversations[key] &&
          oldState.conversations[key].image_signed_url
            ? oldState.conversations[key].image_signed_url
            : value.image_signed_url;
        newState[key] = {
          ...oldState.conversations[key],
          ...value,
          messages,
          image_signed_url,
        };
      });
      return { ...oldState, conversations: newState };
    }
    case 'ADD_CONVERSATIONS': {
      return {
        ...oldState,
        conversations: {
          ...oldState.conversations,
          ...payload,
        },
      };
    }
    case 'REMOVE_CONVERSATION': {
      const conversationState = { ...oldState.conversations };
      delete conversationState[payload];
      return {
        ...oldState,
        conversations: {
          ...conversationState,
        },
      };
    }
    case 'REMOVE_CONVERSATIONS': {
      const conversationsState = { ...oldState.conversations };
      for (const id of payload) delete conversationsState[id];
      return {
        ...oldState,
        conversations: {
          ...conversationsState,
        },
      };
    }
    case 'ADD_NEW_CONVERSATION': {
      return {
        ...oldState,
        conversations: {
          ...oldState.conversations,
          [payload.chat_id]: { messages: [], draft: {}, ...payload },
        },
      };
    }
    case 'UPDATE_CONVERSATION': {
      return {
        ...oldState,
        conversations: {
          ...oldState.conversations,
          [payload.chat_id]: {
            ...oldState.conversations[payload.chat_id],
            latest_message: payload.latest_message,
          },
        },
      };
    }
    case 'EDIT_CONVERSATION': {
      return {
        ...oldState,
        conversations: {
          ...oldState.conversations,
          [payload.chat_id]: {
            ...oldState.conversations[payload.chat_id],
            title: payload.title,
          },
        },
      };
    }
    case 'SET_CONVERSATION_DETAILS': {
      return {
        ...oldState,
        conversations: {
          ...oldState.conversations,
          [payload.id]: {
            ...oldState.conversations[payload.id],
            draft: {},
            messages: oldState.conversations[payload.id]
              ? [...oldState.conversations[payload.id].messages]
              : [],
            ...payload.details,
          },
        },
      };
    }
    case 'SET_CONVERSATION_IMAGES': {
      const conversations = { ...state.conversations };
      for (const c in conversations) {
        const conversation = { ...conversations[c] };
        const image = conversation.image;
        if (payload[image]) {
          conversation.image_signed_url = payload[image];
          conversations[c] = conversation;
        }
      }
      return {
        ...state,
        conversations,
      };
    }
    case 'REMOVE_USER_FROM_CONVERSATION': {
      let oldUsersIds = state.conversations[payload.chatId].user_ids || [];
      let oldUsers = state.conversations[payload.chatId].users || [];
      let oldUsersSet;

      oldUsersIds = new Set([...oldUsersIds]);
      payload.userIds.forEach(userId => {
        oldUsersIds.delete(userId);
      });

      oldUsersSet = new Set(oldUsers);
      payload.userIds.forEach(userId => {
        const user = oldUsers.find(user => user.user_id === userId);
        oldUsersSet.delete(user);
      });

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            user_ids: [...oldUsersIds],
            users: [...oldUsersSet],
            hasLecturer: [...oldUsersSet].some(u => u.role === 'Teacher'),
          },
        },
      };
    }
    case 'ADD_USERS_TO_CONVERSATION': {
      let olderUsersIds = state.conversations[payload.chatId].user_ids || [];
      let olderUsers = state.conversations[payload.chatId].users || [];
      let olderUsersSet;

      olderUsersIds = new Set([...olderUsersIds]);
      payload.users.forEach(({ user_id }) => {
        olderUsersIds.add(user_id);
      });

      olderUsersSet = new Set(olderUsers);
      payload.users.forEach(user => {
        olderUsersSet.add(user);
      });

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            user_ids: [...olderUsersIds],
            users: [...olderUsersSet],
            hasLecturer: [...olderUsersSet].some(u => u.role === 'Teacher'),
          },
        },
      };
    }
    case 'SET_MESSAGES': {
      const messages = [
        ...state.conversations[payload.id].messages.filter(m => !m.replace),
        ...payload.results,
      ];
      messages.sort(
        (m1, m2) =>
          new Date(m2.created_at).getTime() - new Date(m1.created_at).getTime()
      );
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.id]: {
            ...state.conversations[payload.id],
            messages_count: payload.count,
            messages_links: payload.links,
            messages: [...messages],
            fetching_messages: false,
          },
        },
      };
      // return thatState;
    }
    case 'CLEAR_MESSAGES': {
      const conversation = { ...state.conversations[payload.id] };
      delete conversation.messages_count;
      delete conversation.messages_links;
      conversation.messages = payload.messages;
      conversation.latest_message = payload.latestMessage;
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.id]: conversation,
        },
      };
    }
    case 'ADD_MESSAGE': {
      const messages = [
        payload.message,
        ...state.conversations[payload.id].messages,
      ];
      messages.sort(
        (m1, m2) =>
          new Date(m2.created_at).getTime() - new Date(m1.created_at).getTime()
      );
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.id]: {
            ...state.conversations[payload.id],
            messages: [...messages],
            latest_message: messages[0],
          },
        },
      };
    }
    case 'REPLACE_MESSAGE': {
      const index = state.conversations[payload.id].messages.findIndex(
        m => m.message_short_id === payload.message.message_short_id
      );
      const messages = [...state.conversations[payload.id].messages];
      messages[index] = payload.message;
      messages.sort(
        (m1, m2) =>
          new Date(m2.created_at).getTime() - new Date(m1.created_at).getTime()
      );
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.id]: {
            ...state.conversations[payload.id],
            messages: [...messages],
            latest_message: messages[0],
          },
        },
      };
    }
    case 'REPLACE_MESSAGES': {
      const messages = [...state.conversations[payload.chatId].messages];
      for (const message of payload.messages) {
        const index = messages.findIndex(
          m => m.message_short_id === message.message_short_id
        );
        if (index >= 0) messages[index] = message;
      }
      messages.sort(
        (m1, m2) =>
          new Date(m2.created_at).getTime() - new Date(m1.created_at).getTime()
      );
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            messages: [...messages],
            latest_message: messages[0],
          },
        },
      };
    }
    case 'CHANGE_FETCHING_MESSAGES': {
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.id]: {
            ...state.conversations[payload.id],
            fetching_messages: payload.data,
          },
        },
      };
    }
    case 'CHANGE_MESSAGE_STATUS': {
      const conversations = { ...oldState.conversations };
      const unseenMessages = { ...oldState.unseenMessages };
      for (const message of payload) {
        const chat = { ...conversations[message.chat_id] };
        chat.messages = chat.messages.map(m => {
          if (m.message_id !== message.message_id) return m;
          return {
            ...m,
            statuses: {
              ...m.statuses,
              ...message.statuses,
            },
          };
        });
        conversations[message.chat_id] = chat;
        if (message.statuses.seen && unseenMessages[message.chat_id]) {
          unseenMessages[message.chat_id] = unseenMessages[
            message.chat_id
          ].filter(id => id !== message.message_id);
        }
      }

      return {
        ...oldState,
        conversations,
        unseenMessages,
      };
    }
    case 'CHANGE_MESSAGE_SEEN_BY': {
      const conversations = { ...oldState.conversations };
      const messages = {};
      for (const message of payload) {
        if (messages[message.chat_id])
          messages[message.chat_id][message.message_id] = message.seen_by;
        else
          messages[message.chat_id] = {
            [message.message_id]: message.seen_by,
          };
      }
      for (const chatId in messages) {
        if (!conversations[chatId]) continue;
        const chat = { ...conversations[chatId] };
        chat.messages = chat.messages.map(m => {
          if (!messages[chatId][m.message_id]) return m;
          return {
            ...m,
            seen_by: Array.from(
              new Set([...m.seen_by, ...messages[chatId][m.message_id]])
            ),
          };
        });
        conversations[chatId] = chat;
      }

      return {
        ...oldState,
        conversations,
      };
    }
    case 'CHANGE_MESSAGE_DOWNLOADED_BY': {
      if (!oldState.conversations[payload.chatId]) return state;
      const chat = { ...oldState.conversations[payload.chatId] };
      messages = [...chat.messages];
      const changedMessage = messages.find(
        m => m.message_id === payload.messageId
      );
      if (!changedMessage) return oldState;
      const index = messages.indexOf(changedMessage);
      const downloadedBy = new Set(changedMessage.downloaded_by || []);
      payload.user_ids.forEach(user => downloadedBy.add(user));
      messages[index] = {
        ...changedMessage,
        downloaded_by: [...downloadedBy],
      };
      chat.messages = [...messages];

      return {
        ...oldState,
        conversations: { ...oldState.conversations, [payload.chatId]: chat },
      };
    }
    case 'DELETE_MESSAGE': {
      const chat = { ...oldState.conversations[payload.chatId] };
      messages = [...chat.messages];
      const deletedMessage = messages.find(
        m => m.message_id === payload.messageId
      );
      if (!deletedMessage) return oldState;
      const index = messages.indexOf(deletedMessage);
      messages[index] = {
        ...deletedMessage,
        deleted: true,
        body: '',
      };
      chat.messages = [...messages];
      const latest_message = chat.latest_message;
      if (latest_message && latest_message.message_id === payload.messageId) {
        chat.latest_message = {
          ...latest_message,
          deleted: true,
          body: '',
        };
      }

      return {
        ...oldState,
        conversations: { ...oldState.conversations, [payload.chatId]: chat },
      };
    }
    case 'REPLY_TO_MESSAGE': {
      return {
        ...oldState,
        conversations: {
          ...oldState.conversations,
          [payload.chatId]: {
            ...oldState.conversations[payload.chatId],
            reply_to: payload.messageId,
          },
        },
      };
    }
    case 'EDIT_MESSAGE': {
      const chat = { ...oldState.conversations[payload.chatId] };
      messages = [...chat.messages];
      let editedMessage = messages.find(
        m => m.message_id === payload.messageId
      );
      if (
        !editedMessage ||
        new Date(editedMessage.last_edited) > new Date(payload.last_edited)
      ) {
        return oldState;
      }
      const index = messages.indexOf(editedMessage);
      editedMessage = {
        ...editedMessage,
        last_edited: payload.last_edited,
        body: payload.body,
      };
      if (payload.file_data) editedMessage.file_data = payload.file_data;
      messages[index] = editedMessage;
      chat.messages = [...messages];

      if (
        chat.latest_message &&
        chat.latest_message.message_id === editedMessage.message_id
      ) {
        chat.latest_message = { ...editedMessage };
      }

      // oldState.conversations[payload.chatId] = chat;
      return {
        ...oldState,
        conversations: { ...oldState.conversations, [payload.chatId]: chat },
      };
    }
    case 'SET_DRAFT_MESSAGE': {
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            draft: payload.message,
          },
        },
      };
    }
    case 'SET_TYPING_INDICATOR': {
      let prevTyping = state.conversations[payload.chatId]?.typing || [];
      prevTyping = [...prevTyping];
      const user = prevTyping.find(typing => typing.user_id === payload.userId);
      if (!user) {
        prevTyping.push({
          user_id: payload.userId,
          timestamp: payload.timestamp,
        });
      } else if (new Date(user.timestamp) < new Date(payload.timestamp)) {
        const index = prevTyping.indexOf(user);
        prevTyping[index] = {
          ...user,
          timestamp: payload.timestamp,
        };
      }
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            typing: [...prevTyping],
          },
        },
      };
    }
    case 'REMOVE_TYPING_INDICATOR': {
      const previousTyping = state.conversations[payload.chatId].typing || [];
      const user = previousTyping.find(
        typing => typing.user_id === payload.userId
      );
      let newTyping = new Set(previousTyping);
      newTyping.delete(user);
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            typing: [...newTyping],
          },
        },
      };
    }
    case 'ADD_UNKNOWN_MESSAGE':
      return {
        ...state,
        unknownMessages: {
          ...state.unknownMessages,
          [payload.message_id]: payload,
        },
      };
    case 'SET_UNSEEN_MESSAGES':
      return {
        ...state,
        unseenMessages: payload,
      };
    case 'UPDATE_PHOTO':
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            image_signed_url: payload.url,
          },
        },
      };
    case 'SET_PLACEHOLDER_TIMEOUT':
      return {
        ...state,
        placeholderTimeouts: {
          ...state.placeholderTimeouts,
          [payload.messageShortId]: payload.timeout,
        },
      };
    case 'CLEAR_PLACEHOLDER_TIMEOUTS':
      const timeouts = { ...state.placeholderTimeouts };
      for (const message_short_id of payload) {
        const timeout = timeouts[message_short_id];
        if (timeout) {
          clearTimeout(timeout);
          delete timeouts[message_short_id];
        }
      }
      return {
        ...state,
        placeholderTimeouts: timeouts,
      };
    case 'MARK_AS_ERROR': {
      const messages = state.conversations[payload.chatId].messages.map(m => {
        if (m.message_short_id === payload.messageShortId) {
          return {
            ...m,
            error: true,
          };
        }
        return m;
      });

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            messages,
          },
        },
      };
    }
    case 'CLEAR_ERROR_PLACEHOLDERS': {
      const messages = state.conversations[payload.chatId].messages.filter(
        m =>
          payload.messages.findIndex(
            p => p.message_short_id === m.message_short_id
          ) === -1
      );

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.chatId]: {
            ...state.conversations[payload.chatId],
            messages,
          },
        },
      };
    }
    default:
      return state;
  }
}
