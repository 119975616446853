import React from 'react';

const SvgLoadingpage01 = props => (
  <svg
    id="loading_page-01_svg__Layer_1"
    x={0}
    y={0}
    width="1em"
    height="1em"
    viewBox="0 0 236 113"
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.loading_page-01_svg__st0{fill:none;stroke:#bababa}'}</style>
    <g id="loading_page-01_svg__Group_241" transform="translate(4.711 1.645)">
      <path
        id="loading_page-01_svg__Path_151"
        className="loading_page-01_svg__st0"
        d="M18.8 59l22.4-3.5L62.8 69c.3.2.6.1.8-.2.1-.1.1-.3.1-.4L61 52.3l18.3-2.9c3.2-.5 5.5-3.6 4.9-6.8L78.2 5c-.5-3.2-3.6-5.5-6.8-4.9L10.9 9.7c-3.2.5-5.5 3.6-4.9 6.8L11.9 54c.6 3.3 3.6 5.5 6.9 5-.1 0-.1 0 0 0z"
      />
      <path
        id="loading_page-01_svg__Path_152"
        className="loading_page-01_svg__st0"
        d="M10.9 9.7l-5.9.9c-3.2.5-5.5 3.6-4.9 6.8L6 54.9c.5 3.2 3.6 5.5 6.8 4.9l5.9-.9"
      />
      <circle
        id="loading_page-01_svg__Ellipse_1"
        className="loading_page-01_svg__st0"
        cx={45.2}
        cy={30.1}
        r={4.3}
      />
      <circle
        id="loading_page-01_svg__Ellipse_2"
        className="loading_page-01_svg__st0"
        cx={27.9}
        cy={32.9}
        r={4.3}
      />
      <circle
        id="loading_page-01_svg__Ellipse_3"
        className="loading_page-01_svg__st0"
        cx={61.9}
        cy={27.5}
        r={4.3}
      />
    </g>
    <g
      id="loading_page-01_svg__Group_239"
      transform="translate(145.116 39.732)"
    >
      <path
        id="loading_page-01_svg__Path_153"
        className="loading_page-01_svg__st0"
        d="M62.2 67l-21.5-7.1-23.5 9.8c-.3.1-.6 0-.7-.3-.1-.1-.1-.3 0-.4l5.3-15.4-17.7-5.9C1 46.7-.7 43.3.3 40.2l12-36.1c1-3.1 4.4-4.8 7.5-3.8l58.1 19.3c3.1 1 4.8 4.4 3.8 7.5l-12 36.1c-1 3.1-4.4 4.8-7.5 3.8z"
      />
      <path
        id="loading_page-01_svg__Path_154"
        className="loading_page-01_svg__st0"
        d="M77.9 19.6l5.7 1.9c3.1 1 4.8 4.4 3.8 7.5l-12 36.1c-1 3.1-4.4 4.8-7.5 3.8L62.2 67"
      />
      <path
        id="loading_page-01_svg__Path_155"
        className="loading_page-01_svg__st0"
        d="M30.3 32.6l4.6 8.8c.1.2.3.3.5.2L54 32.2c.2-.1.3-.3.2-.5l-2-4c-.1-.2-.3-.3-.5-.2l-13.8 6.9c-.2.1-.4 0-.5-.2L35 29.9c-.1-.2-.3-.3-.5-.2l-4 2.3c-.2.1-.3.4-.2.6z"
      />
      <circle
        id="loading_page-01_svg__Ellipse_4"
        className="loading_page-01_svg__st0"
        cx={41.7}
        cy={33.9}
        r={16.4}
      />
    </g>
    <path
      id="loading_page-01_svg__Rectangle_514"
      fill="none"
      d="M0 0h236v113H0z"
    />
  </svg>
);

export default SvgLoadingpage01;
