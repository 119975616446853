import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changeChatId,
  updateCreatingConversation,
} from '../../../../../redux/actions/App';
import Dialog from '../../../../shared/Dialog/Dialog';
import Contacts from './Contacts/Contacts';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import Icon from '@mdi/react';
import { mdiPencilBoxOutline } from '@mdi/js';

const Actions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(updateCreatingConversation({ status: true }));
    dispatch(changeChatId(false));
  };

  const classes = makeStyles(theme => ({
    actions: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    icon: {
      marginLeft: theme.spacing(1.25),
      padding: theme.spacing(0.75),
      minWidth: theme.spacing(4.5),
      borderRadius: '50%',
    },
    primary: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': { backgroundColor: theme.palette.primary.light },
    },
    secondary: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
    search: { display: 'flex' },
  }))();

  return (
    <div className={classes.actions}>
      <Dialog
        trigger={
          <Tooltip title={t('ProfileActions.contacts')}>
            <div>
              <IconButton className={`${classes.icon} ${classes.secondary}`}>
                <PermContactCalendarIcon />
              </IconButton>
            </div>
          </Tooltip>
        }
      >
        {close => <Contacts handleClose={close} />}
      </Dialog>
      <Tooltip title={t('ProfileActions.title_new_conversation')}>
        <div>
          <IconButton
            className={`${classes.icon} ${classes.primary}`}
            onClick={handleClick}
          >
            <Icon path={mdiPencilBoxOutline} size={1} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default Actions;
