import React, { useState } from 'react';
import MuiDialog from '@material-ui/core/Dialog';

const Dialog = ({
  children,
  trigger,
  closePopup,
  onBack,
  forwardRef,
  className,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = trigger => {
    if (trigger && trigger.props.onClick) {
      trigger.props.onClick();
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (onBack) onBack();
    setOpen(false);
  };

  const handleClick = () => {
    if (closePopup) closePopup();
  };

  return (
    <div onClick={handleClick} ref={forwardRef} className={className}>
      {React.cloneElement(trigger, { onClick: () => handleOpen(trigger) })}
      <MuiDialog open={open} onClose={handleClose} fullWidth>
        {children instanceof Function ? children(handleClose) : children}
      </MuiDialog>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <Dialog {...props} forwardedRef={ref} />
));
