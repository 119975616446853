import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import newSocket, { SocketProvider } from './api/socket';
import { Auth } from './api/azureAuth';
import { getDataFromApi } from './api/dataFromApi';
import { getCurrentUser } from './redux/actions/CurrentUser';
import { getTOSAccepted } from './redux/selectors/CurrentUser';
import { toggleSearchResultsView } from './redux/actions/App';
import { getSettings } from './redux/selectors/App';
import { setInitSearch } from './redux/actions/Search';
import { SnackbarProvider } from './components/Chat/ChatBox/SnackbarContext/SnackbarContext';
import Header from './components/Header/Header';
import Chat from './components/Chat/Chat';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import theme, { getThemeColors } from './utils/theme';
import TOSModal from './components/TOSModal';
import { ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import TOSRefusedPage from './components/TOSRefusedPage';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(false);
  const settings = useSelector(getSettings);
  const tosAccepted = useSelector(getTOSAccepted);
  const [tosRefused, setTosRefused] = useState(false);
  const [tosResolved, setTosResolved] = useState(false);
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const muiTheme = useMemo(() => createTheme(theme), [settings]);

  useEffect(() => {
    (async () => {
      try {
        let token;
        let i = 0;
        while (!(token && token.match(/.+\..+\..+/)) && i < 3) {
          token = await Auth.getToken();
          i++;
        }
        if (!(token && token.match(/.+\..+\..+/))) {
          setError(true);
          return;
        }
        await getDataFromApi('auth/register/azure-ad/');
        await dispatch(getCurrentUser());
        if (tosAccepted && tosResolved) {
          const socket = await newSocket();
          setSocket(socket);
        }
        if (!tosResolved) setTosResolved(true);
        setIsLoading(false);
      } catch {}
    })();
  }, [dispatch, tosResolved, tosAccepted]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchTerm = urlParams.get('search_term');
    if (searchTerm) {
      try {
        const toAscii = atob(searchTerm);
        dispatch(toggleSearchResultsView(true));
        dispatch(setInitSearch(toAscii));
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  function createTheme(theme) {
    if (settings) {
      const fontFamily = `${settings.communicator.font_family}, '${
        settings.communicator.font_family === 'Times New Roman'
          ? 'serif'
          : 'sans-serif'
      }'`;
      const darkMode = settings.common.color_theme;

      theme.typography.fontSize = settings.communicator.font_size * 14;
      theme.typography.fontFamily = fontFamily;
      theme.palette.type = darkMode === 'Classic' ? 'light' : 'dark';

      if (settings.common.background_color === 'default') {
        theme.palette.background = {};
      } else {
        theme.palette.background = getThemeColors(
          settings.common.background_color
        );
      }
    }

    const createdTheme = createMuiTheme(theme);
    if (!createdTheme.palette.background) {
      createdTheme.palette.background = {};
    }
    return createdTheme;
  }

  if (!tosResolved) return null;

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      {!tosAccepted ? (
        tosRefused ? (
          <TOSRefusedPage />
        ) : (
          <TOSModal refuse={() => setTosRefused(true)} />
        )
      ) : (
        <SocketProvider value={socket}>
          <SnackbarProvider>
            <Header />
            <div
              id="app"
              data-testid="app"
              style={{ backgroundColor: muiTheme.palette.background.paper }}
            >
              {isLoading ? (
                <LoadingScreen error={error} />
              ) : (
                <Chat socket={socket} />
              )}
            </div>
          </SnackbarProvider>
        </SocketProvider>
      )}
    </ThemeProvider>
  );
};

export default App;
