import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateMessageFileData } from '../../../../../../../redux/actions/Conversations';
import FileMessage from '../FileMessage/FileMessage';
import InfoSubheader from '../../../../../../shared/InfoSubheader/InfoSubheader';

const VideoMessage = ({ message }) => {
  const [renderAsFile, setRenderAsFile] = useState(false);
  const dispatch = useDispatch();
  const errorCounter = useRef(0);
  const videoEl = useRef(null);

  const { file_data } = message;
  const { content_type, media_file_url } = file_data;

  const handleError = async e => {
    if (errorCounter.current >= 3) {
      setRenderAsFile(true);
      return;
    }
    errorCounter.current++;
    await dispatch(updateMessageFileData(message));
    if (videoEl.current) videoEl.current.load();
  };

  if (renderAsFile) return <FileMessage message={message} />;

  return (
    <div>
      <video
        controls
        preload="metadata"
        width="100%"
        style={{ maxWidth: 400 }}
        onError={handleError}
        ref={videoEl}
      >
        <source src={media_file_url} type={content_type}></source>
        Sorry, your browser doesn't support embedded videos.
      </video>
      <InfoSubheader message={message} />
    </div>
  );
};

export default VideoMessage;
