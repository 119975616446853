import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getName } from '../../../../utils/userUtils';
import { getChatId } from '../../../../redux/selectors/App';
import { getAllUsers } from '../../../../redux/selectors/Users';
import { getConversationDetails } from '../../../../redux/selectors/Conversations';
import { makeStyles } from '@material-ui/styles';
import TypingIcon from '../../../../assets/icons/Typing';
import { getCurrentUser } from '../../../../redux/selectors/CurrentUser';

const TypingIndicator = () => {
  const chatId = useSelector(getChatId);
  const conversationDetails = useSelector(getConversationDetails(chatId));
  const users = useSelector(getAllUsers);
  const currentUser = useSelector(getCurrentUser);
  const typing = (conversationDetails.typing || [])
    .filter(t => t.user_id !== currentUser.user_id)
    .map(t => t.user_id);
  const typingUsers = Object.entries(users)
    .filter(([user_id, user]) => typing.includes(user_id))
    .map(([user_id, user]) => user);
  const { t } = useTranslation();

  const classes = makeStyles(theme => ({
    typing: {
      height: '1em',
      color: theme.palette.text.secondary,
      fontSize: '.85em',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(8),
      '& > span': { marginLeft: theme.spacing(1) },
    },
    ml: { marginLeft: theme.spacing(0.5) },
  }))();

  return (
    <div className={classes.typing}>
      {typing.length ? (
        <>
          <TypingIcon />
          <span>
            {typingUsers.map(getName).join(', ')}
            <span className={classes.ml}>{`${t('TypingIndicator.typing', {
              count: typing.length,
            })}`}</span>
          </span>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default TypingIndicator;
