import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getChatsImages,
  loadMoreResults,
  toggleExpanded,
} from '../../../../../redux/actions/Search';
import { SnackbarContext } from '../../../ChatBox/SnackbarContext/SnackbarContext';
import ChatResult from './ChatResult/ChatResult';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ChatsResults = ({ data }) => {
  const expanded = useSelector(state => state.Search.results.chats.expanded);
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (data && !data.dataFetched) {
      dispatch(getChatsImages(data));
    }
  }, [data, dispatch]);

  const classes = makeStyles(theme => ({
    details: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      flexDirection: 'column',
    },
    loadMore: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
  }))();

  if (!data) return null;

  return data.results.length ? (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => dispatch(toggleExpanded('chats', !expanded))}
      data-section="chats"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('SearchResults.chats')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {data.displayedResults.map(chat => (
          <div key={chat.chat_id}>
            <ChatResult conversation={chat} />
          </div>
        ))}
        {(data.count > data.results.length ||
          data.results.length > data.displayedResults.length) && (
          <div className={classes.loadMore}>
            <Button
              onClick={() =>
                dispatch(loadMoreResults(data, 'chats', setSnackbarOptions))
              }
              disabled={data.loadingMore}
            >
              {t('SearchResults.load_more')}
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null;
};

export default ChatsResults;
