import React, { useContext } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  getFileInfo,
  downloadFile,
} from '../../../../../../../utils/fileUtils';
import { SocketContext } from '../../../../../../../api/socket';
import DefaultIcon from '../../../../../../../assets/icons/Default';
import ExcelIcon from '../../../../../../../assets/icons/Excel';
import PptIcon from '../../../../../../../assets/icons/Ppt';
import WordIcon from '../../../../../../../assets/icons/Word';
import PdfIcon from '../../../../../../../assets/icons/Pdf';
import Moment from '../../../../../../shared/Moment';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

const FileMessage = ({ message, fromSearch }) => {
  const socket = useContext(SocketContext);
  const { t } = useTranslation();

  const { file_data } = message;
  const { content_type, original_name, expires_at } = file_data;

  const expired = moment().isAfter(expires_at);
  const expires = moment(expires_at);
  const isWithinOneDay =
    moment()
      .add(1, 'day')
      .isAfter(expires) && !expired;

  const findExtension = () => {
    if (!original_name) return '';
    const splitName = original_name.split('.');
    const extension = splitName[splitName.length - 1];
    return extension.length < 4 ? extension.toUpperCase() : '';
  };

  const renderAvatar = () =>
    message.message_type === 'image' && !expired ? (
      <Avatar variant="square" src={message.file_data?.media_file_thumb_url} />
    ) : (
      <Avatar variant="square" className={classes.avatar}>
        {renderIcon()}
      </Avatar>
    );

  const renderSubheader = () => {
    const { expiration, sizeText } = getFileInfo(file_data);
    return (
      <span
        data-testid={isWithinOneDay ? 'StyleOneDay' : 'StyleMoreThenOneDay'}
      >
        <span data-testid="size">{sizeText}</span>
        {message.user?.role === 'Student' && (
          <span data-testid="expiredSubheader" className={classes.expiresIn}>
            {expired ? t('File.expired') : `${t('File.expires')} ${expiration}`}
          </span>
        )}
      </span>
    );
  };

  const renderIcon = () => {
    switch (content_type) {
      case 'application/pdf':
        return <PdfIcon />;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <WordIcon />;
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return <PptIcon />;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <ExcelIcon />;
      default:
        const extension = findExtension();
        return <DefaultIcon extension={extension} />;
    }
  };

  const classes = makeStyles(theme => ({
    fileMessage: {
      boxShadow: 'none',
      cursor: expired && !fromSearch ? 'not-allowed' : 'pointer',
      opacity: expired && '.5',
      '&:hover': {
        background: fromSearch ? theme.palette.action.hover : 'unset',
      },
    },
    header: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      '& .MuiCardHeader-title': {
        [theme.breakpoints.down('xs')]: { marginTop: theme.spacing(1) },
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    avatar: { backgroundColor: 'transparent' },
    expiresIn: { marginLeft: theme.spacing(1) },
    link: {
      border: 'unset',
      outline: 'unset',
      boxShadow: 'unset',
      backgroundColor: 'unset',
      textAlign: 'unset',
      color: 'unset',
      font: 'unset',
      padding: 'unset',
      width: '100%',
    },
  }))();

  return (
    <button
      className={classes.link}
      data-testid="link"
      onClick={() => !fromSearch && downloadFile(expired, message, socket)}
    >
      <Card className={classes.fileMessage}>
        <CardHeader
          className={classes.header}
          avatar={renderAvatar()}
          title={original_name}
          subheader={renderSubheader()}
          action={
            fromSearch ? (
              <Typography variant="caption" color="textSecondary">
                <Moment>{message.created_at}</Moment>
              </Typography>
            ) : null
          }
        />
      </Card>
    </button>
  );
};

export default FileMessage;
