export default {
  palette: {
    type: 'light',
    primary: {
      light: '#66c4fe',
      main: '#2294cb',
      dark: '#00669a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9be7ff',
      main: '#64b5f6',
      dark: '#2286c3',
      contrastText: '#fff',
    },
  },
  typography: {
    fontSize: 14,
  },
};

const colorsMap = {
  green: {
    default: '#C8CDA0',
    paper: '#CEDEA2',
  },
  pink: {
    default: '#E7C3AB',
    paper: '#EEDBCE',
  },
  blue: {
    default: '#D3D1DC',
    paper: '#DBD7E4',
  },
};
export const getThemeColors = color => colorsMap[color];
