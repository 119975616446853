import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  decrementIndex,
  incrementIndex,
  resetSearch,
  setIndex,
  setInitSearch,
  setInitValueApplied,
  startSearch,
} from '../../../../../redux/actions/Search';
import {
  getActiveSection,
  getFocusedResultIndex,
  getInitSearch,
  getInitValueApplied,
} from '../../../../../redux/selectors/Search';
import { toggleSearchResultsView } from '../../../../../redux/actions/App';
import {
  getSearchResultsView,
  getSettings,
} from '../../../../../redux/selectors/App';
import { SnackbarContext } from '../../../ChatBox/SnackbarContext/SnackbarContext';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const SearchBar = () => {
  const [value, setValue] = useState('');
  const initValue = useSelector(getInitSearch);
  const initValueApplied = useSelector(getInitValueApplied);
  const searchResultsView = useSelector(getSearchResultsView);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const activeSection = useSelector(getActiveSection);
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const debounceTimeout = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const settings = useSelector(getSettings);

  const initSearch = useCallback(() => {
    const shouldStartSearch = value.trim().length >= 3;
    if (!shouldStartSearch) {
      dispatch(toggleSearchResultsView(shouldStartSearch));
      dispatch(resetSearch());
    } else {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        dispatch(toggleSearchResultsView(shouldStartSearch));
        dispatch(startSearch(value.trim(), setSnackbarOptions));
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (value === '' && !initValueApplied && initValue) {
      setValue(initValue || '');
      dispatch(setInitSearch(''));
      dispatch(setInitValueApplied(true));
    }
    initSearch();
  }, [value, dispatch, initSearch, initValue, initValueApplied]);

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleClear = () => {
    setValue('');
    if (inputRef.current !== null) inputRef.current.focus();
  };

  const handleKeyDown = e => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (searchResultsView) dispatch(decrementIndex());
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (searchResultsView) dispatch(incrementIndex());
      else if (value.length >= 3) initSearch();
    } else if (e.key === 'Escape') {
      dispatch(toggleSearchResultsView(false));
    } else if (e.key === 'Enter') {
      if (focusedResultIndex >= 0 && activeSection) {
        document
          .querySelector(
            `[data-section=${activeSection}] .MuiAccordionDetails-root > div:nth-of-type(${focusedResultIndex +
              1}) > div`
          )
          .click();
      } else {
        initSearch();
      }
    }
  };

  const classes = makeStyles(theme => {
    const cls = {
      search: {
        width: '100%',
        height: 36,
        padding: theme.spacing(1),
        backgroundColor:
          settings.common.background_color === 'default'
            ? theme.palette.action.hover
            : theme.palette.background.default,
        borderRadius: 18,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        '& .MuiInputBase-root': { width: '100%' },
        '& .MuiInputBase-input': { padding: 0 },
      },
      searchIcon: {
        width: '.75em',
        height: '.75em',
        fill: theme.palette.text.secondary,
        marginLeft: theme.spacing(0.75),
      },
      closeIcon: {
        width: '.75em',
        height: '.75em',
        fill: theme.palette.text.secondary,
        cursor: 'pointer',
      },
    };

    if (theme.typography.fontSize === 17.5) {
      cls.search.height = 40;
    }

    return cls;
  })();

  return (
    <FormControl className={classes.search}>
      <Input
        disableUnderline={true}
        placeholder={t('SearchBar.search')}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        }
        endAdornment={
          value.length ? (
            <InputAdornment position="end">
              <CloseIcon className={classes.closeIcon} onClick={handleClear} />
            </InputAdornment>
          ) : null
        }
        value={value}
        ref={inputRef}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => dispatch(setIndex(-1))}
      />
    </FormControl>
  );
};

export default SearchBar;
