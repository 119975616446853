import { clearChatMessages, replyToMessage } from './Conversations';
import { getDataFromApi, putDataToApi } from '../../api/dataFromApi';
import i18n from '../../i18n';
import moment from 'moment';

export const setIsVisible = isVisible => {
  return {
    type: 'SET_VISIBILITY',
    payload: isVisible,
  };
};

export const changeChatId = chat_id => {
  return {
    type: 'CHANGE_CHAT',
    payload: chat_id,
  };
};

export const toggleSearchResultsView = view => {
  return {
    type: 'TOGGLE_SEARCH_RESULTS',
    payload: view,
  };
};

export const updateCreatingConversation = payload => ({
  type: 'UPDATE_CREATING_CONVERSATION',
  payload,
});

export const changeConversationsOrder = order => {
  return {
    type: 'CHANGE_CONVERSATIONS_ORDER',
    payload: order,
  };
};

export const toggleChatView = toggleView => {
  return {
    type: 'CHANGE_CHAT_VIEW',
    payload: toggleView,
  };
};

export const setChatWidth = chatWidth => {
  return {
    type: 'CHANGE_CHAT_WIDTH',
    payload: chatWidth,
  };
};

export const setSettings = settings => {
  return {
    type: 'SET_SETTINGS',
    payload: settings,
  };
};

export const setSettingsPage = settingsPage => {
  return {
    type: 'SET_SETTINGS_PAGE',
    payload: settingsPage,
  };
};

export const conversationsFetched = () => {
  return {
    type: 'CONVERSATIONS_FETCHED',
    payload: true,
  };
};

export const fetchingConversations = payload => {
  return {
    type: 'FETCHING_CONVERSATIONS',
    payload,
  };
};

export const toggleEdit = (messageId, edit) => {
  return {
    type: 'TOGGLE_EDIT',
    payload: {
      messageId,
      edit,
    },
  };
};

export const resetCreatingConversation = () => {
  return {
    type: 'RESET_CREATING_CONVERSATION',
  };
};

export const changeChat = chat_id => {
  return async (dispatch, getState) => {
    const oldChatId = getState().App.chatId;
    if (oldChatId) dispatch(replyToMessage(oldChatId, false));
    await dispatch(await clearChatMessages(chat_id));
    dispatch(changeChatId(chat_id));
  };
};

export const updateConversationsOrder = chatId => {
  return async (dispatch, getState) => {
    const conversationsOrder = new Set(getState().App.conversationsOrder);
    conversationsOrder.delete(chatId);

    await dispatch(changeConversationsOrder([chatId, ...conversationsOrder]));
  };
};

export const getInitSettings = () => {
  return async dispatch => {
    let apiSettings = await getDataFromApi(
      'auth/user/settings/?fields=common&fields=communicator'
    );
    Object.entries(apiSettings).forEach(([key, value]) => {
      if (key !== 'communicator' && key !== 'common') return;
      dispatch(updateSettings(key, value, true));
    });
  };
};

export const updateSettings = (settingsType, value, init) => {
  return async (dispatch, getState) => {
    const settings = getState().App.settings;
    const newSettings = {
      ...settings,
      [settingsType]: {
        ...settings[settingsType],
        ...value,
      },
    };

    if (i18n.language !== newSettings.common.language) {
      i18n.changeLanguage(newSettings.common.language);
    }

    if (moment.locale() !== newSettings.common.language) {
      moment.locale(newSettings.common.language);
    }

    await dispatch(setSettings(newSettings));
    if (!init) await putDataToApi('auth/user/settings/', newSettings);
  };
};
