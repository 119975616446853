import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataFromApi } from '../../../../../../../api/dataFromApi';
import Link from './Link/Link';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

const Links = ({ chatId }) => {
  const [links, setLinks] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const data = await getDataFromApi(
          `chat/${chatId}/messages/link/`,
          'v1'
        );
        setLinks(data);
      } catch {
        setLinks([]);
      }
      setDataFetched(true);
    })();
  }, [chatId]);

  const renderSkeleton = () => (
    <div className={classes.links}>
      {[...new Array(5)].map((item, index) => (
        <div key={index} className={classes.link}>
          <Skeleton width="150px" height="30px" />
          <Skeleton width="225px" height="25px" />
          <Skeleton width="225px" height="25px" />
        </div>
      ))}
    </div>
  );

  const classes = makeStyles(theme => ({
    links: { padding: `0 ${theme.spacing(2)}px` },
    noFiles: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(4),
    },
  }))();

  return dataFetched ? (
    links.length ? (
      <div className={classes.links}>
        {links.map(link => (
          <Link key={link.message_id} data={link} />
        ))}
      </div>
    ) : (
      <Typography variant="subtitle1" className={classes.noFiles}>
        {t('ContactInfo.no_shared_links')}
      </Typography>
    )
  ) : (
    renderSkeleton()
  );
};

export default Links;
