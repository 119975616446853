import React from 'react';
import { usernameText } from '../../../../../../utils/userUtils';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../../../../../redux/selectors/CurrentUser';
import ContactInfo from '../../ContactInfo/ContactInfo';
import Profile from '../../../../../Header/ProfileActions/Profile/Profile';
import Avatar from '../../../../../shared/Avatar/Avatar';
import Dialog from '../../../../../shared/Dialog/Dialog';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const GroupMembers = ({
  currentUserId,
  creatorId,
  users,
  remove,
  closePopup,
}) => {
  const currentUser = useSelector(getCurrentUser);

  const isCurrentUser = ({ user_id }) => currentUser.user_id === user_id;
  const primaryTypographyProps = user =>
    user.role === 'Teacher' ? { color: 'primary' } : { color: 'textPrimary' };

  const classes = makeStyles(theme => ({
    list: { width: '100%' },
  }))();

  return (
    <List className={classes.list}>
      {users.map(user => {
        if (isCurrentUser(user)) user = currentUser;
        return (
          <Dialog
            key={user.user_id}
            trigger={
              <ListItem key={user.user_id} button>
                <ListItemAvatar>
                  <Avatar user={user} />
                </ListItemAvatar>
                <ListItemText
                  primary={usernameText(user)}
                  primaryTypographyProps={primaryTypographyProps(user)}
                />
                {currentUserId === creatorId && currentUserId !== user.user_id && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => remove(user.user_id)()}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            }
          >
            {close =>
              isCurrentUser(user) ? (
                <Profile user={user} handleClose={close} />
              ) : (
                <ContactInfo
                  user_id={user.user_id}
                  handleClose={close}
                  closePopup={closePopup}
                />
              )
            }
          </Dialog>
        );
      })}
    </List>
  );
};
export default GroupMembers;
