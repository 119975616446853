import axios from 'axios';
import { Auth } from './azureAuth';

let token = '';
export const defaultVersion = process.env.REACT_APP_DEV_API_VERSION;
export const URL = process.env.REACT_APP_DEV_API_URL;

export const buildURL = version => `${URL}${version || defaultVersion}/`;

export const getDataFromApi = async (query, version) => {
  token = await Auth.getToken();
  query = checkQuery(query);
  const headers = { Authorization: 'Bearer ' + token };
  try {
    const response = await axios.get(`${buildURL(version)}${query}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDataToApi = async (query, data, version) => {
  token = await Auth.getToken();
  query = checkQuery(query);
  const headers = { Authorization: 'Bearer ' + token };
  try {
    const response = await axios.post(`${buildURL(version)}${query}`, data, {
      headers,
    });
    //console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const patchDataToApi = async (query, data, version) => {
  token = await Auth.getToken();
  query = checkQuery(query);
  const headers = { Authorization: 'Bearer ' + token };
  try {
    const response = await axios.patch(`${buildURL(version)}${query}`, data, {
      headers,
    });
    //console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const putDataToApi = async (query, data, version, options) => {
  token = await Auth.getToken();
  query = checkQuery(query);
  const headers = { Authorization: 'Bearer ' + token };
  try {
    const response = await axios.put(`${buildURL(version)}${query}`, data, {
      headers,
      ...options,
    });
    //console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteDataFromApi = async (query, version) => {
  token = await Auth.getToken();
  query = checkQuery(query);
  const headers = { Authorization: 'Bearer ' + token };
  try {
    const response = await axios.delete(`${buildURL(version)}${query}`, {
      headers,
    });
    //console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getToken = async (query, data, version) => {
  try {
    const response = await axios.post(`${buildURL(version)}${query}`, data);
    //console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkQuery = query => {
  return (query =
    query.charAt(query.length - 1) === '/'
      ? query
      : query.includes('?')
      ? query
      : query + '/');
};
