import * as React from 'react';

function IconLearningApp(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)">
          <circle cx={10} cy={10} r={10} />
          <g fillRule="nonzero">
            <path
              d="M17.427 7.314C17.095 3.52 14.034.57 10.285.428c-3.75-.141-7.017 2.57-7.626 6.33-.147.982-.383 1.949-.706 2.887l-.122.353a.983.983 0 00.104.89c.158.23.417.366.694.365h.091v2.869c0 .853.682 1.545 1.523 1.545H6.13a.876.876 0 01.629.26c.167.169.26.398.26.637v1.583c0 .488.39.884.871.884h5.347c.481 0 .871-.396.871-.884v-3.71c0-.09.047-.174.122-.223 2.21-1.55 3.43-4.184 3.197-6.9z"
              fill="#5D6DA4"
            />
            <path
              d="M10.034 1.954c-3.13.007-5.754 2.404-6.09 5.564a.536.536 0 00.47.581h11.198a.534.534 0 00.524-.531v-.05c-.336-3.164-2.967-5.564-6.102-5.564z"
              fill="#FFF"
            />
            <path
              d="M12.628 7.481V3.153a5.332 5.332 0 00-2.314-.618V7.48h2.314z"
              fill="#98658C"
            />
            <path
              d="M15.399 7.03a5.512 5.512 0 00-2.21-3.518v3.97h1.826a.387.387 0 00.384-.39V7.03z"
              fill="#CB3D50"
            />
            <path
              d="M9.803 7.481V2.535c-.808.025-1.6.236-2.315.618v4.328h2.315z"
              fill="#5D6DA4"
            />
            <path
              d="M6.953 3.506A5.517 5.517 0 004.712 7.03c-.018.1.005.205.063.288.059.084.147.14.247.157h1.93v-3.97z"
              fill="#5C95D2"
            />
          </g>
        </g>
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default IconLearningApp;
