import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataFromApi } from '../../../../api/dataFromApi';
import AchievementIcon from '../../../../assets/icons/OverallAchievementIcon';
import DialogTitle from '../../../shared/Dialog/DialogTitle/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '../../../shared/Avatar/Avatar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import StarsIcon from '@material-ui/icons/Stars';
import { usernameColor, usernameText } from '../../../../utils/userUtils';

const Profile = ({ handleClose, user }) => {
  const [achievedBadges, setAchievedBadges] = useState([]);
  const [openBadges, setOpenBadges] = useState([]);
  const { t } = useTranslation();

  const { bio_info } = user;

  const getBadges = async () => {
    const achieved = [];
    const badges = await getDataFromApi(`achievements/list/`, 'v1');
    if (badges) {
      Object.entries(badges).map(badge => {
        if (badge[1].is_achieved) {
          achieved.push(badge);
        }
        return achieved;
      });
      setAchievedBadges(achieved);
    }

    const openBadges = await getDataFromApi('badges/list/', 'v1');
    if (openBadges) setOpenBadges(openBadges);
  };

  useEffect(() => {
    getBadges();
    // eslint-disable-next-line
  }, []);

  const classes = makeStyles(theme => ({
    profile: { padding: 0 },
    account: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      '& .MuiTypography-h5': { marginTop: theme.spacing(3) },
    },
    achievements: {
      marginTop: theme.spacing(1),
      display: 'flex',
      gap: '16px',
      '& div': {
        display: 'flex',
        alignItems: 'center',
        '& span': { marginLeft: theme.spacing(0.5) },
      },
    },
    about: {
      padding: theme.spacing(2),
      '& .MuiTypography-subtitle1': { fontWeight: 'bold' },
      '& .MuiTypography-body2': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
        wordBreak: 'break-all',
      },
    },
    avatar: {
      height: '8rem',
      width: '8rem',
      fontSize: '2em',
      marginTop: theme.spacing(1),
    },
    star: {
      fill: '#FBC02D',
      fontSize: '1.1rem',
    },
  }))();

  return (
    <>
      <DialogTitle id="form-dialog-title" onClose={handleClose}>
        {t('ProfileActions.profile')}
      </DialogTitle>
      <DialogContent className={classes.profile}>
        <div className={classes.account}>
          <Avatar user={user} className={classes.avatar} />
          <Typography variant="h5" color={usernameColor(user)}>
            {usernameText(user)}
          </Typography>
          <div className={classes.achievements}>
            <div>
              <StarsIcon className={classes.star} />
              <span>{openBadges.length}</span>
            </div>
            <div>
              <AchievementIcon />
              <span>{achievedBadges.length}</span>
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.about}>
          <Typography variant="subtitle1">
            {t('ContactInfo.about_me')}
          </Typography>
          <Typography variant="body2">{bio_info}</Typography>
        </div>
      </DialogContent>
    </>
  );
};

export default Profile;
