import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from './SearchBar/SearchBar';
import Actions from './Actions/Actions';

const SidebarHeader = () => {
  const classes = makeStyles(theme => {
    const cls = {
      sidebarHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: 60,
        padding: theme.spacing(1.5),
      },
    };

    if (theme.typography.fontSize === 17.5) {
      cls.sidebarHeader.maxHeight = 70;
      cls.sidebarHeader.height = 70;
    }

    return cls;
  })();

  return (
    <div className={classes.sidebarHeader}>
      <SearchBar />
      <Actions />
    </div>
  );
};

export default SidebarHeader;
