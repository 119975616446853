import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getUsers,
  loadMoreResults,
  toggleExpanded,
} from '../../../../../redux/actions/Search';
import {
  getActiveSection,
  getFocusedResultIndex,
} from '../../../../../redux/selectors/Search';
import { getCurrentUser } from '../../../../../redux/selectors/CurrentUser';
import { SnackbarContext } from '../../../ChatBox/SnackbarContext/SnackbarContext';
import { usernameText, usernameColor } from '../../../../../utils/userUtils';
import Avatar from '../../../../shared/Avatar/Avatar';
import Dialog from '../../../../shared/Dialog/Dialog';
import ContactInfo from '../../../ChatBox/ChatHeader/ContactInfo/ContactInfo';
import Profile from '../../../../Header/ProfileActions/Profile/Profile';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ContactsResults = ({ data }) => {
  const expanded = useSelector(state => state.Search.results.contacts.expanded);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const activeSection = useSelector(getActiveSection);
  const currentUser = useSelector(getCurrentUser);
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (data && !data.dataFetched) {
      dispatch(getUsers(data));
    }
  }, [data.results, dispatch]);

  const classes = makeStyles(theme => ({
    details: {
      padding: 0,
      paddingBottom: theme.spacing(1),
      flexDirection: 'column',
    },
    result: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      cursor: 'pointer',
      '&:hover': { background: theme.palette.action.hover },
    },
    focused: { background: theme.palette.action.focus },
    loadMore: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
  }))();

  if (!data) return null;

  return data.results.length ? (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => dispatch(toggleExpanded('contacts', !expanded))}
      data-section="contacts"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{t('SearchResults.contacts')}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {data.displayedResults.map((result, i) => (
          <Dialog
            key={result.user_id}
            className={
              activeSection === 'contacts' && focusedResultIndex === i
                ? classes.focused
                : ''
            }
            trigger={
              <div key={result.user_id} className={classes.result}>
                <Avatar user={result} />
                <Typography variant="body1" color={usernameColor(result)}>
                  {usernameText(result)}
                </Typography>
              </div>
            }
          >
            {close =>
              currentUser.user_id === result.user_id ? (
                <Profile user={result} handleClose={close} />
              ) : (
                <ContactInfo user_id={result.user_id} handleClose={close} />
              )
            }
          </Dialog>
        ))}
        {(data.count > data.results.length ||
          data.results.length > data.displayedResults.length) && (
          <div className={classes.loadMore}>
            <Button
              onClick={() =>
                dispatch(loadMoreResults(data, 'contacts', setSnackbarOptions))
              }
              disabled={data.loadingMore}
            >
              {t('SearchResults.load_more')}
            </Button>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null;
};

export default ContactsResults;
