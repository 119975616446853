import * as React from 'react';

function IconILP(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 1)">
          <path
            fill="#FFF003"
            d="M.98 5.955l9.022-5.36 9.513 5.36-4.896 2.647-9.513-.265z"
          />
          <path
            fill="#FF0262"
            d="M19.515 5.955v10.454l-9.303 4.962V16.41l4.267-2.514.14-5.293z"
          />
          <path
            fill="#00E9DE"
            d="M.49 6.021v10.454l9.303 4.963v-4.963l-4.267-2.514-.14-5.293z"
          />
          <path
            fill="#FFF"
            d="M10.212 11.38l4.267 2.515-4.477 2.448-4.196-2.448z"
          />
          <path
            fill="#0175D6"
            d="M5.316 8.866l4.616-2.911 4.687 2.647v5.16l-4.617-2.712-4.686 2.712z"
          />
          <path
            d="M10 0a.51.51 0 01.254.067l9.51 5.459a.458.458 0 01.236.396V16.31a.46.46 0 01-.245.4l-9.257 5.057a.545.545 0 01-.46.233.561.561 0 01-.397-.159l-9.396-5.13A.46.46 0 010 16.31V5.922c0-.162.09-.312.236-.396l1.58-.908 3.198-1.844.019-.02a.496.496 0 01.242-.129L9.71.068A.511.511 0 019.982 0zM5.881 14.617v4.102l3.629 1.982v-3.999l-3.629-2.085zm8.105.076l-3.419 1.964v4.001l3.419-1.867v-4.098zM19.02 11.8l-3.976 2.285v4.128l3.977-2.17-.001-4.243zm-18.041 0v4.242l3.844 2.099v-4.132L.979 11.8zm8.951-.236l-3.669 2.189L10 15.9l3.666-2.107-3.736-2.229zm9.09-4.872l-3.976 2.192v4.117l3.976-2.284V6.692zM.979 6.696v4.021l3.844 2.209V8.83L.979 6.696zm8.461.025l-2.959 1.71-.6.35v4.103l3.788-2.26a.514.514 0 01.505-.009l.017.01 3.795 2.264V8.886l-3.489-2.132v.69c0 .255-.236.463-.528.463-.286 0-.519-.199-.528-.447L9.44 6.72zm5.195-3.045l-3.739 2.18 3.63 2.243 3.991-2.2-3.882-2.223zm-9.23-.035L2.303 5.422l-.825.476 3.829 2.126.758-.439 2.964-1.729-3.624-2.215zm4.578-2.628L6.336 3.107l3.617 2.211 3.74-2.181-3.71-2.124z"
            fill="#000"
            fillRule="nonzero"
          />
          <circle fill="#000" cx={9.932} cy={9.065} r={1} />
        </g>
        <path d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default IconILP;
