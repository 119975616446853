let AuthAzure;

if (process.env.NODE_ENV === 'production') {
  AuthAzure = require('azure-component').AzureAuth;
} else {
  const AuthService = require('azure-login-package').default;
  AuthAzure = new AuthService({
    loginAppUrl: process.env.REACT_APP_LOGIN_APP_URL,
    appConfig: {
      authority: process.env.REACT_APP_AZURE_AUTH_AUTHORITY,
      clientID: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID,
      scopes: [process.env.REACT_APP_AZURE_AUTH_SCOPES],
    },
  });
}

export const Auth = AuthAzure;
