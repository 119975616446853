import { getName, prof } from './userUtils';

export const getTitle = (conversation, users) => {
  if (conversation.private) {
    const otherUser = (users && users[0]) || conversation.users[0];
    const title = getName(otherUser);
    if (otherUser.role === 'Teacher') return prof(title);
    return title;
  }
  if (!conversation.title) return conversation.users.map(getName).join(', ');
  return conversation.title;
};
