import React from 'react';
import Menu from '@material-ui/core/Menu';

const StyledMenu = ({ children, ...props }) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  >
    {children}
  </Menu>
);

export default StyledMenu;
