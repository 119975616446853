import React from 'react';
import { useSelector } from 'react-redux';
import { getCreatingConversation } from '../../../redux/selectors/App';
import NewConversation from './NewConversation/NewConversation';
import ChatHeader from './ChatHeader/ChatHeader';
import MessageBoard from './MessageBoard/MessageBoard';
import TypingIndicator from './TypingIndicator/TypingIndicator';
import SendMessage from './SendMessage/SendMessage';
import { makeStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ChatBox = () => {
  const creatingConversation = useSelector(getCreatingConversation);
  const { status, submitting } = creatingConversation;

  const classes = makeStyles(theme => ({
    chatBox: {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100*var(--vh) - 64px)`,
      maxHeight: `calc(100*var(--vh) - 64px)`,
      overflowY: 'auto',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        height: `calc(100*var(--vh) - 56px)`,
        maxHeight: `calc(100*var(--vh) - 56px)`,
      },
    },
  }))();

  return (
    <div className={classes.chatBox} id="chatBox" data-testid="ChatBox">
      {status ? (
        <>
          <NewConversation />
          {submitting ? <LinearProgress /> : null}
        </>
      ) : (
        <>
          <ChatHeader />
          <MessageBoard />
          <TypingIndicator />
        </>
      )}
      <SendMessage />
    </div>
  );
};

export default React.memo(ChatBox);
