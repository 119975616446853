import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataFromApi } from '../../../../../../../api/dataFromApi';
import { attachFileData } from '../../../../../../../redux/actions/Conversations';
import ImagePreview from '../../../../MessageBoard/Message/MediaMessage/ImageMessage/ImagePreview/ImagePreview';
import Trigger from './Trigger/Trigger';
import { makeStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

const Media = ({ chatId }) => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [dataLength, setDataLength] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const data = await getDataFromApi(
          `chat/${chatId}/messages/media/`,
          'v1'
        );
        setDataLength(data.length);
        const mediaFiles = await Promise.all(
          data.map(async mediaFile => await attachFileData(mediaFile))
        );
        setMediaFiles(mediaFiles);
      } catch {
        setDataLength(0);
        setMediaFiles([]);
      }
      setDataFetched(true);
    })();
  }, [chatId]);

  const renderSkeleton = () => (
    <div className={classes.grid}>
      {[...new Array(dataLength || 12)].map((item, index) => (
        <div key={index} className={classes.skeletonItem}>
          <Skeleton component="div" width="100%" height="100%" />
        </div>
      ))}
    </div>
  );

  const classes = makeStyles(theme => ({
    grid: {
      padding: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, minmax(7.5rem, 1fr))`,
      gridAutoRows: '1fr',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: `repeat(auto-fill, minmax(5rem, 1fr))`,
      },
      '&::before': {
        content: '""',
        width: 0,
        paddingBottom: '100%',
        gridRow: '1 / 1',
        gridColumn: '1 / 1',
      },
      '& > *:first-child': {
        gridRow: '1 / 1',
        gridColumn: '1 / 1',
      },
      '& > *': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
      },
    },
    skeletonItem: {
      width: '100%',
      height: '100%',
      '& .MuiSkeleton-root': {
        transform: 'unset',
        transformOrigin: 'unset',
      },
    },
    noFiles: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(2),
    },
  }))();

  return dataFetched ? (
    mediaFiles.length ? (
      <div className={classes.grid}>
        {mediaFiles.map(mediaFile => (
          <ImagePreview
            key={mediaFile.message_id}
            trigger={<Trigger mediaFile={mediaFile} />}
            message={mediaFile}
          />
        ))}
      </div>
    ) : (
      <Typography variant="subtitle1" className={classes.noFiles}>
        {t('ContactInfo.no_shared_images')}
      </Typography>
    )
  ) : (
    renderSkeleton()
  );
};

export default Media;
