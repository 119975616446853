import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';
import ReduxThunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

import ErrorLogger from '../api/errorLogger';

const errorLogger = new ErrorLogger();

export const store = createStore(
  reducers,
  compose(
    applyMiddleware(ReduxThunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
      : f => f,
    applyMiddleware(errorLogger.middleware)
  )
);

export const persistor = persistStore(store);
