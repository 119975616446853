export const getSettings = state => state.App.settings;
export const getLanguageSettings = state =>
  state.App.settings?.common?.language;
export const getChatWidth = state => state.App.chatWidth;
export const getChatId = state => state.App.chatId;
export const getConversationsOrder = state => state.App.conversationsOrder;
export const getNextPage = state => state.App.nextPage;
export const getSettingsPage = state => state.App.settingsPage;
export const getSideBarView = state => state.App.sidebarView;
export const getSearchResultsView = state => state.App.searchResultsView;
export const getCreatingConversation = state => state.App.creatingConversation;
export const getChatView = state => state.App.toggleView;
export const getIsVisible = state => state.App.isVisible;
