import React, { useState } from 'react';

const SnackbarContext = React.createContext();

const SnackbarProvider = props => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarText, setSnackbarText] = useState('');

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const setSnackbarOptions = (text, severity = 'success', open = true) => {
    setSnackbarText(text);
    setSnackbarSeverity(severity);
    setSnackbarOpen(open);
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbarOpen,
        setSnackbarOpen,
        handleCloseSnackbar,
        snackbarSeverity,
        setSnackbarSeverity,
        snackbarText,
        setSnackbarText,
        setSnackbarOptions,
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarContext, SnackbarProvider };
