import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '../../../redux/selectors/CurrentUser';
import { logoutUser } from '../../../redux/actions/CurrentUser';
import { SocketContext } from '../../../api/socket';
import SettingsWrapper from './Settings/SettingsWrapper';
import Profile from './Profile/Profile';
import Dialog from '../../shared/Dialog/Dialog';
import Avatar from '../../shared/Avatar/Avatar';
import StyledMenu from '../../shared/StyledMenu/StyledMenu';
import StyledMenuItem from '../../shared/StyledMenu/StyledMenuItem/StyledMenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ProfileIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SignOutIcon from '@material-ui/icons/ExitToApp';

const ProfileActions = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const socket = useContext(SocketContext);
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    socket.close();
    dispatch(logoutUser());
    handleClose();
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <Avatar user={currentUser} />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Dialog
          closePopup={handleClose}
          trigger={
            <StyledMenuItem onClick={handleClose}>
              <ListItemIcon>
                <ProfileIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('ProfileActions.profile')} />
            </StyledMenuItem>
          }
        >
          {close => <Profile user={currentUser} handleClose={close} />}
        </Dialog>
        <SettingsWrapper
          trigger={
            <StyledMenuItem onClick={handleClose}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('ProfileActions.settings')} />
            </StyledMenuItem>
          }
          closePopup={handleClose}
        />
        <StyledMenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <SignOutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('ProfileActions.sign_out')} />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default ProfileActions;
