import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SocketContext } from '../../../../../../../api/socket';
import ConversationsList from '../../../../../Sidebar/ConversationsList/ConversationsList';
import DialogTitle from '../../../../../../shared/Dialog/DialogTitle/DialogTitle';
import { changeChat } from '../../../../../../../redux/actions/App';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import UUID from 'uuid/v4';
import SmartboardMessage from '../../MediaMessage/SmartboardMessage/SmartboardMessage';
import { getCurrentUserId } from '../../../../../../../redux/selectors/CurrentUser';

const ComposeNewMessage = ({ handleClose, message }) => {
  const [selectedConversations, setSelectedConversations] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const socket = useContext(SocketContext);
  const currentUserId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleConversations = cnv => {
    setSelectedConversations(prevValue => {
      const conversation = prevValue.find(
        conversation => conversation.chat_id === cnv.chat_id
      );
      if (conversation) {
        const newSet = new Set(prevValue);
        newSet.delete(conversation);
        return [...newSet];
      }
      return [...prevValue, cnv];
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    submitMessage(message);
  };

  const submitMessage = message => {
    setIsSending(true);
    const newMessage = {
      body: message.body,
      message_type: 'smartboard',
      link_title: message.link_title,
      link_url: message.smartboard_link,
    };

    selectedConversations.forEach(cnv =>
      socket.emit(
        'message',
        {
          ...newMessage,
          message_short_id: UUID(),
          chat_id: cnv.chat_id,
        },
        () => {
          setIsSending(false);
          if (handleClose) handleClose();
        }
      )
    );
    if (selectedConversations.length === 1)
      return dispatch(changeChat(selectedConversations[0].chat_id));
  };

  const classes = makeStyles(theme => ({
    content: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
    },
    message: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      color: theme.palette.text.primary,
      background: theme.palette.background.default,
      minHeight: 100,
      '& > span': { opacity: 0.5 },
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
      '& span': { marginRight: theme.spacing(1) },
    },
    conversations: {
      marginTop: theme.spacing(2),
      maxHeight: 'calc(50 * var(--vh))',
      overflowY: 'auto',
      [theme.breakpoints.down('xs')]: { maxHeight: 'calc(35 * var(--vh))' },
    },
    subtitle: {
      marginBottom: theme.spacing(2.5),
      padding: `0 ${theme.spacing(2)}px`,
    },
    actions: { justifyContent: 'flex-start' },
  }))();

  const messageWithFullData = {
    created_at: new Date(),
    link_title: message.link_title,
    link_url: message.smartboard_link,
    user_id: currentUserId,
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle onClose={handleClose}>
        {t('MessageActions.ComposeNewMessage.title')}
      </DialogTitle>
      <Divider />
      <div className={classes.message}>
        <span>{t('MessageActions.ComposeNewMessage.preview')}:</span>
        <SmartboardMessage message={messageWithFullData} />
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.search}>
          <span>{t('MessageActions.Forward.to')}:</span>
          <Input
            fullWidth
            placeholder={t('MessageActions.Forward.search_conversations')}
            disableUnderline
          />
        </div>
        <Divider />

        <div className={classes.conversations}>
          <ConversationsList
            withSelect
            forward
            selectedConversations={selectedConversations.map(
              cnv => cnv.chat_id
            )}
            selectConversations={toggleConversations}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          data-testid="deleteButton"
          type="submit"
          disabled={!selectedConversations.length || isSending}
          color="primary"
          variant="contained"
          size="large"
        >
          {t('MessageActions.Forward.send')}
        </Button>
        <Button
          data-testid="cancelButton"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="large"
        >
          {t('MessageActions.Forward.cancel')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default ComposeNewMessage;
