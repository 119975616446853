import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChatsResults from './ChatsResults/ChatsResults';
import ContactsResults from './ContactsResults/ContactsResults';
import MediaResults from './MediaResults/MediaResults';
import MessagesResults from './MessagesResults/MessagesResults';
import {
  getActiveSection,
  getFocusedResultIndex,
  getSearchTerm,
  isSearchLoading,
  searchResults,
} from '../../../../redux/selectors/Search';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const SearchResults = () => {
  const loading = useSelector(isSearchLoading);
  const data = useSelector(searchResults);
  const searchTerm = useSelector(getSearchTerm);
  const focusedResultIndex = useSelector(getFocusedResultIndex);
  const activeSection = useSelector(getActiveSection);
  const resultsEl = useRef(null);
  const { t } = useTranslation();

  const noResults = !(
    data.contacts.count ||
    data.messages.count ||
    data.media.count ||
    data.chats.count
  );

  useEffect(() => {
    if (resultsEl.current) {
      const clientHeight = resultsEl.current.clientHeight;
      const scrollTop = resultsEl.current.scrollTop;
      const element = resultsEl.current.querySelector(
        `[data-section=${activeSection}] .MuiAccordionDetails-root > div:nth-of-type(${focusedResultIndex +
          1})`
      );
      if (!element) return;
      if (activeSection === 'contacts') {
        if (
          clientHeight + scrollTop <
          element.offsetTop + element.clientHeight
        ) {
          resultsEl.current.scrollTop =
            element.offsetTop - clientHeight + element.clientHeight;
        } else if (scrollTop > element.offsetTop) {
          if (focusedResultIndex === 0) {
            resultsEl.current.scrollTop = 0;
          } else {
            resultsEl.current.scrollTop = element.offsetTop;
          }
        }
      } else {
        const accordionElement = document.querySelector(
          `[data-section=${activeSection}]`
        );
        if (
          clientHeight + scrollTop <
          element.offsetTop + accordionElement.offsetTop + element.clientHeight
        ) {
          resultsEl.current.scrollTop =
            element.offsetTop +
            accordionElement.offsetTop -
            clientHeight +
            element.clientHeight;
        } else if (scrollTop > element.offsetTop + accordionElement.offsetTop) {
          resultsEl.current.scrollTop =
            element.offsetTop + accordionElement.offsetTop;
        }
      }
    }
  }, [focusedResultIndex, activeSection]);

  const searchIn = app => {
    let url = '';
    if (app === 'learning-app') {
      url = process.env.REACT_APP_LEARNING_APP_URL;
    } else {
      url = process.env.REACT_APP_SMARTBOARD_APP_URL + '/dashboard';
    }

    window.open(`${url}?search_term=${btoa(searchTerm)}`, '_blank');
  };

  const classes = makeStyles(theme => {
    const cls = {
      searchResults: {
        height: 'calc(100*var(--vh) - 168px)',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
          height: 'calc(100*var(--vh) - 160px)',
        },
      },
      results: {
        height: '100%',
        overflowY: 'auto',
        position: 'relative',
      },
      actions: {
        marginTop: 'auto',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        '& .MuiButton-root': {
          minWidth: 175,
          borderRadius: 16,
          textTransform: 'none',
          fontWeight: 'bold',
        },
      },
      loading: {
        padding: `${theme.spacing(2)}px 0`,
        display: 'grid',
        placeItems: 'center',
      },
      noResults: {
        padding: theme.spacing(2),
        textAlign: 'center',
        '& .MuiTypography-body1': { marginBottom: theme.spacing(1) },
      },
    };

    if (theme.typography.fontSize === 17.5) {
      cls.searchResults.height = 'calc(100*var(--vh) - 185px)';
      cls.searchResults[theme.breakpoints.down('xs')] =
        'calc(100*var(--vh) - 181px)';
    }

    return cls;
  })();

  return (
    <div className={classes.searchResults}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : noResults ? (
        <div className={classes.noResults}>
          <Typography variany="subtitle1" color="textSecondary">
            {t('SearchResults.no_matches')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {t('SearchResults.couldnt_find')} <br />
            {t('SearchResults.check_typos')}
          </Typography>
        </div>
      ) : (
        <div className={classes.results} ref={resultsEl}>
          <ContactsResults data={data.contacts} />
          <MessagesResults data={data.messages} />
          <MediaResults data={data.media} />
          <ChatsResults data={data.chats} />
        </div>
      )}
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => searchIn('smatrboard')}
        >
          {t('SearchResults.search_in_smartboard')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => searchIn('learning-app')}
        >
          {t('SearchResults.search_in_learningapp')}
        </Button>
      </div>
    </div>
  );
};

export default SearchResults;
