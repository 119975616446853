import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeExistingContact } from '../../../../../../redux/actions/Users';
import { blockContact } from '../../../../../../redux/actions/CurrentUser';
import { getChatId } from '../../../../../../redux/selectors/App';
import { getName } from '../../../../../../utils/userUtils';
import { SnackbarContext } from '../../../../ChatBox/SnackbarContext/SnackbarContext';
import ReportDialog from '../../../../ChatBox/ChatHeader/ReportDialog/ReportDialog';
import Dialog from '../../../../../shared/Dialog/Dialog';
import Avatar from '../../../../../shared/Avatar/Avatar';
import ContactInfo from '../../../../ChatBox/ChatHeader/ContactInfo/ContactInfo';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const Contact = ({ user, onClose }) => {
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuVisibility, setMenuVisibility] = useState('visible');
  const chatId = useSelector(getChatId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onContactInfoClose = close => {
    close();
    handleClose();
    if (onClose) onClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = e => {
    setMenuVisibility('visible');
    setAnchorEl(e.currentTarget);
  };

  const handleRemoveContact = user_id => {
    dispatch(removeExistingContact(user_id));
    handleClose();
  };

  const handleBlockContact = async user_id => {
    await dispatch(blockContact(user_id, chatId));
    handleClose();
    setSnackbarOptions(
      `${t('ContactInfo.BlockContact.you_have_blocked')} ${getName(user)}`
    );
  };

  return (
    <>
      <ListItem onContextMenu={handleClick}>
        <ListItemAvatar>
          <Avatar user={user} />
        </ListItemAvatar>
        <ListItemText primary={getName(user)} />
        <ListItemIcon>
          <IconButton onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
        </ListItemIcon>
      </ListItem>
      <Menu
        elevation={1}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={{ visibility: menuVisibility }}
      >
        <Dialog
          trigger={<MenuItem>{t('ProfileActions.view_profile')}</MenuItem>}
          closePopup={() => setMenuVisibility('hidden')}
        >
          {close => (
            <ContactInfo
              user_id={user.user_id}
              handleClose={() => onContactInfoClose(close)}
            />
          )}
        </Dialog>
        <MenuItem onClick={() => handleRemoveContact(user.user_id)}>
          {t('ProfileActions.remove_contact')}
        </MenuItem>
        <Dialog
          trigger={<MenuItem>{t('ProfileActions.report_contact')}</MenuItem>}
          closePopup={() => setMenuVisibility('hidden')}
        >
          {close => <ReportDialog handleClose={close} />}
        </Dialog>
        <MenuItem onClick={() => handleBlockContact(user.user_id)}>
          {t('ProfileActions.block_contact')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Contact;
