import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './WelcomeBox.module.css';
import LogoIcon from '../../../assets/icons/CommunicatorLogoRedone01';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const WelcomeBox = () => {
  const { t } = useTranslation();

  const classes = makeStyles(theme => ({
    heading: {
      color: theme.palette.text.secondary,
      '&:first-child': { fontWeight: 'bold' },
      '&:last-child': { marginTop: theme.spacing(1) },
    },
  }))();

  return (
    <div className={styles.WelcomeBox} id="welcomeBox" data-testid="welcomeBox">
      <div className={styles.MCastLogo}>
        <img src={require('../../../assets/MCAST.png')} alt="mcast logo" />
      </div>
      <div className={styles.AppLogo}>
        <LogoIcon />
      </div>
      <Typography variant="h6" className={classes.heading}>
        {t('WelcomeBox.welcome_message')}
      </Typography>
      <Typography variant="subtitle1" className={classes.heading}>
        {t('WelcomeBox.select_message')}
      </Typography>
    </div>
  );
};

export default WelcomeBox;
