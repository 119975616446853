import moment from 'moment';

export default function reducer(
  state = {
    loggedIn: false,
    lastLogin: moment()
      .subtract('2', 'days')
      .toISOString(),
    blockList: [],
    tnc_accepted: false,
    tnc_accepted_at: null,
  },
  { type, payload }
) {
  switch (type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        ...payload,
      };
    case 'UPDATE_CURRENT_USER':
      return {
        ...state,
        ...payload,
      };
    case 'SET_LAST_LOGIN':
      return {
        ...state,
        lastLogin: payload,
      };
    case 'ADD_TO_BLOCK_LIST':
      return {
        ...state,
        blockList: [...state.blockList, ...payload],
      };
    case 'REMOVE_FROM_BLOCK_LIST':
      return {
        ...state,
        blockList: state.blockList.filter(u => u.user_id !== payload),
      };
    case 'SET_TOS_ACCEPTED':
      return {
        ...state,
        tnc_accepted: true,
      };
    default:
      return state;
  }
}
