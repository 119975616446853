import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConversations,
  getNextPage,
} from '../../../../redux/actions/Conversations';
import { getAllUsers } from '../../../../redux/selectors/Users';
import { getCurrentUserId } from '../../../../redux/selectors/CurrentUser';
import {
  getChatId,
  getConversationsOrder,
} from '../../../../redux/selectors/App';
import Conversation from './Conversation/Conversation';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const ConversationsList = ({
  withSelect,
  selectedConversations,
  selectConversations,
  forward,
}) => {
  const dispatch = useDispatch();
  const chatId = useSelector(getChatId);
  const currentUserId = useSelector(getCurrentUserId);
  const conversationOrder = useSelector(getConversationsOrder);
  const nextPage = useSelector(state => state.App.nextPage);
  const fetchedConversations = useSelector(
    state => state.App.conversationsFetched
  );
  const fetchingConversations = useSelector(
    state => state.App.fetchingConversations
  );
  const conversations = useSelector(state => state.Conversations.conversations);
  const users = useSelector(getAllUsers);
  const allConversations = Object.values(conversations)
    .sort((a, b) =>
      conversationOrder.indexOf(a.chat_id) >
      conversationOrder.indexOf(b.chat_id)
        ? 1
        : -1
    )
    .map(conversation => ({
      ...conversation,
      users: conversation.users
        .filter(user => user.user_id !== currentUserId)
        .map(({ user_id }) => users[user_id]),
    }));
  const conversationListRef = useRef(null);

  useEffect(() => {
    if (!fetchedConversations) {
      dispatch(getConversations());
    }
  }, [dispatch, fetchedConversations]);

  useEffect(() => {
    if (nextPage) {
      const ref = conversationListRef.current;
      ref.addEventListener('scroll', scrollHandler);
      return () => ref.removeEventListener('scroll', scrollHandler);
    }
    // eslint-disable-next-line
  }, [nextPage]);

  const scrollHandler = e => {
    const { scrollTop, scrollHeight, offsetHeight } = e.target;
    if (scrollTop === scrollHeight - offsetHeight) dispatch(getNextPage());
  };

  const classes = makeStyles(theme => {
    const loadMore = {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      justifyContent: 'center',
      '& .MuiListItemText-primary': { textAlign: 'center' },
    };

    if (forward)
      return {
        conversationsList: {
          '& .MuiList-root': {
            padding: 0,
            '& .MuiListItemIcon-root': {
              [theme.breakpoints.down('xs')]: { minWidth: 0 },
            },
          },
        },
        loadMore,
      };

    const cls = {
      conversationsList: {
        overflowY: 'hidden',
        height: 'calc(100*var(--vh) - 168px)',
        [theme.breakpoints.down('xs')]: {
          height: 'calc(100*var(--vh) - 160px)',
        },
        '&:hover': { overflowY: 'auto' },
        '& .MuiList-root': { padding: 0 },
      },
      loadMore,
    };

    if (theme.typography.fontSize === 17.5) {
      cls.conversationsList.height = 'calc(100*var(--vh) - 185px)';
      cls.conversationsList[theme.breakpoints.down('xs')].height =
        'calc(100*var(--vh) - 181px)';
    }

    return cls;
  })();

  const conversationClasses = makeStyles(theme => {
    const cls = {
      '@global': {
        '.MuiTouchRipple-child': {
          color: theme.palette.action.disabled,
        },
      },
      card: {
        border: 'none',
        boxShadow: 'none',
        background: 'transparent',
        width: '100%',
        height: '100%',
      },
      header: {
        padding: theme.spacing(1),
        '& .MuiCardHeader-title': {
          fontSize: '1.15em',
          // color: conversation.hasLecturer
          //   ? theme.palette.primary.main
          //   : theme.palette.text.primary,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 195,
        },
        '& .MuiCardHeader-subheader': {
          color: theme.palette.text.secondary,
        },
        '& .MuiCardHeader-action': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          '& span': { color: theme.palette.text.secondary },
        },
      },
      notification: {
        color: theme.palette.common.white,
        backgroundColor: '#f57c00',
        borderRadius: '50%',
        padding: '0.75em',
        width: '1.2em',
        height: '1.2em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1em',
      },
      deletedMessage: {
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'italic',
        '& .MuiTypography-body2': {
          marginLeft: theme.spacing(1),
          fontStyle: 'italic',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 195,
        },
        '& svg': { fontSize: '1em' },
      },
      descriptionMessage: {
        display: 'inline-block',
        fontStyle: 'italic',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 195,
      },
      lastMessage: {
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 190,
      },
      draft: {
        color: '#f27000',
        marginRight: theme.spacing(1),
      },
    };

    if (!chatId) {
      cls.header['& .MuiCardHeader-title'][theme.breakpoints.down('sm')] = {
        maxWidth: 450,
      };
      cls.header['& .MuiCardHeader-title'][theme.breakpoints.down('xs')] = {
        maxWidth: 200,
      };
      cls.deletedMessage['& .MuiTypography-body2'][
        theme.breakpoints.down('sm')
      ] = {
        maxWidth: 450,
      };
      cls.deletedMessage['& .MuiTypography-body2'][
        theme.breakpoints.down('xs')
      ] = {
        maxWidth: 200,
      };
      cls.descriptionMessage[theme.breakpoints.down('sm')] = { maxWidth: 450 };
      cls.descriptionMessage[theme.breakpoints.down('xs')] = { maxWidth: 200 };
      cls.lastMessage[theme.breakpoints.down('sm')] = { maxWidth: 450 };
      cls.lastMessage[theme.breakpoints.down('xs')] = { maxWidth: 200 };
    }

    if (forward) {
      cls.notification.display = 'none';
      cls.header['& .MuiCardHeader-action'][theme.breakpoints.down('xs')] = {
        display: 'none',
      };

      cls.header['& .MuiCardHeader-title'].maxWidth = 400;
      cls.header['& .MuiCardHeader-title'][theme.breakpoints.down('xs')] = {
        maxWidth: 175,
      };
      cls.lastMessage = {
        ...cls.lastMessage,
        [theme.breakpoints.down('xs')]: { maxWidth: 175 },
      };
    }

    return cls;
  })();

  return (
    <div className={classes.conversationsList} ref={conversationListRef}>
      <List className={classes.list}>
        {allConversations.map(cnv => (
          <Conversation
            key={cnv.chat_id}
            conversation={cnv}
            conversations={allConversations}
            forward={forward}
            withSelect={withSelect}
            selectedConversations={selectedConversations}
            selectConversations={selectConversations}
            classes={conversationClasses}
          />
        ))}
        {fetchingConversations && (
          <ListItem className={classes.loadMore}>
            <CircularProgress />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default ConversationsList;
