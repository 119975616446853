import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  usernameColor,
  usernameText,
} from '../../../../../../../../utils/userUtils';
import { getConversation } from '../../../../../../../../redux/actions/Conversations';
import { getConversationDetails } from '../../../../../../../../redux/selectors/Conversations';
import { changeChat } from '../../../../../../../../redux/actions/App';
import { getUser } from '../../../../../../../../redux/selectors/Users';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '../../../../../../../shared/Avatar/Avatar';
import Typography from '@material-ui/core/Typography';

const MessageInfo = ({ message, closePopup }) => {
  const conversation = useSelector(getConversationDetails(message.chat_id));
  const userId =
    conversation && conversation.private
      ? conversation.users.find(u => u.user_id !== message.user_id).user_id
      : null;
  const user = useSelector(getUser(userId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const date = new Date(message.created_at);

  useEffect(() => {
    (async () => {
      if (conversation) return;
      dispatch(getConversation(message.chat_id));
    })();
  }, [conversation, dispatch, message.chat_id]);

  const openChat = () => {
    dispatch(changeChat(conversation.chat_id));
    closePopup();
  };

  const classes = makeStyles(theme => ({
    messageInfo: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      '& .MuiTypography-subtitle1': {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
      },
    },
    chat: {
      all: 'unset',
      cursor: 'pointer',
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      '& .MuiTypography-body1': {
        marginLeft: theme.spacing(2),
        maxWidth: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }))();

  return (
    <div className={classes.messageInfo}>
      <Typography variant="subtitle1">
        {t('MessageActions.Details.sent_to')}
      </Typography>
      {conversation && (
        <button className={classes.chat} onClick={openChat}>
          {conversation.private ? (
            <>
              <Avatar user={user} />
              <Typography variant="body1" color={usernameColor(user)}>
                {usernameText(user)}
              </Typography>
            </>
          ) : (
            <>
              <Avatar group photo={conversation.image_signed_url} />
              <Typography
                variant="body1"
                color={conversation.hasLecturer ? 'primary' : 'textPrimary'}
              >
                {conversation.title}
              </Typography>
            </>
          )}
        </button>
      )}
      <Typography variant="body2" color="textSecondary">
        {date.toLocaleDateString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })}
      </Typography>
    </div>
  );
};

export default MessageInfo;
