import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSettingsPage } from '../../../../redux/selectors/App';
import { setSettingsPage } from '../../../../redux/actions/App';
import Dialog from '../../../shared/Dialog/Dialog';
import DialogTitle from '../../../shared/Dialog/DialogTitle/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Settings from './Settings';
import Appearance from './Appearance/Appearance';
import Notifications from './Notifications/Notifications';
import Privacy from './Privacy/Privacy';
import Export from './Export/Export';

const SettingsWrapper = ({ closePopup, trigger }) => {
  const settingsState = useSelector(getSettingsPage);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const title = {
    settings: t('SettingsWrapper.settings'),
    appearance: t('SettingsWrapper.appearance'),
    notification: t('SettingsWrapper.notification'),
    privacy: t('SettingsWrapper.privacy'),
    export: t('SettingsWrapper.export'),
    undefined: t('SettingsWrapper.undefined'),
  }[settingsState];

  const handleBack = () => {
    dispatch(setSettingsPage('settings'));
    // return false;
  };

  // const handleCloseSettings = () => {
  //   dispatch(setSettingsPage('settings'));
  //   handleClose();
  //   return true;
  // };

  return (
    <Dialog closePopup={closePopup} trigger={trigger} onBack={handleBack}>
      {close => (
        <>
          <DialogTitle
            onClose={close}
            onBack={settingsState !== 'settings' && handleBack}
          >
            {title}
          </DialogTitle>
          <Divider />
          {settingsState === 'settings' && <Settings />}
          {settingsState === 'notification' && <Notifications />}
          {settingsState === 'appearance' && <Appearance />}
          {settingsState === 'privacy' && <Privacy />}
          {settingsState === 'export' && <Export />}
        </>
      )}
    </Dialog>
  );
};

export default SettingsWrapper;
