import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const GeneralInfo = ({ bio_info }) => {
  const { t } = useTranslation();

  const classes = makeStyles(theme => ({
    generalInfo: {
      padding: `0 ${theme.spacing(2)}px`,
      '& .MuiBox-root': { padding: theme.spacing(0.5) },
    },
    about: {
      marginTop: theme.spacing(1),
      '& .MuiTypography-body2': {
        marginTop: theme.spacing(0.5),
        wordBreak: 'break-word',
      },
    },
    courses: { marginTop: theme.spacing(2) },
    course: {
      marginTop: theme.spacing(1),
      '& .MuiTypography-body2': { color: theme.palette.text.secondary },
    },
    subtitle: { fontWeight: 'bold' },
  }))();

  return (
    <div className={classes.generalInfo}>
      {bio_info ? (
        <div className={classes.about}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {t('ContactInfo.about_me')}
          </Typography>
          <Typography variant="body2">{bio_info}</Typography>
        </div>
      ) : null}
      {/* <div className={classes.courses}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {t('ContactInfo.courses_enrolled')}
        </Typography>
        <div className={classes.course}>
          <Typography variant="subtitle2">
            CDKSK-406-1602 | Mathematics
          </Typography>
          <Typography variant="body2">
            Cross Curricular Units - IET - Year 1
          </Typography>
        </div>
        <div className={classes.course}>
          <Typography variant="subtitle2">General Physics</Typography>
          <Typography variant="body2">
            EE4-A1-18 | MCAST Advanced Diploma in Industrial Electronics - Year
            1
          </Typography>
        </div>
      </div> */}
    </div>
  );
};

export default GeneralInfo;
