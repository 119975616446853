import * as React from 'react';

function SvgExcel(props) {
  return (
    <svg width="1.5em" height="1.5em" viewBox="0 0 100 120" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M59.098 0L100 40.115V110c0 5.523-4.477 10-10 10H10c-5.523 0-10-4.477-10-10V10C0 4.477 4.477 0 10 0h49.098z"
          fill="#4CAF50"
        />
        <path
          d="M100 40l-35.314-.86A10 10 0 0159 30.114V0h1l40 39v1z"
          fill="#C8E6C9"
          fillRule="nonzero"
        />
        <path
          d="M58.963 55.016v.032h.012v39.95h-3.33v-.005h-10.01v.005h-3.329v-.005h-6.59v.005h-3.328v-.005H22.352l.001.005h-3.329v-.005h-.007v-3.328l.006-.001v-10.06l-.008.002v-3.33h.008v-10.06l-.01.002v-3.33h.01v-6.543h-.01v-3.33h39.95zM32.387 81.605H22.353v10.059h10.034v-10.06zm9.918 0h-6.589v10.059h6.59v-10.06zm13.34 0h-10.01v10.059h10.01v-10.06zM32.387 68.217H22.353v10.059h10.034v-10.06zm9.918 0h-6.589v10.059h6.59v-10.06zm13.34 0h-10.01v10.059h10.01v-10.06zm0-9.872H22.353v6.543h33.292v-6.543z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgExcel;
