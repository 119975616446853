import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../../../../../../../utils/fileUtils';
import ForwardMessage from '../../../../../MessageBoard/Message/MessageActions/ForwardMessage/ForwardMessage';
import MessageDetails from '../../../../../MessageBoard/Message/MessageActions/MessageDetails/MessageDetails';
import { makeStyles } from '@material-ui/styles';
import StyledMenu from '../../../../../../../shared/StyledMenu/StyledMenu';
import StyledMenuItem from '../../../../../../../shared/StyledMenu/StyledMenuItem/StyledMenuItem';
import Dialog from '../../../../../../../shared/Dialog/Dialog';
import ListItemText from '@material-ui/core/ListItemText';

const Trigger = ({ mediaFile, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const { file_data, message_type } = mediaFile;
  const { expires_at, media_file_url, original_name } = file_data;
  const isExpired = moment().isAfter(expires_at);

  const handleRightClick = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = message => {
    downloadFile(false, message.file_data);
    handleClose();
  };

  const classes = makeStyles(theme => ({
    trigger: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      cursor: 'pointer',
    },
  }))();

  return (
    <>
      <img
        src={media_file_url}
        alt={original_name}
        className={classes.trigger}
        onClick={onClick}
        onContextMenu={handleRightClick}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        autoFocus={false}
        onClose={handleClose}
      >
        <Dialog
          closePopup={handleClose}
          trigger={
            <StyledMenuItem onClick={handleClose}>
              <ListItemText primary={t('MessageActions.details')} />
            </StyledMenuItem>
          }
        >
          {close => <MessageDetails handleClose={close} message={mediaFile} />}
        </Dialog>

        <Dialog
          closePopup={handleClose}
          trigger={
            <StyledMenuItem onClick={handleClose}>
              <ListItemText primary={t('MessageActions.forward')} />
            </StyledMenuItem>
          }
        >
          {close => <ForwardMessage handleClose={close} message={mediaFile} />}
        </Dialog>

        {!['text', 'youtube', 'smartboard'].includes(message_type) &&
          !isExpired && (
            <StyledMenuItem
              onClick={() => handleDownload(mediaFile)}
              data-testid="download"
            >
              <ListItemText primary={t('MessageActions.download')} />
            </StyledMenuItem>
          )}
      </StyledMenu>
    </>
  );
};

export default Trigger;
