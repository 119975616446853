import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getName } from '../../../../../utils/userUtils';
import { unblockContact } from '../../../../../redux/actions/CurrentUser';
import { getBlockList } from '../../../../../redux/selectors/CurrentUser';
import { SnackbarContext } from '../../../../Chat/ChatBox/SnackbarContext/SnackbarContext';
import Avatar from '../../../../shared/Avatar/Avatar';
import Dialog from '../../../../shared/Dialog/Dialog';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import MuiAvatar from '@material-ui/core/Avatar';
import DialogContent from '@material-ui/core/DialogContent';
// import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import BlockUsers from './BlockUsers/BlockUsers';

const Privacy = () => {
  const { setSnackbarOptions } = useContext(SnackbarContext);
  const blockList = useSelector(getBlockList);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUnblock = async (userId, displayName) => {
    await dispatch(unblockContact(userId));
    setSnackbarOptions(
      `${t('Settings.Privacy.you_have_unblocked')} ${displayName}`
    );
  };

  const classes = makeStyles(theme => ({
    privacy: { padding: 0 },
    options: {
      padding: theme.spacing(2),
      '& .MuiFormControl-root': { width: '100%' },
      '& .MuiFormControlLabel-root': { margin: 0 },
      '& .MuiFormControlLabel-label': { marginRight: 'auto' },
    },
    heading: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
    padding: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    blockContact: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(2),
        color: orange[500],
      },
    },
    blocklistInfo: {
      margin: `${theme.spacing(1.5)}px 0`,
      padding: `0 ${theme.spacing(1)}px`,
      textAlign: 'center',
    },
    orange: { backgroundColor: orange[500] },
    alert: { padding: theme.spacing(1) },
  }))();

  return (
    <DialogContent className={classes.privacy}>
      {/* <div className={classes.options}>
        <Typography variant="subtitle1" className={classes.heading}>
          {t('Settings.Privacy.who_can_see')}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup value="everybody" onChange={() => {}}>
            <FormControlLabel
              value="everybody"
              control={<Radio disabled />}
              label={t('Settings.Privacy.everybody')}
              labelPlacement="start"
            />
            <FormControlLabel
              value="personal"
              control={<Radio disabled />}
              label={t('Settings.Privacy.personal_contacts')}
              labelPlacement="start"
            />
            <FormControlLabel
              value="class"
              control={<Radio disabled />}
              label={t('Settings.Privacy.class_members')}
              labelPlacement="start"
            />
            <FormControlLabel
              value="only-me"
              control={<Radio disabled />}
              label={t('Settings.Privacy.only_me')}
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <Divider />
      <div className={classes.options}>
        <Typography variant="subtitle1" className={classes.heading}>
          {t('Settings.Privacy.who_can_add')}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup value="everybody" onChange={() => {}}>
            <FormControlLabel
              value="everybody"
              control={<Radio disabled />}
              label={t('Settings.Privacy.everybody')}
              labelPlacement="start"
            />
            <FormControlLabel
              value="class"
              control={<Radio disabled />}
              label={t('Settings.Privacy.class_members')}
              labelPlacement="start"
            />
            <FormControlLabel
              value="nobody"
              control={<Radio disabled />}
              label={t('Settings.Privacy.nobody')}
              labelPlacement="start"
            />
          </RadioGroup>
        </FormControl>
      </div> */}
      <div>
        <div className={classes.padding}>
          <Typography variant="subtitle1" className={classes.heading}>
            {t('Settings.Privacy.blocked_contacts')}
          </Typography>
          <Dialog
            trigger={
              <div className={classes.blockContact}>
                <MuiAvatar className={classes.orange}>
                  <BlockIcon />
                </MuiAvatar>
                <Typography variant="subtitle1">
                  {t('Settings.Privacy.block_contact')}
                </Typography>
              </div>
            }
          >
            {close => <BlockUsers handleClose={close} />}
          </Dialog>
        </div>
        {blockList.length ? (
          <div className={classes.blockedContacts}>
            <List>
              {blockList.map(user => (
                <ListItem button key={user.user_id}>
                  <ListItemAvatar>
                    <Avatar user={user} />
                  </ListItemAvatar>
                  <ListItemText>{getName(user)}</ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleUnblock(user.user_id, getName(user))}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        ) : (
          <Typography
            variant="body1"
            color="textSecondary"
            className={classes.blocklistInfo}
          >
            {t('Settings.Privacy.blocklist_empty')}
          </Typography>
        )}
      </div>
      <div className={classes.alert}>
        <Alert severity="warning">{t('Settings.Privacy.privacy_note')}</Alert>
      </div>
    </DialogContent>
  );
};

export default Privacy;
