import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateMessageFileData } from '../../../../../../../../redux/actions/Conversations';
import { downloadFile } from '../../../../../../../../utils/fileUtils';
import { SocketContext } from '../../../../../../../../api/socket';
import { makeStyles, withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import GetAppIcon from '@material-ui/icons/GetApp';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTooltip = withStyles({
  tooltipPlacementTop: { margin: 0 },
})(Tooltip);

const ImagePreview = ({ trigger, message }) => {
  const { file_data } = message;

  const [open, setOpen] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1.0);
  const [imgLoading, setImgLoading] = useState(false);
  const counter = useRef(0);
  const _isMounted = useRef(false);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const SCALE_FACTOR = 0.5;
  const ROTATE_FACTOR = 90;
  const FULL_CIRCLE = 360;
  const MAX_SCALE = 3;
  const MIN_SCALE = 0.5;

  useEffect(() => {
    _isMounted.current = true;
    return () => (_isMounted.current = false);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRotate(0);
    setScale(1.0);
    setOpen(false);
  };

  const rotateLeft = () =>
    setRotate(rotate => (rotate - ROTATE_FACTOR) % FULL_CIRCLE);
  const rotateRight = () =>
    setRotate(rotate => (rotate + ROTATE_FACTOR) % FULL_CIRCLE);
  const zoomIn = () => setScale(scale => scale + SCALE_FACTOR);
  const zoomOut = () => setScale(scale => scale - SCALE_FACTOR);
  const download = () => downloadFile(false, message, socket);

  const handleError = async e => {
    if (counter.current >= 3) return;
    counter.current += 1;
    setImgLoading(true);
    await dispatch(updateMessageFileData(message));
    if (_isMounted.current) setImgLoading(false);
  };

  const classes = makeStyles(theme => ({
    root: {
      '& .MuiDialog-paper': {
        backgroundColor: 'rgba(0, 0, 0, .87)',
        color: theme.palette.getContrastText('rgba(0, 0, 0, .87)'),
      },
      '& .MuiDialogTitle-root': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiDialogContent-root': { userSelect: 'none' },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    actions: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '5vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
      },
      '& button[disabled]': {
        '& .MuiSvgIcon-root': { color: theme.palette.grey[700] },
      },
      [theme.breakpoints.down('sm')]: {
        top: 'auto',
        bottom: 0,
        width: '100%',
        height: 'calc(10 * var(--vh))',
        flexDirection: 'row',
      },
    },
    imageWrapper: {
      width: '90%',
      height: '100%',
      margin: 'auto',
      overflow: 'hidden',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '90%',
      },
    },
    image: {
      objectFit: 'scale-down',
      width: '100%',
      height: '100%',
      transform: `rotate(${rotate}deg) scale(${scale})`,
    },
    spinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1em 0',
    },
  }))();

  return (
    <>
      {React.cloneElement(trigger, { onClick: handleOpen })}
      <Dialog
        className={classes.root}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
      >
        {open && (
          <>
            <DialogTitle disableTypography id="alert-dialog-slide-title">
              <Typography variant="h6">{file_data.original_name}</Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className={classes.imageWrapper}>
                {imgLoading ? (
                  <div data-testid="spinner" className={classes.spinner}>
                    <CircularProgress />
                  </div>
                ) : (
                  <img
                    className={classes.image}
                    src={file_data.media_file_url}
                    alt={file_data.name}
                    onError={handleError}
                  />
                )}
              </div>
            </DialogContent>
            <div className={classes.actions}>
              <StyledTooltip title={t('Image.rotate_left')} placement="top">
                <IconButton onClick={rotateLeft}>
                  <RotateLeftIcon fontSize="large" />
                </IconButton>
              </StyledTooltip>
              <StyledTooltip title={t('Image.rotate_right')} placement="top">
                <IconButton onClick={rotateRight}>
                  <RotateRightIcon fontSize="large" />
                </IconButton>
              </StyledTooltip>
              <StyledTooltip title={t('Image.zoom_in')} placement="top">
                <IconButton onClick={zoomIn} disabled={scale === MAX_SCALE}>
                  <ZoomInIcon fontSize="large" />
                </IconButton>
              </StyledTooltip>
              <StyledTooltip title={t('Image.zoom_out')} placement="top">
                <IconButton onClick={zoomOut} disabled={scale === MIN_SCALE}>
                  <ZoomOutIcon fontSize="large" />
                </IconButton>
              </StyledTooltip>
              <StyledTooltip title={t('Image.download')} placement="top">
                <IconButton onClick={download}>
                  <GetAppIcon fontSize="large" />
                </IconButton>
              </StyledTooltip>
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ImagePreview;
