import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import youtubeUrl from 'youtube-url';
import UUID from 'uuid/v4';
import isUrl from 'is-url';
import { getName } from '../../../../utils/userUtils';
import { SocketContext } from '../../../../api/socket';
import { postDataToApi, putDataToApi } from '../../../../api/dataFromApi';
import {
  toggleEdit,
  updateCreatingConversation,
} from '../../../../redux/actions/App';
import {
  addNewMessage,
  replyToMessage,
  setPlaceholderTimeout,
} from '../../../../redux/actions/Conversations';
import {
  getChatId,
  getCreatingConversation,
  getSettings,
} from '../../../../redux/selectors/App';
import {
  getCurrentUserId,
  getCurrentUser,
} from '../../../../redux/selectors/CurrentUser';
import useInterval from '../../../shared/useIntervalHook';
import FileUpload from '../FileUpload/FileUpload';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import ReplyIcon from '@material-ui/icons/Reply';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const SendMessage = () => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const chatId = useSelector(getChatId);
  const userId = useSelector(getCurrentUserId);
  const currentUser = useSelector(getCurrentUser);
  const creatingConversation = useSelector(getCreatingConversation);
  const replyTo = useSelector(
    state => state.Conversations.conversations[chatId]?.reply_to
  );
  const messageToReply = useSelector(state =>
    state.Conversations.conversations[chatId]?.messages.find(
      m => m.message_id === replyTo
    )
  );
  const editedMessage = useSelector(state => state.App.editMessage);
  const messageForEdit = useSelector(state =>
    state.Conversations.conversations[chatId]?.messages.find(
      m => m.message_id === Number(editedMessage.messageId)
    )
  );
  const [message, setMessage] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const inputEl = useRef(null);
  const draftMessage = useSelector(
    state => state.Conversations.conversations[chatId]?.draft
  );
  const temporaryDraft = useRef(message);
  const [isTyping, setIsTyping] = useState(false);
  const [oldMessage, setOldMessage] = useState(message);
  const { t } = useTranslation();

  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [audioRecording, setAudioRecording] = useState({
    audioMessageMode: false,
    activelyRecording: false,
  });
  const [audioUrl, setAudioUrl] = useState(null);
  const audioEl = useRef(null);
  const blobRef = useRef(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const audioDurationInterval = useRef(null);
  const savedCallback = useRef(null);
  const [uploadingAudioMessage, setUploadingAudioMessage] = useState(false);

  const { status, users, submitting, initialUser } = creatingConversation;
  const inputDisabled = status && !users.length;
  const sendButtonDisabled =
    ((!message.trim() || submitting) && !audioUrl) || uploadingAudioMessage;

  function callback() {
    if (audioDuration === 59) {
      stopRecording();
    }
    setAudioDuration(duration => duration + 1);
  }

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    return () => {
      if (audioDurationInterval.current) {
        clearInterval(audioDurationInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    handleCancelAudioMessage();
  }, [chatId]);

  const settings = useSelector(getSettings);

  useEffect(() => {
    if (initialUser && status) {
      inputEl.current.focus();
    }
  }, [initialUser, status]);

  // Set Edit message
  useEffect(() => {
    if (messageForEdit && editedMessage.edit) {
      setMessage(messageForEdit.body);
    }
  }, [messageForEdit, editedMessage]);

  // Set message on change
  const setInputHandler = e => {
    const inputMessage = e.target.value;
    setIsDirty(true);
    setMessage(inputMessage);
    temporaryDraft.current = inputMessage;
    setIsTyping(true);
  };

  // Send Message
  const submitMessage = async e => {
    e.preventDefault();
    if (blobRef.current) {
      setUploadingAudioMessage(true);
      const form = new FormData();
      form.append('media', blobRef.current);
      const {
        name,
        message_type_suggestion,
        original_name,
      } = await putDataToApi('files/upload/media-file/', form, 'v1');
      sendMessage(name, message_type_suggestion, original_name);
      setUploadingAudioMessage(false);
      handleCancelAudioMessage();
    } else {
      sendMessage(message);
    }
  };

  const sendMessage = (message, type, fileName) => {
    if (!message.length) return;
    let message_type;
    if (youtubeUrl.valid(message)) message_type = 'youtube';
    else if (isUrl(message)) message_type = 'link';
    else message_type = type;
    if (status) {
      const recipients = users.map(u => u.user_id);
      dispatch(updateCreatingConversation({ submitting: true, users: [] }));
      createConversation(recipients, message, message_type);
      return;
    }
    const replies_to = replyTo || undefined;
    if (editedMessage.edit) {
      socket.emit('edit_message', {
        chat_id: chatId,
        message_id: messageForEdit.message_id,
        body: message,
      });
    } else {
      const message_short_id = UUID();
      socket.emit('message', {
        chat_id: chatId,
        body: message,
        message_type,
        replies_to,
        message_short_id,
        file_name: fileName,
      });
      const now = new Date();
      const placeholderMessage = {
        user_id: userId,
        chat_id: chatId,
        body: message,
        message_type,
        user: currentUser,
        created_at: now,
        last_edited: now,
        replies_to: {},
        forwards: {},
        statuses: { seen: true },
        seen_by: [],
        message_short_id,
        placeholder: true,
      };
      dispatch(addNewMessage(chatId, placeholderMessage));
      dispatch(setPlaceholderTimeout(chatId, message_short_id));
      postDataToApi(
        'achievements/increment/',
        {
          user_id: userId,
          achievement_event_id: 'message_sent',
          count: 1,
        },
        'v1'
      );
    }
    temporaryDraft.current = '';
    setDraft(message, '', chatId);
    stopTyping();
    setMessage('');
    cancelReply();
    cancelEdit();
  };

  const createConversation = (users, message, type) => {
    const data = {
      creator_id: userId,
      user_ids: [userId, ...users],
      body: message,
      message_type: type,
    };

    socket.emit('new_chat_message', data);

    if (users.length > 1) {
      postDataToApi(
        'achievements/increment/',
        {
          user_id: userId,
          achievement_event_id: 'group_chat_created',
          count: 1,
        },
        'v1'
      );
    }
  };

  // Cancel Reply message
  const cancelReply = () => {
    if (replyTo) dispatch(replyToMessage(chatId, false));
  };

  const cancelEdit = () => {
    if (editedMessage.edit) dispatch(toggleEdit(false));
  };

  // Input Focus
  useEffect(() => {
    if (window.innerWidth > 1024) {
      inputEl.current.focus();
    }
  }, [chatId, inputEl]);

  // Clear Input on Chat change
  useEffect(() => {
    setMessage('');
    setIsDirty(false);
    cancelEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  // Set Draft message on draft change
  useEffect(() => {
    if (!isDirty) {
      setMessage((draftMessage && draftMessage.body) || '');
      temporaryDraft.current = (draftMessage && draftMessage.body) || '';
    }
  }, [draftMessage, isDirty]);

  // Create/Update or Destroy draft for chat
  const setDraft = (prevDraft, draft, chatId) => {
    if (prevDraft && !draft) {
      socket.emit('delete_draft_message', {
        chat_id: chatId,
      });
    } else if ((!prevDraft && draft) || (prevDraft && prevDraft !== draft)) {
      socket.emit('create_draft_message', {
        chat_id: chatId,
        body: draft,
      });
    }
  };

  // Set Draft when chat is changed
  useEffect(() => {
    return () => {
      const oldDraft = draftMessage && draftMessage.body;
      const tempDraft = temporaryDraft.current;
      setDraft(oldDraft, tempDraft, chatId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  // Set Draft every 3 sec if user typed something
  useInterval(
    () => {
      const oldDraft = draftMessage && draftMessage.body;
      const tempDraft = temporaryDraft.current;
      setDraft(oldDraft, tempDraft, chatId);
    },
    !isDirty ? null : 3000
  );

  // Stop typing indicator
  const stopTyping = () => {
    setIsTyping(false);
    socket.emit('typing', {
      chat_id: chatId,
      typing: false,
    });
  };

  // Set typing indicator every 3 sec
  useInterval(
    () => {
      if (oldMessage === message) {
        stopTyping();
      } else {
        setOldMessage(message);
        socket.emit('typing', {
          chat_id: chatId,
          typing: true,
        });
      }
    },
    !isTyping ? null : 3000
  );

  const handleCancelAudioMessage = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
    }
    if (audioEl.current && !audioEl.current.paused) {
      audioEl.current.pause();
    }
    setAudioRecording({ audioMessageMode: false, activelyRecording: false });
    setAudioDuration(0);
    setAudioUrl(null);
    blobRef.current = null;
    chunks.current = [];
    if (audioDurationInterval.current) {
      clearInterval(audioDurationInterval.current);
    }
  };

  const stopRecording = () => {
    mediaRecorder.current.stop();
    setAudioRecording(state => ({ ...state, activelyRecording: false }));
  };

  const startRecording = () => {
    mediaRecorder.current.start();
    setAudioRecording({
      audioMessageMode: true,
      activelyRecording: true,
    });
    function tick() {
      savedCallback.current();
    }
    if (audioDurationInterval.current) {
      clearInterval(audioDurationInterval.current);
    }
    audioDurationInterval.current = setInterval(tick, 1000);
  };

  const getDuration = () => {
    if (audioDuration < 10) return `00:0${audioDuration}`;
    if (audioDuration < 60) return `00:${audioDuration}`;
    return `01:00`;
  };

  const handleAudioRecord = async () => {
    if (audioRecording.audioMessageMode) {
      if (audioRecording.activelyRecording) {
        stopRecording();
      } else {
        audioEl.current.play();
      }
    } else {
      if (!mediaRecorder.current) {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            mediaRecorder.current = new MediaRecorder(stream);
            mediaRecorder.current.ondataavailable = e => {
              chunks.current.push(e.data);
            };
            mediaRecorder.current.onstop = () => {
              const blob = new Blob(chunks.current, {
                type: 'audio/ogg; codecs=opus',
              });
              blobRef.current = blob;
              const audioUrl = URL.createObjectURL(blob);
              setAudioUrl(audioUrl);
              chunks.current = [];
              if (audioDurationInterval.current) {
                clearInterval(audioDurationInterval.current);
              }
            };
            startRecording();
          } catch (err) {
            mediaRecorder.current = null;
            console.error(`The following getUserMedia error occurred: ${err}`);
          }
        } else {
          console.log('getUserMedia not supported on your browser!');
        }
      } else {
        startRecording();
      }
    }
  };

  const classes = makeStyles(theme => {
    const cls = {
      form: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
      },
      input: {
        flexGrow: 1,
        display: 'grid',
        placeItems: 'center',
        height: 36,
        margin: `0 ${theme.spacing(1)}px`,
        backgroundColor:
          settings.common.background_color === 'default'
            ? theme.palette.action.hover
            : theme.palette.background.default,
        borderRadius: 18,
        '& .MuiInputBase-root': {
          width: '100%',
          padding: `0 ${theme.spacing(1.5)}px`,
        },
        '& .MuiInputBase-input': { padding: 0 },
      },
      inputAudio: {
        flexGrow: 1,
        display: 'grid',
        placeItems: 'center',
        height: 36,
        margin: `0 ${theme.spacing(1)}px`,
        background: `linear-gradient(to right, rgba(186, 218, 234, 0.6) 0, rgba(186, 218, 234, 0.6) ${(audioDuration /
          60) *
          100}%, #badaea ${(audioDuration / 60) * 100}%, #badaea 100%)`,
        borderRadius: 18,
        '& .MuiInputBase-root': {
          width: '100%',
          padding: `0 ${theme.spacing(1.5)}px`,
        },
        '& .MuiInputBase-input': { padding: 0 },
      },
      sendButton: {
        backgroundColor:
          settings.common.background_color === 'default'
            ? theme.palette.action.hover
            : theme.palette.background.default,
        padding: theme.spacing(0.75),
        '&[disabled]': {
          backgroundColor:
            settings.common.background_color === 'default'
              ? theme.palette.action.hover
              : theme.palette.background.default,
          '& .MuiIconButton-label': {
            '& .MuiSvgIcon-root': {
              fill:
                theme.palette.type === 'light'
                  ? theme.palette.common.white
                  : theme.palette.action.disabled,
            },
          },
        },
      },
      replyOrEdit: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0 ${theme.spacing(3)}px`,
        paddingTop: theme.spacing(1),
      },
      replyingOrEditing: { display: 'flex' },
      messageIcon: {
        display: 'flex',
        alignItems: 'center',
      },
      messageInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(3),
        '& p:last-child': { color: theme.palette.text.secondary },
      },
      usernameReply: { color: theme.palette.text.primary },
      usernameEdit: { color: theme.palette.primary.main },
      openButton: {
        backgroundColor:
          settings.common.background_color === 'default'
            ? theme.palette.action.hover
            : theme.palette.background.default,
        padding: theme.spacing(0.75),
      },
      stopButton: { fill: theme.palette.error.main },
    };

    if (status) {
      cls.form.position = 'absolute';
      cls.form.bottom = 0;
      cls.form.width = '100%';
    }

    if (theme.typography.fontSize === 17.5) {
      cls.input.maxHeight = 40;
      cls.input.height = 40;
    }

    return cls;
  })();

  return (
    <>
      {audioUrl && (
        <audio src={audioUrl} ref={audioEl} hidden>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      )}
      {replyTo && messageToReply ? (
        <>
          <Divider />
          <div className={classes.replyOrEdit}>
            <div className={classes.replyingOrEditing}>
              <div className={classes.messageIcon}>
                <ReplyIcon />
              </div>
              <div className={classes.messageInfo}>
                <Typography variant="body2" className={classes.usernameReply}>
                  {getName(messageToReply.user)}
                </Typography>
                <Typography variant="body2">
                  {messageToReply.file_data
                    ? messageToReply.file_data.original_name
                    : messageToReply.message_type === 'smartboard'
                    ? messageToReply.link_title
                    : messageToReply.body}
                </Typography>
              </div>
            </div>
            <IconButton size="small" onClick={cancelReply}>
              <CloseIcon />
            </IconButton>
          </div>
        </>
      ) : (
        ''
      )}
      {messageForEdit && editedMessage.edit && (
        <>
          <Divider />
          <div className={classes.replyOrEdit}>
            <div className={classes.replyingOrEditing}>
              <div className={classes.messageIcon}>
                <EditIcon color="primary" />
              </div>
              <div className={classes.messageInfo}>
                <Typography variant="body2" className={classes.usernameEdit}>
                  {t('SendMessage.edit_message')}
                </Typography>
                <Typography variant="body2">{messageForEdit.body}</Typography>
              </div>
            </div>
            <IconButton
              size="small"
              onClick={() => dispatch(toggleEdit(false))}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </>
      )}
      <form className={classes.form} onSubmit={submitMessage}>
        {!audioRecording.audioMessageMode ? (
          <FileUpload
            trigger={
              <IconButton
                className={classes.openButton}
                data-testid="dialog-opener"
                disabled={inputDisabled}
                size="small"
              >
                <AddIcon color={inputDisabled ? 'disabled' : 'primary'} />
              </IconButton>
            }
            data={{
              onUpload: sendMessage,
              name: 'media',
              url: 'files/upload/media-file/',
              requiredField: 'name',
              from: 'send-message',
            }}
          />
        ) : (
          <IconButton
            className={classes.openButton}
            size="small"
            onClick={handleCancelAudioMessage}
          >
            <CancelIcon color="primary" />
          </IconButton>
        )}
        <IconButton
          className={classes.openButton}
          style={{ marginLeft: '0.5rem' }}
          onClick={handleAudioRecord}
          disabled={inputDisabled}
          size="small"
        >
          {!audioRecording.audioMessageMode ? (
            <MicIcon color={inputDisabled ? 'disabled' : 'primary'} />
          ) : !audioRecording.activelyRecording ? (
            <PlayArrowIcon color="primary" />
          ) : (
            <StopIcon className={classes.stopButton} />
          )}
        </IconButton>
        {!audioRecording.audioMessageMode ? (
          <FormControl className={classes.input}>
            <Input
              inputRef={inputEl}
              disableUnderline={true}
              placeholder={t('SendMessage.type_here')}
              value={message}
              onChange={setInputHandler}
              disabled={inputDisabled}
            />
          </FormControl>
        ) : (
          <FormControl className={classes.inputAudio}>
            <Input disableUnderline={true} value={getDuration()} disabled />
          </FormControl>
        )}
        <IconButton
          disabled={sendButtonDisabled}
          className={classes.sendButton}
          size="small"
          type="submit"
        >
          <SendIcon color={!sendButtonDisabled ? 'primary' : 'disabled'} />
        </IconButton>
      </form>
    </>
  );
};

export default SendMessage;
