import moment from 'moment';

export const downloadFile = async (expired, message, socket) => {
  if (expired) return;
  const { file_data } = message;
  const { media_file_url, original_name } = file_data;
  const a = document.createElement('a');
  a.href = media_file_url;
  a.download = original_name;
  a.target = '_blank';
  a.click();
  socket.emit('ack', {
    message_id: message.message_id,
    chat_id: message.chat_id,
    statuses: { downloaded: true },
  });
};

export const getFileInfo = file_data => {
  const { size, expires_at } = file_data;

  const expiration = moment(expires_at).fromNow();
  const sizeInMB = size / 1024 / 1024;
  const sizeText =
    sizeInMB < 1
      ? `${Math.trunc(sizeInMB * 1024)}KB`
      : `${sizeInMB.toFixed(2)}MB`;

  return { expiration, sizeText };
};
